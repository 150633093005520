.shopping-cart {
  background: $gray-darkest;
    display: block;
    position: relative;
    z-index: 8;
    margin: auto;
    height: 30px;

  .block-superfish,
  .block-menu-block {
    @include outer-container;
    padding-left: 6px;

    @include media($screen-lg){
      padding-left: 0;
      padding-right: 0;
    }
  }

  .menu {
    display: block;
    float: left;
    line-height: 30px;
    list-style-type: none;
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;

    li {
      margin-right: 2em;
      list-style: none;
      float: left;
      a,
      .nolink {
        font-size: 12px;
        color: transparentize($white,0.15);
        font-weight: bold;

        &:hover {
          color: $fluke-yellow;
          text-decoration: none;
        }
      }

      .nolink {
        color: transparentize($white,0.25);
        font-weight: normal;
        &:hover {
          color: transparentize($white,0.25);
          cursor: pointer;
        }
      }
    }
    .fbc-top-level {
      display: none;
      @include media($screen-md){
        display: block;
      }

      &:last-of-type {
        display: block;
      }
    }
    .fhs-brands {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0;
      margin: 0;
      span {
        display: none;
      }
      ul {
        display: block;
        right: 0;
        background: none;
        margin: 0;

        li {
          display: inline;
          margin: 0 1em 0 0;
          padding: 0;
          a {
            color: $white;
            line-height: 30px;
            font-size: 10px;
            padding: 0 0 0 20px;
            font-weight: bold;
            display: inline-block!important;
            @include media($screen-md){
              font-size: 12px;
              padding: 0 20px;
            }

            &:hover {
              color: $fluke-yellow;
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

    }
    .fluke-brands {
      position: relative;
      width: 10em;
      .nolink {
        background: #222;
        padding: 0.25em 3em 0.25em 1em;
        line-height: 18px;
        border-radius: 4px;
        position: relative;
        &:after {
          content: "▼";
          position: absolute;
          top: 0;
          right: 1em;
        }
      }
      ul {
        display: none;
        background: $white;
        padding: 0!important;
        border-radius: 0;
        top: 22px;
        box-shadow: 0 0 3px $gray;
        width: 10em!important;

        li {
          padding: 0 0.5em;
          border-bottom: 1px solid $gray-lighter;
          display: block;
          margin-right: 0;
          a {
            font-size: 10px;
            color: $base-font-color;
            line-height: 1;

            &:hover {
              color: $gray-darkest;
            }
          }

        }
      }
      &:hover > ul {
        display: block;
        position: absolute;
        top: 24px;
        left: 0;
      }
    }
    .this-site a {
      background: $gray-dark;
      padding: 10px;
    }
  }
  .where-to-buy-link {
    position: relative;

    &:before {
      content: "\f3c5";
      font-family: FontAwesomeFive;
      position: absolute;
      top: -1px;
      left: 4px;
      font-size: 16px;
    }
  }
  .my-account-link {
    position: relative;

    &:before {
      content: "\f007";
      font-family: FontAwesomeFive;
      position: absolute;
      top: -2px;
      left: 2px;
      font-weight: normal;
      font-size: 16px;
    }

  }
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
