%adds-toggle-plus {
  a[data-parent='#accordion'] {
    height: 20px;
    height:10px;
    line-height: 30px;
    position: absolute;
    right: 7px;
    text-decoration: none;
    top: 0;
    transition: visibility 2s, opacity 3s linear;
    width: 10px;
    &:after {
      color: $action-color;
      position: absolute;
      content: '\f067';              //minus   f068
      font-family: fluke-icon-font;
    }
    &.expanded {
      &:after {
        content: '\f068';
      }
    }
  }
}
%adds-toggle-triangle {
  a[data-parent='#accordion'] {
    position: absolute;
    left: 1em;
    text-decoration: none;
    top: 0;
    bottom: 0;
    right: 0;
    transition: visibility 2s, opacity 3s linear;
    width: 100%;
    &:after {
      color: $action-color;
      position: absolute;
      font-size: 24px;
      content: '▶';              //minus   f068
      transition: transform .5s linear;

    }
    &.expanded {
      &:after {
        font-size: 24px;
        transform: rotate(90deg) translateX(-2px) translateY(2px) ;
        transition: transform .5s linear;

        //content: '▼';
      }
    }
  }
}

.custom-collapse {
  .panel {
    display: block;
    border-bottom: 1px solid $gray-c;
    .child-menu-item {
      padding-bottom: 10px;
    }
    .panel-collapse {
      display: none;
    }
  }
}
.custom-collapse {
  .panel-heading {
    position: relative;
    background: $light-color-primary;
    margin-bottom: .5em;

    h3 {
      margin-bottom: 0;

      padding: 1em 1em 1em 50px;
      font-family: $font-proxima-nova-semibold;
      font-size: 1.6rem;
    }
    @extend %adds-toggle-triangle;
    a[data-parent='#accordion'] {
      font-family: $heading-font-family;
      color: $gray-21;
      padding: 1rem 0;
      line-height: 1.5;
    }
  }
  >.field-items{
    padding: 1em 50px;
  }

  a.plus-minus {}
  .panel-collapse {}
}