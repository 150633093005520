.tooltip {
  position: relative;
  display: inline-block;


  .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: transparentize(black, 0.17);
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 45px;
    left: 0;
    padding: 1em;

    @include media($screen-md){
      width: 400px;
      right: 0;
      left: unset;
    }

    &:after {
      content: '';
      position: absolute;
      top: -15px;
      left: 10px;
      height: 10px;
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid transparentize(black, 0.17);
      @include media($screen-md){
        right: 10px;
        left: unset;
      }
    }
  }
  &:hover .tooltiptext{
    visibility: visible;
  }
}
