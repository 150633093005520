.node-feature-banner,
.feature-banner-wrapper {
padding: 1em;
  @include media($screen-md){
    padding: 0 1em;
  }
  h1, h2, h3, h4, h5, h6, p {
    color: $white;
    margin: 0;

  }
  color: $white;
  position: relative;
  @include outer-container(100%);
  background: $gray-light;
  @include media($screen-md){
    height: 260px;

  }
  >div{
    display: block;
    @include outer-container();
    @include media($screen-sm){
      display: flex;

    }

  }
  .field-name-field-detail-image{
    width: 50%;
    padding-right: 1em;
    float: left;

    @include media($screen-md){
      width: auto;
      display: flex;
      flex: 0 1 20%;
      flex-direction: column;
      justify-content: space-around;
      height: 260px;
      padding: 0;
    }

    img{
      -webkit-border-radius:50%;
      -moz-border-radius:50%;
      border-radius: 50%;
    }
  }
  .group-content{
    @include clearfix;
    @include media($screen-md){
      float: none;
      display: flex;
      flex: 0 1 80%;
      flex-direction: column;
      justify-content: space-around;
      font-size: 1.7rem;
      font-weight: 700;
    }
    p{
      font-size: 1.4rem;
      @include media($screen-md) {
        font-size: $base-font-size;
      }
    }

    .field-item{
      @include media($screen-md){
        max-width: 80%;

      }

    }
    .field-name-field-attribution-name{
      font-style: italic;
      margin-top: 1em;
      margin-left: 1em;
    }
    .field-name-field-attribution-location{
      margin-left: 1em;
    }

  }
}
