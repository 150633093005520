.field-name-ds-attribution {
    table{
        tbody {
            tr {
                padding: 0;
                td {
                    padding: 0;
                    font-size: 1rem;

                    &:first-child{
                        width: 25%;
                    }
                }

                &:nth-child(odd){
                    background: $gray-lightest;
                } 
            }
        }
    }
}