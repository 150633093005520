/*
* ACTUALLY READ THIS!
*
* This file contains settings for the brand differences.
* If you change this file, you may change the css for a brand.
*
* This file imports only the brand settings file for this brand.
* All of the rest of the file should be exactly the same for all brands.
*/

// All of the normalize stuff is imported with the default theme files

// Make sure the charset is set appropriately

@charset "UTF-8";

// Import normalize.css
@import "../../neato/normalize.css/normalize.css";

// Import Bourbon.
@import "../../neato/bourbon/app/assets/stylesheets/bourbon";

// Import Neat.
@import "../../neato/neat/app/assets/stylesheets/neat";
@import "../../neato/neat/app/assets/stylesheets/neat-helpers";

@import "variables/colors.scss";
@import "variables/grid-settings.scss";
@import "variables/typography.scss";
@import "variables/all-other-variables.scss";
@import "variables/brand-settings-fluke-health.scss";

// DO NOT CHANGE THE ITEMS BELOW, they need to be imported in this order

// Import mixins
@import "helpers-mixins/_alternate-cards.scss";
@import "helpers-mixins/_animations.scss";
@import "helpers-mixins/_aspect-ratio.scss";
@import "helpers-mixins/_box-shadows.scss";
@import "helpers-mixins/_buttons.scss";
@import "helpers-mixins/_center-abs-pos-item.scss";
@import "helpers-mixins/_center-image.scss";
@import "helpers-mixins/_clear_ul.scss";
@import "helpers-mixins/_clear-margin-padding.scss";
@import "helpers-mixins/_clear-table.scss";
@import "helpers-mixins/_colored-svg.scss";
@import "helpers-mixins/_extended-background.scss";
@import "helpers-mixins/_flex.scss";
@import "helpers-mixins/_fpi-mixins.scss";
@import "helpers-mixins/_gradient.scss";
@import "helpers-mixins/_gray-background-text.scss";
@import "helpers-mixins/_hexagon.scss";
@import "helpers-mixins/_hide-h1.scss";
@import "helpers-mixins/_icons.scss";
@import "helpers-mixins/_inverse.scss";
@import "helpers-mixins/_layout-background.scss";
@import "helpers-mixins/_lists.scss";
@import "helpers-mixins/_menus.scss";
@import "helpers-mixins/_modals.scss";
@import "helpers-mixins/_omega-reset.scss";
@import "helpers-mixins/_overlay.scss";
@import "helpers-mixins/_position-centered.scss";
@import "helpers-mixins/_responsive-text.scss";
@import "helpers-mixins/_screenreader-classes.scss";
@import "helpers-mixins/_svg-with-png-fallback.scss";
@import "helpers-mixins/_table-caption.scss";
@import "helpers-mixins/_tables.scss";
@import "helpers-mixins/_test-background.scss";
@import "helpers-mixins/_test-backgrounds.scss";
@import "helpers-mixins/_texture_background.scss";

// Import Bitters.
@import "base/base";

// Import yer base stuff
@import "base/_base.scss";
@import "base/_buttons.scss";
@import "base/_details.scss";
@import "base/_forms.scss";
@import "base/_html.scss";
@import "base/_links.scss";
@import "base/_lists.scss";
@import "base/_tables.scss";
@import "base/_testing.scss";
@import "base/_transitions.scss";
@import "base/_typography.scss";

// Import Drupal element overrides
@import "layouts/_layout--flukestrap_darwin.scss";
@import "layouts/_layout--flukestrap_templates.scss";
@import "layouts/_layout--flukestrap-article-toc.scss";
@import "layouts/_layout--flukestrap.scss";
@import "layouts/_layout--no-top-banner.scss";
@import "layouts/_layout--product-display.scss";
@import "layouts/_layout.scss";
@import "layouts/_page-node-done.scss";
@import "layouts/_page-taxonomy.scss";
@import "layouts/_wrapped-items.scss";

// Import Drupal element overrides
@import "common/_backtotop.scss";
@import "common/_breadcrumb.scss";
@import "common/_bs_modals.scss";
@import "common/_messages.scss";
@import "common/_reusable-classes.scss";
@import "common/_shiny_sprite_shim.scss";
@import "common/_tables.scss";
@import "common/_tasks.scss";

// Import your components here.
@import "components/_shame.scss";
@import "components/elements/_accordion.scss";
@import "components/elements/_easy-breadcrumbs.scss";
@import "components/elements/_social-icons.scss";
@import "components/elements/_tabs.scss";
@import "components/elements/_tile-toc.scss";
@import "components/elements/_tiles.scss";
@import "components/elements/_toggles.scss";
@import "components/elements/_yes-no.scss";
@import "components/global/_admin_interface.scss";
@import "components/global/_dev-notes.scss";
@import "components/global/_drop_down_login.scss";
@import "components/global/_footer.scss";
@import "components/global/_how-to-buy-menu.scss";
@import "components/global/_icon-wrap.scss";
@import "components/global/_logo-sets.scss";
@import "components/global/_maintenance-page.scss";
@import "components/global/_mobile-indicator.scss";
@import "components/global/_navbar-white.scss";
@import "components/global/_navbar.scss";
@import "components/global/_navigation--mm-basic-fluke.scss";
@import "components/global/_navigation--primary-nav.scss";
@import "components/global/_navigation--sidebar.scss";
@import "components/global/_navigation-minisite.scss";
@import "components/global/_print.scss";
@import "components/global/_search-block.scss";
@import "components/global/_shopping-cart.scss";
@import "components/global/_sidebar-blocks.scss";
@import "components/global/_styleguide-overrides.scss";
@import "components/global/_tooltip.scss";
@import "components/global/_visibility.scss";
@import "components/pages/_access-denied.scss";
@import "components/pages/_application.scss";
@import "components/pages/_applications.scss";
@import "components/pages/_blog.scss";
@import "components/pages/_care-plans.scss";
@import "components/pages/_discontinued-products.scss";
@import "components/pages/_frontpage--categories.scss";
@import "components/pages/_frontpage--cta-primary.scss";
@import "components/pages/_frontpage--cta-secondary.scss";
@import "components/pages/_frontpage--meet-team.scss";
@import "components/pages/_frontpage--view-frontpage.scss";
@import "components/pages/_home.scss";
@import "components/pages/_landing-page.scss";
@import "components/pages/_node-type-application-2021.scss";
@import "components/pages/_node-type-channel-alert.scss";
@import "components/pages/_node-type-faq.scss";
@import "components/pages/_node-type-tabula-rasa.scss";
@import "components/pages/_page-channel-tools-partner-alerts.scss";
@import "components/pages/_page-channel-tools.scss";
@import "components/pages/_page-node-add-blog-post.scss";
@import "components/pages/_page-not-found.scss";
@import "components/pages/_printable_specifications.scss";
@import "components/pages/_product-highlights.scss";
@import "components/pages/_search-results.scss";
@import "components/pages/_service-request-form-eform.scss";
@import "components/pages/_service-request-form-webform.scss";
@import "components/pages/_site-messages.scss";
@import "components/pages/_training.scss";
@import "components/pages/_user-login.scss";
@import "components/pages/ds/_banner-full-width.scss";
@import "components/pages/ds/_banner-image-text-sets.scss";
@import "components/pages/ds/_banners.scss";
@import "components/pages/ds/_basic-page-template.scss";
@import "components/pages/ds/_card-layout.scss";
@import "components/pages/ds/_category_page.scss";
@import "components/pages/ds/_ds_resource_title_with_button.scss";
@import "components/pages/ds/_fbc-2col.scss";
@import "components/pages/ds/_paragaph-sections.scss";
@import "components/pages/ds/_product-pages.scss";
@import "components/pages/ds/_product-specs.scss";
@import "components/pages/ds/_resource-page.scss";
@import "components/pages/ds/_software-release-notes.scss";
@import "components/pages/ds/_view-id-fbc_category_intro.scss";
@import "components/pages/ds/_view-mode-simple_one_line.scss";
@import "components/pages/webform/_webform-base.scss";
@import "components/pages/webform/_webform-buy-quote.scss";
@import "components/pages/webform/_webform-home-footer.scss";
@import "components/pages/webform/_webform-request-quote-demo.scss";
@import "components/paragraphs/_paragraph-banner.scss";
@import "components/paragraphs/_paragraph-highlighted-section.scss";
@import "components/paragraphs/_paragraph-item-two-column.scss";
@import "components/paragraphs/_paragraphs-accordion.scss";
@import "components/paragraphs/_paragraphs-item-application-accordion.scss";
@import "components/paragraphs/_paragraphs-item-application-banner.scss";
@import "components/paragraphs/_paragraphs-item-application-quote.scss";
@import "components/paragraphs/_paragraphs-item-application-recommended-products.scss";
@import "components/paragraphs/_paragraphs-item-application-section.scss";
@import "components/paragraphs/_paragraphs-item-application-subsection.scss";
@import "components/paragraphs/_paragraphs-item-application-tile-set.scss";
@import "components/paragraphs/_paragraphs-item-application-video.scss";
@import "components/paragraphs/_paragraphs-item-application-webform.scss";
@import "components/paragraphs/_paragraphs-item-webform.scss";
@import "components/paragraphs/_paragraphs-treatments.scss";
@import "components/paragraphs/_teaser.scss";
@import "components/partials/_banner-slideshow.scss";
@import "components/partials/_card-wide-products.scss";
@import "components/partials/_cta-bug.scss";
@import "components/partials/_expanded-block.scss";
@import "components/partials/_iframe.scss";
@import "components/partials/_livechat-compact-container.scss";
@import "components/partials/_product-blocks.scss";
@import "components/partials/_protected-pages-enter-password.scss";
@import "components/partials/_sidebar-menu.scss";
@import "components/partials/_slick.scss";
@import "components/partials/_superfish.scss";
@import "components/partials/_zoom-container.scss";
@import "components/partials/fields/_block-views-fbc-attribution-urls-block-1.scss";
@import "components/partials/fields/_field-name-ds-products-compare.scss";
@import "components/partials/fields/_field-name-ds-upcoming-webinars.scss";
@import "components/partials/fields/_field-name-field-application-related-produc.scss";
@import "components/partials/fields/_field-name-field-call-to-action.scss";
@import "components/partials/fields/_field-name-field-content-not-available-mess.scss";
@import "components/partials/fields/_field-name-field-promo-link.scss";
@import "components/partials/fields/_field-name-field-related-product.scss";
@import "components/partials/fields/_field-name-field-video.scss";
@import "components/partials/views/_accreditations.scss";
@import "components/partials/views/_attribution_urls.scss";
@import "components/partials/views/_events.scss";
@import "components/partials/views/_polls.scss";
@import "components/partials/views/_product-categories.scss";
@import "components/partials/views/_quicktabs-view__advantage_training__block.scss";
@import "components/partials/views/_view-fbc-accessory-search.scss";
@import "components/partials/views/_view-fbc-events.scss";
@import "components/partials/views/_view-fbc-faqs.scss";
@import "components/partials/views/_view-fbc-press-releases.scss";
@import "components/partials/views/_view-fbc-product-compatibility.scss";
@import "components/partials/views/_view-fbc-products.scss";
@import "components/partials/views/_view-fbc-resources.scss";
@import "components/partials/views/_view-fbc-service-and-support.scss";
@import "components/partials/views/_view-fbc-software-downloads.scss";
@import "components/partials/views/_view-fbc-webinars.scss";
@import "components/partials/views/_view-frontpage--view-display-id-block_2.scss";
@import "components/partials/views/_view-id-fbc_videos.scss";
@import "components/partials/views/_views-exposed-widgets.scss";
@import "components/partials/views/_where-to-buy.scss";
@import "components/webforms/_oneqa-form.scss";

//@import "custom-per-brand/fluke-health_custom";
//@import "custom-per-brand/_fpi_custom.scss";