

#{$all-button-inputs},
%button,
.button{
  @include appearance(none);
  -webkit-font-smoothing: antialiased;
  background-color: $color-pop;
  border: none;
  color: $base-font-color;
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  font-size: $font-size-md;
  line-height: 1;
  padding: .75em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  border-radius: 4px;
  //font-weight: bold;
  //box-shadow: 0 0 1px darken($color-pop, 25%);

  a{
    color: $base-font-color;
    font-weight:bold;
  }

  &:hover,
  &:focus {
    background-color: darken($color-pop, 25%);
    text-decoration: none;
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

%button-primary,
.button-primary{
  @extend button;
  background-color: $color-pop;
  color: $base-font-color;
  border-radius: 4px;
  padding: 1em 3em;
  font-size: 1.7rem;
  //font-weight: bold;
  &:hover,
  &:active,
  &:focus{
    background: darken($color-pop, 10%);
    color: $base-font-color;
  }
  a{

  }
}

%button-secondary,
.button-secondary{
  @extend button;
  background-color: $color-primary;
  display: inline-block;
  padding: 0.5em 2em;
  margin-top: 1rem;
  border-radius: 4px;
  font-size: 1.7rem;
  color: $white;

  a{
   color: $white;
    &:hover,
    &:active,
    &:focus{
      color: $white;
      text-decoration: none;
    }
  }

  &:hover,
  &:active,
  &:focus{
    color: $white;

    background: darken($color-primary, 10%);

  }
}

%ghost-button,
.ghost-button{
  @extend button;
  border: 1px solid $color-primary;
  background-color: $white;
  display: inline-block;
  padding: 0.75em;
  margin-top: 1rem;
  border-radius: 4px;
  font-size: 1.7rem;
  color: $color-primary;

  a{
   color: $color-primary;
    &:hover,
    &:active,
    &:focus{
      color: $white;
      text-decoration: none;
    }
  }

  &:hover,
  &:active,
  &:focus{
    color: $white;

    background: darken($color-primary, 10%);

  }
}

%button-white{
  @extend button;
  border-radius: 4px;
  background-color: $white;
  color: $color-primary;

  &:hover{
    background-color: $gray-lightest;
  }
}

%button-small,
.button-small{
  padding: .25em .5em;
}