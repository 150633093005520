.node-type-application {

  .node-call-to-action {
    max-height: 300px;
    overflow: hidden;

    .banner-content {
      height: 300px;
    }
    .foreground-section {
      height: 300px;
    }

    &.treatment-light-text {
      .foreground-section{
        position: relative;
        &:before {
          @include extended-background(transparent);
          background-color: transparent!important;
          @include linear-gradient(90deg, $black, transparent);

          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
    }
  }
  .group-constrained{
    @include outer-container();
  }

  .has-background {
    position: relative;
    &:after {
      content:'';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: $gray-lightest;
      margin-left: -50%;
      margin-right: -50%;
      z-index: -1;
    }
  }

  .card-wide-layout {
    box-shadow: none;
  }

  .title-field,
  .field-name-field-why-to-test-title,
  .field-name-field-how-to-test-title,
  .field-name-field-what-you-need-title,
  .field-name-field-best-practices-title,
  .field-name-field-history-title,
  .field-name-field-history-content,
  .field-name-ds-get-more-information-webform {
    padding: 1em;

    @include media($screen-lg) {
      @include outer-container();
      padding: 1em 0;
    }

    h2 {
      font-family: $font-proxima-nova-regular;
      font-size: 3.2rem;
      text-transform: uppercase;
    }
  }

  .paragraphs-items-card-wide {
    .card-wide-layout {
      padding: 1em 0;

      .card-content {
        padding: 0 1em;

        @include media($screen-lg){
          padding: 0;

          .card-text {
            padding-left: 1em;
          }
        }

      }
    }
  }

  .field-name-field-what-you-need-product {
    padding-bottom: 1em;


    .field-name-ds-application-demo-button {
      background: $gray-light;
      padding: 1em;
    }
    & > .field-items {
      display: flex;
      width: 100%;
      justify-content: space-between;
      & > .field-item {
        flex: 0 1 30%;
        display: flex;
        flex-direction: column;
        .field-name-field-teaser-image {
          height: 150px;
          display: block;
          img {
            max-height: 150px;
            object-fit: contain;
            margin: auto;
            display: block;
          }
        }

      }
    }
  }

  .group-one-left {
    padding-left: 1em;
    display: inline-block;
    vertical-align: top;
    @include media($screen-md) {
      width: 50%;
      padding-right: 2em;

    }

    @include media($screen-lg){
      padding-left: 0;
    }
  }
  .group-one-right {
    width: 100%;
    padding: 1em;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    @include media($screen-md) {
      width: 50%;

    }

    @include media($screen-lg){
      padding-right: 0;
    }
  }

  .group-two {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: $screen-lg;
    margin: auto;


    .field-name-field-what-you-need-title {
      flex: 0 1 100%;
      width: 100%;

    }
    .group-two-left {
      padding-left: 1em;
      @include media($screen-md) {
        flex: 0 1 68%;
        width: 68%;

      }
      @include media($screen-lg){
        padding-left: 0;
      }
    }
    .group-two-right {
      margin: 1em;
      padding: 1em;
      background: $gray-lighter;
      max-width: 400px;
      margin: 1em auto;

      @include media($screen-md) {
        margin: 1em;
        flex: 0 1 28%;
        width: 28%;

      }
    }
  }

}