@mixin horizontal-menu{
 @include clear-ul;
  ul{
    list-style: none;
    display: flex;
    justify-content: space-between;
    li{
      list-style-type: none;
      display: inline-block;
    }
  }
}