.dev-note{
  height: 300px;
  width: 300px;
  position: absolute;
  top: 400px;
  right: 400px;
  padding: 3em 2em;
  background: lightgoldenrodyellow;
  box-shadow: inset 0px 0px 1px $gray-dark, 0px 25px 50px $gray-dark;
  &:before{
    content: "Developer Note:";
    position: absolute;
    top: 1em;
    left: 1em;
    color: green;
  }


}