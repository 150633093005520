.page-user {
  .messages.status {
    position: absolute;
    top: -50px;
  }
  &.not-logged-in {
    .main-container {
      clear: both;
      float: none;
      max-width: 500px;
      margin: auto;
    }
    .tabs.primary {
      position: relative;
      top: 0;
      border-bottom: 1px solid $gray-lighter;
      width: 100%;
      margin-left: -1em;
      margin-right: -1em;
      height: 48px;

      li {
          border: 1px solid $gray-lighter;
          border-radius: 5px 5px 0 0;
          background: $gray-lighter;
          border-bottom: none;

        &.active {
            background: $white;
            color: $base-font-color;
            border-bottom: 2px solid $white;
          }
        a {
          background-color: transparent;
          width: 100%;
          display: block;
          text-align: center;
        }
        a.active {
          border: none;
        }

        &:nth-child(1) {
          position: absolute;
          right: 1em;
          width: calc(50% - 2em);
          a {
          }
          &.active {

            & ~ li:last-child {
              display: none;
            }
        }
        }
        &:nth-child(2) {
          width: calc(50% - 2em);
          position: absolute;
          left: 2em;
        }
        &:nth-child(3) {
          position: absolute;
          bottom: -250px;
          right: 12px;
          border: none;
          background: none;

          &.active {
            bottom: -145px;
          }

          a {
            background-color: transparent;
            color: $blue;
            display: block;
            font-weight: 600;
            padding: 0;
            text-decoration: none;
            &:after {
              content: "?";
              position: absolute;
              top: 0;
              font-weight: bold;
              right: -10px;
              color: $blue;
            }
            &.active {
              display: none;
              &::after{
                content: '';
              }
            }
          }
        }
      }
    }
  }

  form {
    .ds-form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .form-wrapper {
      padding: 0;
      .form-item {
        margin-top: 0;
      }
    }
    .field-name-field-first-name,
    .field-name-field-last-name,
    .field-name-field-job-role,
    .field-name-field-phone,
    .field-name-field-phone-number {
      display: inline-block;
      flex: 0 1 48%;
    }
    .field-name-field-phone,
    .field-name-field-phone-number {
      margin-left: 20px;
    }
  }
}

.security-user-login-form {
  display: block;
  #edit-account {
    border-bottom: 1px solid $gray-lighter;
    padding-bottom: 1em;
    margin-bottom: 1em;
  }
}
