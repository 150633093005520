.view-fbc-accreditation {

  &.view-display-id-block_1{
    -webkit-box-shadow: 0 -10px 25px -16px #555;
    -moz-box-shadow: 0 -10px 25px -16px #555;
    box-shadow: 0 -10px 25px -16px #555;
    padding-top: 2em;
    .view-content{
      @extend %four-by;
      .views-row{
        vertical-align: top;
        text-align: center;
        margin-bottom: 3em;
        .card-content{
          flex-direction: column;

        }
        .card-image{
          min-height: 120px;
          img{
            min-width: 0;
            margin: auto;
          }
        }
      }
    }
  }
}