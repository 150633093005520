header,
section{
}
// HERE WE GO!
body {

  .panel-display{
    .container{
      @include outer-container(100%);
      @include media($screen-sm){
        //@include outer-container();
      }
      @include media($screen-md){

      }
      @include media($screen-lg){
      }
      .panel-pane{
        line-height: $base-line-height;
      }
      .content-container{
        @include outer-container();
        position: relative;
      }
    }
  }

  &.no-sidebars {
    main {
      section#content {
        @include span-columns(12);
      }
    }
  }

  &.one-sidebar.sidebar-first {
    main {
      aside {
        &#sidebar-first {
          @include span-columns(4);
        }
      }

      section#content {
        @include span-columns(8);
      }
    }
  }

  &.one-sidebar.sidebar-second {
    main {
      aside {
        &#sidebar-second {
          @include span-columns(4);
        }
      }

      section#content {
        @include span-columns(8);
      }
    }
  }

  &.two-sidebars {
    main {
      aside {
        &#sidebar-first {
          @include span-columns(3);
        }

        &#sidebar-second {
          @include span-columns(3);
        }
      }

      section#content {
        @include span-columns(6);
      }
    }
  }
}

.outer-wrapper{
  @include outer-container();
}

section{
  @include clearfix;
}
// .paragraphs-items{
//   @include outer-container();
//   .field-type-paragraphs {
//     &>.field-items{
//       &>.field-item{
//       }
//     }
//   }
// }

.node-unpublished {
position: relative;
background-color: transparent;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fb83fa+0,e77eea+99&0+1,0.4+23,0+43,0.4+66,0+87 */
background: -moz-linear-gradient(-45deg,  rgba(251,131,250,0) 0%, rgba(251,131,250,0) 1%, rgba(247,130,246,0.4) 23%, rgba(243,129,243,0) 43%, rgba(238,128,239,0.4) 66%, rgba(234,127,236,0) 87%, rgba(231,126,234,0) 99%); /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg,  rgba(251,131,250,0) 0%,rgba(251,131,250,0) 1%,rgba(247,130,246,0.4) 23%,rgba(243,129,243,0) 43%,rgba(238,128,239,0.4) 66%,rgba(234,127,236,0) 87%,rgba(231,126,234,0) 99%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg,  rgba(251,131,250,0) 0%,rgba(251,131,250,0) 1%,rgba(247,130,246,0.4) 23%,rgba(243,129,243,0) 43%,rgba(238,128,239,0.4) 66%,rgba(234,127,236,0) 87%,rgba(231,126,234,0) 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00fb83fa', endColorstr='#00e77eea',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }
}