
@mixin clear-margin-padding{
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

%clear-margin-padding{
  *{
    @include clear-margin-padding;
  }
}
