.node-type-landing-page {
  .paragraphs-item-webform {
    .field-name-field-headline {
      font-size: 24px;
    }
    .field-name-field-subheadline {
      font-size: 18px;
    }
    border: 1px solid $gray-lighter;
    padding: 1em;

    .form-wrapper{
      padding: 0;
    }
    .field-name-field-headline {
      h2 {
        line-height: 1.25;
        margin-bottom: 1em;
      }
    }
    .form-actions {
      input {
        border: 1px solid $gray-light;
        border-radius: 3px;
        padding: 0.25em 0.5em;
      }
    }
    .abridged {
      .webform-component--company,
      .webform-component--business-phone,
      .webform-component--business-phone-rev,
      .webform-component--job-function,
      .webform-component--country,
      .webform-component--zip-or-postal-code,
      .webform-component--list-all-products-of-interest,
      .webform-component--i-consent {
        display: none;
      }
    }
  }
  .webform-client-form-19731 {
    .webform-component--i-would-like-to,
    .webform-component--list-all-products-of-interest {
      display: none;
    }
  }
}

.webform-client-form {
  label {
    text-align: left;
  }
}
