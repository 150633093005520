header{
  @include clearfix;
  width: 100%;
  z-index: 8;

  .container{
    @include outer-container;
  }
  a.name{
    @include hide-text;
    display: block;
    line-height: 0;
  }
}
body {
  .modal-active {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: pink;
    z-index: 1;
  }
}
#navbar{
  height: 60px;
  //transition: height .25s ease-in-out, box-shadow .25s ease-in-out;
  //box-shadow: 0 1px 2px transparent; /* Vendor Prefixes no longer required for box shadow */
  position: relative;
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  // -webkit-transform: translate3d(0,0,0);
  // transform: translate3d(0,0,0);

  padding-left: 6px;

  @include media($screen-md){
    height: 62px;
    position: relative;
  }

  @include media($screen-lg){
    padding-left: 0;
  }

  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: $gray-dark;
    z-index: -1;
    height: 100%;
    width: 100%;
  }

  .container{
    display: flex;
    height: 100%;
    width: 100%;
    .navbar-header{
      margin-right: 1em;
      display: flex;
      flex-direction:column;
      justify-content: space-around;
      @include media($screen-md){
        flex: 0 1 10%;
      }
    }
    .navbar-collapse{
      width: 100%;
      #block-menu-block-1{
        display: none;
        @include media($screen-md) {
          display: block;
        }
      }
      #block-views-exp-fbc-search-page-1{
        //margin-right: 100px;
      }

      .region-navigation{
        display: flex;
        justify-content: flex-start;
        z-index: 1;
        position: relative;

        .block {
          display: inline-block;
          width: 2%;
          &#block-webform-client-block-16, 
          &#block-block-42 {
            width: auto;
          }
        }

        #block-menu-block-1{
          width: 55%;
        }
        #block-menu-block-2{
          width: 15%;
          margin: auto;
          position: absolute;
          right: 100px;
          white-space: normal;

          @include media($screen-md) {
            //white-space: nowrap;
            position: relative;
            right: auto;
          }
        }
        #shop-now-button {
          text-align: right;
          width: 30%;
          margin-top: 21px;
          position: absolute;
          right: 170px;
          @include media($screen-sm) {
            width: 50%;
          }
          @include media($screen-md) {
            position: relative;
            width: 15%;
            margin-top: 4px;
            right: 0;
          }
          @include media($screen-lg) {
            width: 18%;
          }
          a {
            padding: 0 .5em;
            font-size: 1.4rem;
            font-weight: 700;
            display: inline-block;
            -webkit-border-radius:4px;
            -moz-border-radius:4px;
            border-radius: 4px;
            height: 22px;
            line-height: 22px;
            margin-top: 0;
            @include media($screen-md) {
              padding: 2px 1em;
              font-size: 1.7rem;
              margin-top: 12px;
              height: 30px;
              line-height: 28px;
            }
          }
        }
        
        #block-webform-client-block-16, 
        #block-block-42 {
          @include media($screen-lg) {
            width: 140px;
          }
        }
        // Contact Sales block.
        #block-block-42 {
          @include media($screen-md) {
            top: 0;
            width: 100%;
          }
        }

        #block-block-32 {
          right: 0px;
          margin-top: 57px;
          @include media($screen-md) {
            width: 15%;
          }
        }

        #block-block-32, 
        #block-block-42, 
        #block-block-16 { 
          position: absolute;
          text-align: right;
          @include media($screen-md) {
            position: absolute;
            right: 0;
            text-align: right;
            margin-top: 4px;
          }

           a {
              padding: 0 .5em;
              font-size: 1.4rem;
              font-weight: 700;
              display: inline-block;
              height: 22px;
              line-height: 22px;
              margin-top: 0;
             @include media($screen-md) {
              padding: 2px 1em;
              font-size: 1.7rem;
              margin-top: 12px;
              height: 30px;
              line-height: 30px;
            }
           }
        }

        .block-search-api-page,
        #block-search-form,
        #block-views-exp-fbc-search-page-1 {
          display: none;
          @include media($screen-md) {
            display: inline-block;
            width: 13%;
            position: relative;
            left: auto;
            top: 5px;
            padding-left: 1em;
          }

          }
      }
    }
  }

  &.stickynav-active {
    position: fixed;
    // -webkit-overflow-scrolling: touch;
    // -webkit-transform: translate3d(0,0,0);
    // transform: translate3d(0,0,0);
    // backface-visibility: hidden;
  }

  a.logo {
    display: inline-block;

    img {
      width: 110px;
      margin: 8px 10px;
      display: block;
    }
  }
}

.sticky-navbar {
  #navbar {
    position: fixed;
    top: 0;
  }
}
