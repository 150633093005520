.page-channel-tools-partner-alerts {
  .view-filters,
  .view-content {
    @include outer-container();
  }

  .resource-page .header-wrapper {
    background-position: 50%;
    height: 150px;
    .header-text {

      .title {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;
        .field-name-breadcrumbs {
          padding: 1em 0;
        }
        .field-name-title-field {
          height: 300px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }
  .view-filters {
    padding: 1em 0;
  }
  .views-table {
    th, td, tr {
      background-color: transparent;
      border-bottom: 1px solid $gray-lighter;
      padding: 0.5em 1em;
    }
    th {
      background-color: $gray-lightest;
      border-top: 1px solid $gray-lighter;
    }
  }
  .view-content {
    min-height: 50vh;
    margin-bottom: 2em;
  }
}