.pane-superfish{
  .sf-accordion-toggle {
    display:none;
  }
  ul.sf-menu.sf-accordion{
    display: block;
    position: relative;
  }

  #superfish-1-accordion {
    left:auto !important;
    top:auto !important;
    z-index: 1;
  }

  ul.menu{
    li{
      padding: .75em 0;
      font-family: $heading-font-family;
      font-size: 1.7rem;
      border-bottom: 1px solid $gray-light;
      position: relative;
      a{
        color: $gray-dark;
      }

      ul{
        li{
          font-family: $base-font-family;
          font-size: $base-font-size;
          border-bottom: none;
          padding: .25em 0;
          a{
            color: $action-color;

          }
        }
      }
    }

    & > .menuparent,
    & > .sf-expanded{
      position: relative;
      @include icon(after, plus);

      &:after{
        position: absolute;
        top: 0;
        right: 0;
        padding: .75em 0;
        height: 20px;
        width: 20px;
        color: $action-color;
        pointer-events: none;
      }

    }
    & > .menuparent.sf-epxanded{
      &:after{
        content: ''!important;
      }
    }
    & > .sf-expanded{
      position: relative;
      @include icon(after, minus);

    }
  }

}