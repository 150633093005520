.view-fbc-press-release{
  &.view-display-id-block_1{
    .views-row{
      margin-bottom: 2em;
    }
  }
  &.view-display-id-block_2{
    .view-filters{
      margin-bottom: 1em;
      label{
        display: inline-block;
        line-height: 54px;
        height: 40px;
        vertical-align: middle;
      }
      .views-widget{
        display: inline-block;
      }
      select{
        margin: 0;
      }
    }
    .view-content{
      //width: 100%;
      //display: flex;
      //justify-content: space-between;
      //flex-wrap: wrap;
      margin-bottom: 2em;
      .views-row{
        //display: inline-block;
        //width: 22%;
        display: block;
        margin-bottom: 2em;
      }
    }
  }
  &.view-display-id-block_2{
    .view-content{
      margin-bottom: 2em;
      .views-row{
        margin-bottom: 2em;
      }
    }
  }


}