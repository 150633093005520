@mixin overlay($color: $teal-light, $transparentize: .5){
  position: relative;
  &:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: transparentize($color, $transparentize);
  }
}