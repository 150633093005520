@mixin extended-background($color: $gray-e){
  background-color: $color;
  //margin: 0 -4em;
  //padding: 0 4em;

  @include media($screen-lg){
    margin-left: -2000px;
    margin-right: -2000px;
    padding-left: 2000px;
    padding-right: 2000px;

  }
}

@mixin remove-extended-background($color: transparent){
  background: none;
  margin: 0;
  padding: 0;
  overflow-y: visible;
}