.view-id-fbc_events.view-display-id-block_1{
  margin-top: 2em;

  .view-header{
    display: none;
    @include media($screen-md){
      display: flex;
      margin-bottom:2em;
      div{
        display: inline-block;
        &:nth-child(1){
          flex: 0 1 40%;
        }
        &:nth-child(2){
          flex: 0 1 22%;
        }
        &:nth-child(3){
          flex: 0 1 23%;
        }
        &:nth-child(4){
          flex: 0 1 15%;
        }
      }

    }
  }

  .views-row{
    display: flex;
    flex-wrap: wrap;
    @include media($screen-md){
      flex-wrap: nowrap;
    }

    >div{
      display: inline-block;
      flex: 0 1 100%;
      @include media($screen-md){
        flex: 0 1 25%;
        padding-right: 2em;

        &:nth-child(1){
          flex: 0 1 40%;
        }
        &:nth-child(2){
          flex: 0 1 22%;
        }
        &:nth-child(3){
          flex: 0 1 23%;
        }
        &:nth-child(4){
          flex: 0 1 15%;
        }
      }

    }

    a>h3{
      color: $base-link-color;
    }

  }

  @include clear-table;
  margin-bottom: 5em;
  .view-content{
    margin-bottom: 5em;
  }

  .views-row{
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid $gray-lightest;
  .event-info-wrapper{
    display: flex;
    width: 100%;
    .text-section{
      flex: 0 1 75%;
        h3{
          margin: 1em 0;
        }
      .date-display-range{

      }

    }
    .image-section{
      flex: 0 1 25%;
      img{
        height: 100%;
        width: auto;
      }
    }
  }

  }
}