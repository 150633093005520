@mixin basic-flex{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  @include media($screen-md){
    flex-wrap: nowrap;
  }
  div:not(contextual-links-wrapper){
    display: inline-block;
    flex: 0 1 100%;
    @include media($screen-md){
      flex: 0 1 50%;
    }
  }
}


@mixin flex-toc{
  .view-content{
    //@extend %test-backgrounds;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
    @include media($phone){
      justify-content: flex-start;
    }
  }
  .views-row{
    display: inline-block;
    padding: 1em;

    &:nth-child(2n+2){
    }

    @include media($tablet){
      flex-basis: 30%;
      &:nth-child(2n+2){
      }
      &:nth-child(3n+3){  }
    }

    @include media($desk-wide){
      flex-basis: 25%;
      &:nth-child(2n+2){
      }
      &:nth-child(3n+3){
      }
      &:nth-child(4n+4){
      }
    }
    img{
      height: auto;
    }

  }
}

@mixin flex-items(){
  .views-row{
    border-bottom: 1px solid $gray-lightest;
    padding-bottom: 1em;
    margin-bottom: 1em;
    &:last-child{
      border-bottom: none;
    }
  }
  .field-content{
    display: flex;


    & > div:first-child{
      //padding-right: 1em;
    }


    .teaser-image{
      display: inline-block;
      flex: 0 1 30%;
    }
    .teaser-content{
      display: inline-block;
      flex: 0 1 70%;
    }
  }

}

%three-by{
  .view-content{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    display: -ms-flexbox;
    -ms-flex-align: start;
  }
  .views-row{
    display: inline-block;
    padding-right: 15px;

    @include media($tablet) {
      flex-basis: 50%;
      -ms-flex-preferred-size: 50%;
      max-width: 50%;

    }
    @include media($desk) {
      flex-basis: 33%;
      -ms-flex-preferred-size: 33%;
      max-width: 33%;
    }
    h3 {
      margin-top: .5em;
    }
    img{
      height: auto;
      min-width: 0;
      margin: auto;
      display: block;
      width: auto;
    }


  }
}

%four-by{
  @extend %three-by;
  .views-row{

    @include media($desk) {
      flex-basis: 25%;
      -ms-flex-preferred-size: 25%;
      width: 25%;
      max-width: 24%;


    }
    .view-content{
      display: block;
    }
  }
}

@mixin flex-toc{
  @extend %four-by;

}

@mixin clear-ul{
  ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
    li{
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
}

%responsive-wrapper{
  max-width: 100%;
  padding: 3vw;
  //@include test-background('true');
  @include media($screen-xs){
    margin: 0 auto;
  }
  @include media($screen-sm){
    max-width: $screen-sm;
  }
  @include media($screen-md){
    max-width: $screen-md;
    padding: 2vw;
  }
  @include media($screen-lg){
    @include outer-container();
    padding: 2em 0;
  }
}

