.view-fbc-products{
  .views-row{
    padding-bottom: 2em;
    &.views-row-even{
      .card-image{
        order: 2;
      }
      .card-text{
        order: 1;
      }
    }
  }
  &.view-display-id-block,
  &.view-display-id-block_4{
    .card-content {
      position: relative;
    }

    .card-image{
      max-height: 300px;
      text-align: center;
      img{
        max-height: 300px;
        max-width: 100%;
        height: auto;
        width: auto;

      }
    }
  }
  .card-text{
    &.new {
      @include media($screen-sm) {
        position: relative;
      }
      &:after {
        z-index: 2;
        position: absolute;
        content: '';
        top: -56px;
        left: -1em;
        height: 65px;
        width: 100px;
        background: url('../images/icon-new-with-tab.png') no-repeat 100%;
        background-size: 100%;
        @include media($screen-sm){
          width: 130px;
          top: -87px;
          left: -1em;

        }
      }
    }
    a {
      h2 {
        color: $color-primary;
      }
    }
  }
}


.view-id-fbc_products {
  &.view-display-id-block_3{
    display: none;
    @include media($screen-md){
      display: block;
    }
      tbody {
        img {
          max-width: 25px;
          max-height: 25px;
        }
      }
  }
  &.view-display-id-block_11{
    display: block;
    @include media($screen-md){
      display: none;
    }
  }
}