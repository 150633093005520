.paragraphs-items-field-app-subsection-accordion {
  margin-top: 1.5em;
}

.paragraphs-item-application-accordion-item {
  details {
    margin-bottom: 0px;
    position: relative;

    summary {
      position: relative;
      padding-top: 5px;
      padding-bottom: 5px;

      &:before {
        width: 10px;
        height: 10px;
        position: absolute;
        top: 6px;
        left: 0;
      }
    }

    &[closed] {
      summary {
        &:before {
          content: "\25BA";
          left: 0;
          transition: all 0.5s;
        }
      }
    }

    &[open] {
      summary {
        &:before {
          transform: rotate(90deg);
          transition: all 0.5s;
          left: 5px;
        }
      }
    }
  }

  .field-name-field-app-accordion-trigger {
    display: inline-block;
    margin-left: 0.5em;

    &:hover {
      cursor: pointer;
    }
  }

  .field-name-field-app-accordion-content {
    padding: 0.65em 1.25em;
  }
}
