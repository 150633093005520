// This affects the Panelizer templates flukestrap-pascal,flukestrap-toc-card and flukestrap-toc    
.flukestrap-pascal,
.flukestrap-toc,
.flukestrap-toc-card,
.pascal {
    // Extends the gray background of the left hand nav to the edge of the screen on desktop
    @include media($screen-md) {
      //@include linear-gradient(90deg, $gray-e 0%, $gray-e 20%, $white 20%, $white 100%);
    }
    // Keeps the banners full width
    .flukestrap-layouts-header,
    .pane-igcommerce-utility-articles-articles-toc-banner,
    .pane-igcommerce-utility-articles-articles-solr-banner {
      @include outer-container(100%);
      line-height: 0;
    }
    .container {
    // Keeps the sidebar full height on tablet and desktop
    @include media($screen-md) {
      .row{
        display: flex;
      }
    }
    @include outer-container;
    .flukestrap-layouts-sidebar{
      @include outer-container(100%);
      margin-top: 2em;
      padding: 1.5rem;
      position: relative;
      flex-basis: 100%;
      @include media($screen-md) {
        min-height: 500px;
        margin-top: 0;
        flex-basis: 25%;
        //@include span-columns(3);
        //margin-left: -1000px; padding-left: 1000px; overflow-x: hidden
      }
    }
    .flukestrap-layouts-content {
      //@include span-columns(12);
      flex-basis: 100%;

      padding: 3rem 1.5rem;
      @include media($screen-md) {
        flex-basis: 75%;
        //@include span-columns(9);
        padding: 2em 1em;
      }
      @include media($desk-xwide){

      }
    }
  }
  .flukestrap-layouts-header{
    //see .pane-igcommerce-utility-articles-articles-toc-banner-image-text

  }

}
// This affects ONLY the Panelizer template flukestrap-toc-card    
.flukestrap-toc-card {
  // Modifies ONLY Flukestrap-toc-card to change right white column to grey. White footer found under left nav.
  @include media($screen-lg) {
    @include linear-gradient(90deg, $gray-e 0%, $gray-e 20%, $gray-e 20%, $gray-e 100%);
  }
  .flukestrap-layouts-footer{
    background: $white;
  }
}