
.entity-paragraphs-item{
  padding: 1em;
  @include media($screen-lg){
    padding: 1em 0;
  }
  .entity-paragraphs-item{
    padding: 0;
  }
}

  .field-item.even{
    .paragraphs-item-highlighted-section{
      .card-image{
        order: 2;
        margin-right: 0;
        @include media($screen-sm){
        margin-left: 1em;}
      }
    }
  }



.paragraphs-item-image-only{
  width: 100%;
  img{
    margin: auto;
    display: block;
    //width: 100%;
    height: auto;
  }
}

.paragraphs-item-content-in-columns{
  .field-name-field-page-sections{
    >.field-items{
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      @include media($screen-md){
        flex-wrap: nowrap;
      }
      >.field-item{
        display: inline-block;
        flex: 0 1 100%;
        margin-bottom: auto;
        @include media($screen-md){
          flex: 0 1 50%;

        }
        @include media($screen-lg){
          padding-right: 2em;
          //flex: 0 1 25%;
          &:last-child{
          padding-right: 0;
        }
        }
      }
    }
  }
}

.page-node-10069{
  .field-name-field-page-sections{
    >.field-items{
      >.field-item:first-child{
        width: 70%;
        @include remove-extended-background();
      }
      >.field-item:last-child{
        width: 30%;
        background: $gray-lightest;
        padding: 2rem;
      }
    }
  }
}

.view-mode-press_release_primary.card-wide-layout,
.view-mode-press_release_secondary.card-wide-layout{
  -webkit-box-shadow: 0 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 0 transparent;
  box-shadow: 0 0 0 0 transparent;
  background: none;
  padding-top: 0;
  padding-bottom: 0;
}

.paragraphs-items-field-column {
  .field-name-field-column {
    & > .field-items {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      @include media($screen-md){
        flex-wrap: nowrap;
      }

      & > .field-item {
        display: inline-block;
        width: 100%;
        padding-right: 2em;

        &:last-child {
          padding-left: 2em;
        }
      }
    }
  }

}
