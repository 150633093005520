.node-type-software-release-notes,
.node-software-release-notes{
  .content-container{
    @include outer-container();
  }
  .field-name-title-field,
  .field-name-title{
    position: relative;
    max-height: 80px;
    overflow: hidden;
    &:before{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      content: url(../images/backgrounds/hexagons-category-1260x604.png);
    }
  }
  .paragraphs-items-field-history-releases{
    .field-name-field-release-number{
      .field-label,
      .field-items{
        display: inline-block;
        padding-right: .5rem;
      }
    }
  }
  .field-name-ds-footer-landing-release-notes{
    background: $gray-lighter!important;
    padding: 1em;
    .landing-wrapper{
      display: flex;
      width: 100%;
      .landing-left,
      .landing-right{
        flex: 0 1 50%;
        width: 50%;
      }
    }

  }
}


  @media print{
    .node-type-software-release-notes {
      header{
        display: none;
      }
      .field-name-ds-footer-landing-release-notes{
      background: $gray-lighter!important;
      -webkit-print-color-adjust: exact;
    }

  }
}
