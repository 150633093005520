/* This is used mainly in the country picker.  This css was adapted from the 
countryicons_shiny module. We weren't using the icon
functions enough to warrant the use of countryicons_shiny and it's subsequent
requirement countryicons. This css has been adapted to support uppercase classnames as well as the origional lowercase. */
.countryicon-sprite.iconset-shiny {
  width: 16px; height: 11px;
  margin: 0; padding: 0;
  background-image: url('../images/shiny-sprite.png');
  display:inline-block;
  margin-right: .5rem;
  &.countrycode-ad { background-position: 0 -10px; }
  &.countrycode-ae { background-position: 0 -31px; }
  &.countrycode-af { background-position: 0 -52px; }
  &.countrycode-ag { background-position: 0 -73px; }
  &.countrycode-ai { background-position: 0 -94px; }
  &.countrycode-al { background-position: 0 -115px; }
  &.countrycode-am { background-position: 0 -136px; }
  &.countrycode-an { background-position: 0 -157px; }
  &.countrycode-ao { background-position: 0 -178px; }
  &.countrycode-ar { background-position: 0 -199px; }
  &.countrycode-as { background-position: 0 -220px; }
  &.countrycode-at { background-position: 0 -241px; }
  &.countrycode-au { background-position: 0 -262px; }
  &.countrycode-aw { background-position: 0 -283px; }
  &.countrycode-ax { background-position: 0 -304px; }
  &.countrycode-az { background-position: 0 -325px; }
  &.countrycode-ba { background-position: 0 -346px; }
  &.countrycode-bb { background-position: 0 -367px; }
  &.countrycode-bd { background-position: 0 -388px; }
  &.countrycode-be { background-position: 0 -409px; }
  &.countrycode-bf { background-position: 0 -430px; }
  &.countrycode-bg { background-position: 0 -451px; }
  &.countrycode-bh { background-position: 0 -472px; }
  &.countrycode-bi { background-position: 0 -493px; }
  &.countrycode-bj { background-position: 0 -514px; }
  &.countrycode-bm { background-position: 0 -535px; }
  &.countrycode-bn { background-position: 0 -556px; }
  &.countrycode-bo { background-position: 0 -577px; }
  &.countrycode-br { background-position: 0 -598px; }
  &.countrycode-bs { background-position: 0 -619px; }
  &.countrycode-bt { background-position: 0 -640px; }
  &.countrycode-bv { background-position: 0 -661px; }
  &.countrycode-bw { background-position: 0 -682px; }
  &.countrycode-by { background-position: 0 -703px; }
  &.countrycode-bz { background-position: 0 -724px; }
  &.countrycode-ca { background-position: 0 -745px; }
  &.countrycode-catalonia { background-position: 0 -766px; }
  &.countrycode-cc { background-position: 0 -787px; }
  &.countrycode-cd { background-position: 0 -808px; }
  &.countrycode-cf { background-position: 0 -829px; }
  &.countrycode-cg { background-position: 0 -850px; }
  &.countrycode-ch { background-position: 0 -871px; }
  &.countrycode-ci { background-position: 0 -892px; }
  &.countrycode-ck { background-position: 0 -913px; }
  &.countrycode-cl { background-position: 0 -934px; }
  &.countrycode-cm { background-position: 0 -955px; }
  &.countrycode-cn { background-position: 0 -976px; }
  &.countrycode-co { background-position: 0 -997px; }
  &.countrycode-cr { background-position: 0 -1018px; }
  &.countrycode-cs { background-position: 0 -1039px; }
  &.countrycode-cu { background-position: 0 -1060px; }
  &.countrycode-cv { background-position: 0 -1081px; }
  &.countrycode-cx { background-position: 0 -1102px; }
  &.countrycode-cy { background-position: 0 -1123px; }
  &.countrycode-cz { background-position: 0 -1144px; }
  &.countrycode-de { background-position: 0 -1165px; }
  &.countrycode-dj { background-position: 0 -1186px; }
  &.countrycode-dk { background-position: 0 -1207px; }
  &.countrycode-dm { background-position: 0 -1228px; }
  &.countrycode-do { background-position: 0 -1249px; }
  &.countrycode-dz { background-position: 0 -1270px; }
  &.countrycode-ec { background-position: 0 -1291px; }
  &.countrycode-ee { background-position: 0 -1312px; }
  &.countrycode-eg { background-position: 0 -1333px; }
  &.countrycode-eh { background-position: 0 -1354px; }
  &.countrycode-england { background-position: 0 -1375px; }
  &.countrycode-er { background-position: 0 -1396px; }
  &.countrycode-es { background-position: 0 -1417px; }
  &.countrycode-et { background-position: 0 -1438px; }
  &.countrycode-europeanunion { background-position: 0 -1459px; }
  &.countrycode-fam { background-position: 0 -1480px; }
  &.countrycode-fi { background-position: 0 -1501px; }
  &.countrycode-fj { background-position: 0 -1522px; }
  &.countrycode-fk { background-position: 0 -1543px; }
  &.countrycode-fm { background-position: 0 -1564px; }
  &.countrycode-fo { background-position: 0 -1585px; }
  &.countrycode-fr { background-position: 0 -1606px; }
  &.countrycode-ga { background-position: 0 -1627px; }
  &.countrycode-gb { background-position: 0 -1648px; }
  &.countrycode-gd { background-position: 0 -1669px; }
  &.countrycode-ge { background-position: 0 -1690px; }
  &.countrycode-gf { background-position: 0 -1711px; }
  &.countrycode-gh { background-position: 0 -1732px; }
  &.countrycode-gi { background-position: 0 -1753px; }
  &.countrycode-gl { background-position: 0 -1774px; }
  &.countrycode-gm { background-position: 0 -1795px; }
  &.countrycode-gn { background-position: 0 -1816px; }
  &.countrycode-gp { background-position: 0 -1837px; }
  &.countrycode-gq { background-position: 0 -1858px; }
  &.countrycode-gr { background-position: 0 -1879px; }
  &.countrycode-gs { background-position: 0 -1900px; }
  &.countrycode-gt { background-position: 0 -1921px; }
  &.countrycode-gu { background-position: 0 -1942px; }
  &.countrycode-gw { background-position: 0 -1963px; }
  &.countrycode-gy { background-position: 0 -1984px; }
  &.countrycode-hk { background-position: -26px -10px; }
  &.countrycode-hm { background-position: -26px -31px; }
  &.countrycode-hn { background-position: -26px -52px; }
  &.countrycode-hr { background-position: -26px -73px; }
  &.countrycode-ht { background-position: -26px -94px; }
  &.countrycode-hu { background-position: -26px -115px; }
  &.countrycode-id { background-position: -26px -136px; }
  &.countrycode-ie { background-position: -26px -157px; }
  &.countrycode-il { background-position: -26px -178px; }
  &.countrycode-in { background-position: -26px -199px; }
  &.countrycode-io { background-position: -26px -220px; }
  &.countrycode-iq { background-position: -26px -241px; }
  &.countrycode-ir { background-position: -26px -262px; }
  &.countrycode-is { background-position: -26px -283px; }
  &.countrycode-it { background-position: -26px -304px; }
  &.countrycode-jm { background-position: -26px -325px; }
  &.countrycode-jo { background-position: -26px -346px; }
  &.countrycode-jp { background-position: -26px -367px; }
  &.countrycode-ke { background-position: -26px -388px; }
  &.countrycode-kg { background-position: -26px -409px; }
  &.countrycode-kh { background-position: -26px -430px; }
  &.countrycode-ki { background-position: -26px -451px; }
  &.countrycode-km { background-position: -26px -472px; }
  &.countrycode-kn { background-position: -26px -493px; }
  &.countrycode-kp { background-position: -26px -514px; }
  &.countrycode-kr { background-position: -26px -535px; }
  &.countrycode-kw { background-position: -26px -556px; }
  &.countrycode-ky { background-position: -26px -577px; }
  &.countrycode-kz { background-position: -26px -598px; }
  &.countrycode-la { background-position: -26px -619px; }
  &.countrycode-lb { background-position: -26px -640px; }
  &.countrycode-lc { background-position: -26px -661px; }
  &.countrycode-li { background-position: -26px -682px; }
  &.countrycode-lk { background-position: -26px -703px; }
  &.countrycode-lr { background-position: -26px -724px; }
  &.countrycode-ls { background-position: -26px -745px; }
  &.countrycode-lt { background-position: -26px -766px; }
  &.countrycode-lu { background-position: -26px -787px; }
  &.countrycode-lv { background-position: -26px -808px; }
  &.countrycode-ly { background-position: -26px -829px; }
  &.countrycode-ma { background-position: -26px -850px; }
  &.countrycode-mc { background-position: -26px -871px; }
  &.countrycode-md { background-position: -26px -892px; }
  &.countrycode-me { background-position: -26px -913px; }
  &.countrycode-mg { background-position: -26px -935px; }
  &.countrycode-mh { background-position: -26px -956px; }
  &.countrycode-mk { background-position: -26px -977px; }
  &.countrycode-ml { background-position: -26px -998px; }
  &.countrycode-mm { background-position: -26px -1019px; }
  &.countrycode-mn { background-position: -26px -1040px; }
  &.countrycode-mo { background-position: -26px -1061px; }
  &.countrycode-mp { background-position: -26px -1082px; }
  &.countrycode-mq { background-position: -26px -1103px; }
  &.countrycode-mr { background-position: -26px -1124px; }
  &.countrycode-ms { background-position: -26px -1145px; }
  &.countrycode-mt { background-position: -26px -1166px; }
  &.countrycode-mu { background-position: -26px -1187px; }
  &.countrycode-mv { background-position: -26px -1208px; }
  &.countrycode-mw { background-position: -26px -1229px; }
  &.countrycode-mx { background-position: -26px -1250px; }
  &.countrycode-my { background-position: -26px -1271px; }
  &.countrycode-mz { background-position: -26px -1292px; }
  &.countrycode-na { background-position: -26px -1313px; }
  &.countrycode-nc { background-position: -26px -1334px; }
  &.countrycode-ne { background-position: -26px -1355px; }
  &.countrycode-nf { background-position: -26px -1376px; }
  &.countrycode-ng { background-position: -26px -1397px; }
  &.countrycode-ni { background-position: -26px -1418px; }
  &.countrycode-nl { background-position: -26px -1439px; }
  &.countrycode-no { background-position: -26px -1460px; }
  &.countrycode-np { background-position: -26px -1481px; }
  &.countrycode-nr { background-position: -26px -1502px; }
  &.countrycode-nu { background-position: -26px -1523px; }
  &.countrycode-nz { background-position: -26px -1544px; }
  &.countrycode-om { background-position: -26px -1565px; }
  &.countrycode-pa { background-position: -26px -1586px; }
  &.countrycode-pe { background-position: -26px -1607px; }
  &.countrycode-pf { background-position: -26px -1628px; }
  &.countrycode-pg { background-position: -26px -1649px; }
  &.countrycode-ph { background-position: -26px -1670px; }
  &.countrycode-pk { background-position: -26px -1691px; }
  &.countrycode-pl { background-position: -26px -1712px; }
  &.countrycode-pm { background-position: -26px -1733px; }
  &.countrycode-pn { background-position: -26px -1754px; }
  &.countrycode-pr { background-position: -26px -1775px; }
  &.countrycode-ps { background-position: -26px -1796px; }
  &.countrycode-pt { background-position: -26px -1817px; }
  &.countrycode-pw { background-position: -26px -1838px; }
  &.countrycode-py { background-position: -26px -1859px; }
  &.countrycode-qa { background-position: -26px -1880px; }
  &.countrycode-re { background-position: -26px -1901px; }
  &.countrycode-ro { background-position: -26px -1922px; }
  &.countrycode-rs { background-position: -26px -1943px; }
  &.countrycode-ru { background-position: -26px -1964px; }
  &.countrycode-rw { background-position: -26px -1985px; }
  &.countrycode-sa { background-position: -52px -10px; }
  &.countrycode-sb { background-position: -52px -31px; }
  &.countrycode-sc { background-position: -52px -52px; }
  &.countrycode-scotland { background-position: -52px -73px; }
  &.countrycode-sd { background-position: -52px -94px; }
  &.countrycode-se { background-position: -52px -115px; }
  &.countrycode-sg { background-position: -52px -136px; }
  &.countrycode-sh { background-position: -52px -157px; }
  &.countrycode-si { background-position: -52px -178px; }
  &.countrycode-sj { background-position: -52px -199px; }
  &.countrycode-sk { background-position: -52px -220px; }
  &.countrycode-sl { background-position: -52px -241px; }
  &.countrycode-sm { background-position: -52px -262px; }
  &.countrycode-sn { background-position: -52px -283px; }
  &.countrycode-so { background-position: -52px -304px; }
  &.countrycode-sr { background-position: -52px -325px; }
  &.countrycode-st { background-position: -52px -346px; }
  &.countrycode-sv { background-position: -52px -367px; }
  &.countrycode-sy { background-position: -52px -388px; }
  &.countrycode-sz { background-position: -52px -409px; }
  &.countrycode-tc { background-position: -52px -430px; }
  &.countrycode-td { background-position: -52px -451px; }
  &.countrycode-tf { background-position: -52px -472px; }
  &.countrycode-tg { background-position: -52px -493px; }
  &.countrycode-th { background-position: -52px -514px; }
  &.countrycode-tj { background-position: -52px -535px; }
  &.countrycode-tk { background-position: -52px -556px; }
  &.countrycode-tl { background-position: -52px -577px; }
  &.countrycode-tm { background-position: -52px -598px; }
  &.countrycode-tn { background-position: -52px -619px; }
  &.countrycode-to { background-position: -52px -640px; }
  &.countrycode-tr { background-position: -52px -661px; }
  &.countrycode-tt { background-position: -52px -682px; }
  &.countrycode-tv { background-position: -52px -703px; }
  &.countrycode-tw { background-position: -52px -724px; }
  &.countrycode-tz { background-position: -52px -745px; }
  &.countrycode-ua { background-position: -52px -766px; }
  &.countrycode-ug { background-position: -52px -787px; }
  &.countrycode-um { background-position: -52px -808px; }
  &.countrycode-us { background-position: -52px -829px; }
  &.countrycode-uy { background-position: -52px -850px; }
  &.countrycode-uz { background-position: -52px -871px; }
  &.countrycode-va { background-position: -52px -892px; }
  &.countrycode-vc { background-position: -52px -913px; }
  &.countrycode-ve { background-position: -52px -934px; }
  &.countrycode-vg { background-position: -52px -955px; }
  &.countrycode-vi { background-position: -52px -976px; }
  &.countrycode-vn { background-position: -52px -997px; }
  &.countrycode-vu { background-position: -52px -1018px; }
  &.countrycode-wales { background-position: -52px -1039px; }
  &.countrycode-wf { background-position: -52px -1060px; }
  &.countrycode-ws { background-position: -52px -1081px; }
  &.countrycode-ye { background-position: -52px -1102px; }
  &.countrycode-yt { background-position: -52px -1123px; }
  &.countrycode-za { background-position: -52px -1144px; }
  &.countrycode-zm { background-position: -52px -1165px; }
  &.countrycode-zw { background-position: -52px -1186px; }
  &.countrycode-fa { background-position: -52px -1985px; } // Blank for Africa
  &.countrycode-fb { background-position: -52px -1985px; } // Blank for Middle East
  &.countrycode-fc { background-position: -52px -1985px; } // Blank for Rest of Europe

  // Same as above, only uppercase
  &.countrycode-AD { background-position: 0 -10px; }
  &.countrycode-AE { background-position: 0 -31px; }
  &.countrycode-AF { background-position: 0 -52px; }
  &.countrycode-AG { background-position: 0 -73px; }
  &.countrycode-AI { background-position: 0 -94px; }
  &.countrycode-AL { background-position: 0 -115px; }
  &.countrycode-AM { background-position: 0 -136px; }
  &.countrycode-AN { background-position: 0 -157px; }
  &.countrycode-AO { background-position: 0 -178px; }
  &.countrycode-AR { background-position: 0 -199px; }
  &.countrycode-AS { background-position: 0 -220px; }
  &.countrycode-AT { background-position: 0 -241px; }
  &.countrycode-AU { background-position: 0 -262px; }
  &.countrycode-AW { background-position: 0 -283px; }
  &.countrycode-AX { background-position: 0 -304px; }
  &.countrycode-AZ { background-position: 0 -325px; }
  &.countrycode-BA { background-position: 0 -346px; }
  &.countrycode-BB { background-position: 0 -367px; }
  &.countrycode-BD { background-position: 0 -388px; }
  &.countrycode-BE { background-position: 0 -409px; }
  &.countrycode-BF { background-position: 0 -430px; }
  &.countrycode-BG { background-position: 0 -451px; }
  &.countrycode-BH { background-position: 0 -472px; }
  &.countrycode-BI { background-position: 0 -493px; }
  &.countrycode-BJ { background-position: 0 -514px; }
  &.countrycode-BM { background-position: 0 -535px; }
  &.countrycode-BN { background-position: 0 -556px; }
  &.countrycode-BO { background-position: 0 -577px; }
  &.countrycode-BR { background-position: 0 -598px; }
  &.countrycode-BS { background-position: 0 -619px; }
  &.countrycode-BT { background-position: 0 -640px; }
  &.countrycode-BV { background-position: 0 -661px; }
  &.countrycode-BW { background-position: 0 -682px; }
  &.countrycode-BY { background-position: 0 -703px; }
  &.countrycode-BZ { background-position: 0 -724px; }
  &.countrycode-CA { background-position: 0 -745px; }
  &.countrycode-CATALONIA { background-position: 0 -766px; }
  &.countrycode-CC { background-position: 0 -787px; }
  &.countrycode-CD { background-position: 0 -808px; }
  &.countrycode-CF { background-position: 0 -829px; }
  &.countrycode-CG { background-position: 0 -850px; }
  &.countrycode-CH { background-position: 0 -871px; }
  &.countrycode-CI { background-position: 0 -892px; }
  &.countrycode-CK { background-position: 0 -913px; }
  &.countrycode-CL { background-position: 0 -934px; }
  &.countrycode-CM { background-position: 0 -955px; }
  &.countrycode-CN { background-position: 0 -976px; }
  &.countrycode-CO { background-position: 0 -997px; }
  &.countrycode-CR { background-position: 0 -1018px; }
  &.countrycode-CS { background-position: 0 -1039px; }
  &.countrycode-CU { background-position: 0 -1060px; }
  &.countrycode-CV { background-position: 0 -1081px; }
  &.countrycode-CX { background-position: 0 -1102px; }
  &.countrycode-CY { background-position: 0 -1123px; }
  &.countrycode-CZ { background-position: 0 -1144px; }
  &.countrycode-DE { background-position: 0 -1165px; }
  &.countrycode-DJ { background-position: 0 -1186px; }
  &.countrycode-DK { background-position: 0 -1207px; }
  &.countrycode-DM { background-position: 0 -1228px; }
  &.countrycode-DO { background-position: 0 -1249px; }
  &.countrycode-DZ { background-position: 0 -1270px; }
  &.countrycode-EC { background-position: 0 -1291px; }
  &.countrycode-EE { background-position: 0 -1312px; }
  &.countrycode-EG { background-position: 0 -1333px; }
  &.countrycode-EH { background-position: 0 -1354px; }
  &.countrycode-ENGLAND { background-position: 0 -1375px; }
  &.countrycode-ER { background-position: 0 -1396px; }
  &.countrycode-ES { background-position: 0 -1417px; }
  &.countrycode-ET { background-position: 0 -1438px; }
  &.countrycode-EUROPEANUNION { background-position: 0 -1459px; }
  &.countrycode-FAM { background-position: 0 -1480px; }
  &.countrycode-FI { background-position: 0 -1501px; }
  &.countrycode-FJ { background-position: 0 -1522px; }
  &.countrycode-FK { background-position: 0 -1543px; }
  &.countrycode-FM { background-position: 0 -1564px; }
  &.countrycode-FO { background-position: 0 -1585px; }
  &.countrycode-FR { background-position: 0 -1606px; }
  &.countrycode-GA { background-position: 0 -1627px; }
  &.countrycode-GB { background-position: 0 -1648px; }
  &.countrycode-GD { background-position: 0 -1669px; }
  &.countrycode-GE { background-position: 0 -1690px; }
  &.countrycode-GF { background-position: 0 -1711px; }
  &.countrycode-GH { background-position: 0 -1732px; }
  &.countrycode-GI { background-position: 0 -1753px; }
  &.countrycode-GL { background-position: 0 -1774px; }
  &.countrycode-GM { background-position: 0 -1795px; }
  &.countrycode-GN { background-position: 0 -1816px; }
  &.countrycode-GP { background-position: 0 -1837px; }
  &.countrycode-GQ { background-position: 0 -1858px; }
  &.countrycode-GR { background-position: 0 -1879px; }
  &.countrycode-GS { background-position: 0 -1900px; }
  &.countrycode-GT { background-position: 0 -1921px; }
  &.countrycode-GU { background-position: 0 -1942px; }
  &.countrycode-GW { background-position: 0 -1963px; }
  &.countrycode-GY { background-position: 0 -1984px; }
  &.countrycode-HK { background-position: -26px -10px; }
  &.countrycode-HM { background-position: -26px -31px; }
  &.countrycode-HN { background-position: -26px -52px; }
  &.countrycode-HR { background-position: -26px -73px; }
  &.countrycode-HT { background-position: -26px -94px; }
  &.countrycode-HU { background-position: -26px -115px; }
  &.countrycode-ID { background-position: -26px -136px; }
  &.countrycode-IE { background-position: -26px -157px; }
  &.countrycode-IL { background-position: -26px -178px; }
  &.countrycode-IN { background-position: -26px -199px; }
  &.countrycode-IO { background-position: -26px -220px; }
  &.countrycode-IQ { background-position: -26px -241px; }
  &.countrycode-IR { background-position: -26px -262px; }
  &.countrycode-IS { background-position: -26px -283px; }
  &.countrycode-IT { background-position: -26px -304px; }
  &.countrycode-JM { background-position: -26px -325px; }
  &.countrycode-JO { background-position: -26px -346px; }
  &.countrycode-JP { background-position: -26px -367px; }
  &.countrycode-KE { background-position: -26px -388px; }
  &.countrycode-KG { background-position: -26px -409px; }
  &.countrycode-KH { background-position: -26px -430px; }
  &.countrycode-KI { background-position: -26px -451px; }
  &.countrycode-KM { background-position: -26px -472px; }
  &.countrycode-KN { background-position: -26px -493px; }
  &.countrycode-KP { background-position: -26px -514px; }
  &.countrycode-KR { background-position: -26px -535px; }
  &.countrycode-KW { background-position: -26px -556px; }
  &.countrycode-KY { background-position: -26px -577px; }
  &.countrycode-KZ { background-position: -26px -598px; }
  &.countrycode-LA { background-position: -26px -619px; }
  &.countrycode-LB { background-position: -26px -640px; }
  &.countrycode-LC { background-position: -26px -661px; }
  &.countrycode-LI { background-position: -26px -682px; }
  &.countrycode-LK { background-position: -26px -703px; }
  &.countrycode-LR { background-position: -26px -724px; }
  &.countrycode-LS { background-position: -26px -745px; }
  &.countrycode-LT { background-position: -26px -766px; }
  &.countrycode-LU { background-position: -26px -787px; }
  &.countrycode-LV { background-position: -26px -808px; }
  &.countrycode-LY { background-position: -26px -829px; }
  &.countrycode-MA { background-position: -26px -850px; }
  &.countrycode-MC { background-position: -26px -871px; }
  &.countrycode-MD { background-position: -26px -892px; }
  &.countrycode-ME { background-position: -26px -913px; }
  &.countrycode-MG { background-position: -26px -935px; }
  &.countrycode-MH { background-position: -26px -956px; }
  &.countrycode-MK { background-position: -26px -977px; }
  &.countrycode-ML { background-position: -26px -998px; }
  &.countrycode-MM { background-position: -26px -1019px; }
  &.countrycode-MN { background-position: -26px -1040px; }
  &.countrycode-MO { background-position: -26px -1061px; }
  &.countrycode-MP { background-position: -26px -1082px; }
  &.countrycode-MQ { background-position: -26px -1103px; }
  &.countrycode-MR { background-position: -26px -1124px; }
  &.countrycode-MS { background-position: -26px -1145px; }
  &.countrycode-MT { background-position: -26px -1166px; }
  &.countrycode-MU { background-position: -26px -1187px; }
  &.countrycode-MV { background-position: -26px -1208px; }
  &.countrycode-MW { background-position: -26px -1229px; }
  &.countrycode-MX { background-position: -26px -1250px; }
  &.countrycode-MY { background-position: -26px -1271px; }
  &.countrycode-MZ { background-position: -26px -1292px; }
  &.countrycode-NA { background-position: -26px -1313px; }
  &.countrycode-NC { background-position: -26px -1334px; }
  &.countrycode-NE { background-position: -26px -1355px; }
  &.countrycode-NF { background-position: -26px -1376px; }
  &.countrycode-NG { background-position: -26px -1397px; }
  &.countrycode-NI { background-position: -26px -1418px; }
  &.countrycode-NL { background-position: -26px -1439px; }
  &.countrycode-NO { background-position: -26px -1460px; }
  &.countrycode-NP { background-position: -26px -1481px; }
  &.countrycode-NR { background-position: -26px -1502px; }
  &.countrycode-NU { background-position: -26px -1523px; }
  &.countrycode-NZ { background-position: -26px -1544px; }
  &.countrycode-OM { background-position: -26px -1565px; }
  &.countrycode-PA { background-position: -26px -1586px; }
  &.countrycode-PE { background-position: -26px -1607px; }
  &.countrycode-PF { background-position: -26px -1628px; }
  &.countrycode-PG { background-position: -26px -1649px; }
  &.countrycode-PH { background-position: -26px -1670px; }
  &.countrycode-PK { background-position: -26px -1691px; }
  &.countrycode-PL { background-position: -26px -1712px; }
  &.countrycode-PM { background-position: -26px -1733px; }
  &.countrycode-PN { background-position: -26px -1754px; }
  &.countrycode-PR { background-position: -26px -1775px; }
  &.countrycode-PS { background-position: -26px -1796px; }
  &.countrycode-PT { background-position: -26px -1817px; }
  &.countrycode-PW { background-position: -26px -1838px; }
  &.countrycode-PY { background-position: -26px -1859px; }
  &.countrycode-QA { background-position: -26px -1880px; }
  &.countrycode-RE { background-position: -26px -1901px; }
  &.countrycode-RO { background-position: -26px -1922px; }
  &.countrycode-RS { background-position: -26px -1943px; }
  &.countrycode-RU { background-position: -26px -1964px; }
  &.countrycode-RW { background-position: -26px -1985px; }
  &.countrycode-SA { background-position: -52px -10px; }
  &.countrycode-SB { background-position: -52px -31px; }
  &.countrycode-SC { background-position: -52px -52px; }
  &.countrycode-SCOTLAND { background-position: -52px -73px; }
  &.countrycode-SD { background-position: -52px -94px; }
  &.countrycode-SE { background-position: -52px -115px; }
  &.countrycode-SG { background-position: -52px -136px; }
  &.countrycode-SH { background-position: -52px -157px; }
  &.countrycode-SI { background-position: -52px -178px; }
  &.countrycode-SJ { background-position: -52px -199px; }
  &.countrycode-SK { background-position: -52px -220px; }
  &.countrycode-SL { background-position: -52px -241px; }
  &.countrycode-SM { background-position: -52px -262px; }
  &.countrycode-SN { background-position: -52px -283px; }
  &.countrycode-SO { background-position: -52px -304px; }
  &.countrycode-SR { background-position: -52px -325px; }
  &.countrycode-ST { background-position: -52px -346px; }
  &.countrycode-SV { background-position: -52px -367px; }
  &.countrycode-SY { background-position: -52px -388px; }
  &.countrycode-SZ { background-position: -52px -409px; }
  &.countrycode-TC { background-position: -52px -430px; }
  &.countrycode-TD { background-position: -52px -451px; }
  &.countrycode-TF { background-position: -52px -472px; }
  &.countrycode-TG { background-position: -52px -493px; }
  &.countrycode-TH { background-position: -52px -514px; }
  &.countrycode-TJ { background-position: -52px -535px; }
  &.countrycode-TK { background-position: -52px -556px; }
  &.countrycode-TL { background-position: -52px -577px; }
  &.countrycode-TM { background-position: -52px -598px; }
  &.countrycode-TN { background-position: -52px -619px; }
  &.countrycode-TO { background-position: -52px -640px; }
  &.countrycode-TR { background-position: -52px -661px; }
  &.countrycode-TT { background-position: -52px -682px; }
  &.countrycode-TV { background-position: -52px -703px; }
  &.countrycode-TW { background-position: -52px -724px; }
  &.countrycode-TZ { background-position: -52px -745px; }
  &.countrycode-UA { background-position: -52px -766px; }
  &.countrycode-UG { background-position: -52px -787px; }
  &.countrycode-UM { background-position: -52px -808px; }
  &.countrycode-US { background-position: -52px -829px; }
  &.countrycode-UY { background-position: -52px -850px; }
  &.countrycode-UZ { background-position: -52px -871px; }
  &.countrycode-VA { background-position: -52px -892px; }
  &.countrycode-VC { background-position: -52px -913px; }
  &.countrycode-VE { background-position: -52px -934px; }
  &.countrycode-VG { background-position: -52px -955px; }
  &.countrycode-VI { background-position: -52px -976px; }
  &.countrycode-VN { background-position: -52px -997px; }
  &.countrycode-VU { background-position: -52px -1018px; }
  &.countrycode-WALES { background-position: -52px -1039px; }
  &.countrycode-WF { background-position: -52px -1060px; }
  &.countrycode-WS { background-position: -52px -1081px; }
  &.countrycode-YE { background-position: -52px -1102px; }
  &.countrycode-YT { background-position: -52px -1123px; }
  &.countrycode-ZA { background-position: -52px -1144px; }
  &.countrycode-ZM { background-position: -52px -1165px; }
  &.countrycode-ZW { background-position: -52px -1186px; }
  &.countrycode-FA { background-position: -52px -1985px; } // Blank for Africa
  &.countrycode-FB { background-position: -52px -1985px; } // Blank for Middle East
  &.countrycode-FC { background-position: -52px -1985px; } // Blank for Rest of Europe
}