.front #block-block-28{
  display: none!important;
}

/*
 Meet the team block
 */
.about-us--wrapper {
  margin-top: 2em;
  @include extended-background(transparent);
  position: relative;
  text-align: center;
  z-index: 2;

  &:after{
    content: '';
    border: 1px solid $color-pop;
    height: 1px;
    position: absolute;
    top: 47%;
    bottom: 53%;
    left: -150%;
    right: -150%;
    z-index: 0;

  }

  .about-us--content {
    position: relative;
    z-index: 3;

  }
}

.pane-block-22{
  margin: 2em 0;
  h2 {
    text-align: center;
  }
}

#block-block-28{
  width: 100%;
  .about-us--wrapper {
    text-align: right;
  }
}
