// Map icon names to font unicode characters
$icons: (
        pin : "\e917",
         location : "\e917",
         map : "\e917",
         marker : "\e917",
         corner-bracket : "\e908",
         close : "\e915",
         loop : "\e90a",
         checkmark : "\e90b",
         bubbles : "\e90c",
         search : "\e90d",
         add-to-shopping-cart : "\e90e",
         circle-cross-reversed : "\e913",
         my-account : "\e916",
         shopping-cart : "\e919",
         map-marker : "\e918",
         map-marker-alt : "\f3c5",
         instagram : "\e914",
         select-arrows : "\e911",
         questions : "\e912",
         fluke-nation : "\e909",
         registration : "\e001",
         manual : "\e002",
         where-to-buy : "\e003",
         awards : "\e012",
         benefits : "\e900",
         career : "\e901",
         fluke-ig : "\e902",
         information : "\e903",
         manager-resources : "\e904",
         news : "\e905",
         recognition : "\e906",
         time-pay : "\e907",
         search-fa-search : "\f002",
         user : "\f007",
         home : "\f015",
         arrow-circle-o-up : "\f01b",
         print : "\f02f",
         fa-arrow-down : "\f063",
         chevron-right : "\f054",
         check-circle : "\f058",
         fa-arrow-up : "\f062",
         fa-arrows-h : "\f07e",
         plus : "\f067",
         minus : "\f068",
         exclamation-triangle : "\f071",
         warning : "\f071",
         twitter : "\f099",
         facebook : "\f09a",
         facebook-f : "\f09a",
         fa-bars : "\f0c9",
         google-plus : "\f0d5",
         caret-down : "\f0d7",
         caret-up : "\f0d8",
         caret-right : "\f0da",
         envelope : "\f0e0",
         linkedin : "\f0e1",
         angle-left : "\f104",
         angle-right : "\f105",
         angle-up : "\f106",
         angle-down : "\f107",
         youtube : "\f167",
         file-pdf-o : "\f1c1",
         reddit-alien : "\f281",
         zoom-in : "\e90f",
         circle-cross : "\e910",
         naver : "\e91a",
         tmall : "\e91b",
         youku : "\e91c",
         vk : "\f189",
         weibo : "\f18a",
         truck: "\f0d1",
         box: "\f466",

);
