.view-id-fbc_webinars {
    &.view-display-id-default {
        background: $gray-lightest;
        margin-left: 2em;
        padding: 1em;
    }
    .views-row {
        margin-bottom: 2em;
        h2,
        h3 {
            margin-bottom: 0;
        }
        img {
            display: block;
            margin: auto auto 1em;
        }
    }

}
