

header {
  .mm-menu.mm-current,
  .mm-menu.mm-offcanvas {
    display: block!important;
  }
  #mmenu_left {
    //display: none!important;
  }
  #mmenu {
    display: block;
    position: absolute;
    top: 1em;
    right: 0.5em;
    height: 30px;
    width: 30px;
    a {
      position: absolute;
      color: transparent;
      height: 30px;
      width: 30px;
      top: 0;
      left: 0;
      background: $gray;
      overflow: hidden;
      &:before {
        content: '\f0c9';
        color: $white;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        text-align: center;
        font-family: FontAwesome;
        font-size: 20px;
        line-height: 30px;
      }
    }
    @include media($screen-md) {
      display: none;
    }
  }
}
/* Blocker */
#mm-blocker {
  background: #000 !important;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}
#mmenu {
  padding: 1em;
  // Hid sticky nav. Causing mmenu button to change position on mobile
  //  .stickynav-active & {
  //    padding: .5em;
  //  }
  //  .stickynav-active & {
  //    padding: .5em;
  //  }
  button {
    background: rgba($white, .2);
    padding: .6em;
    border-radius: 0;
    //  .stickynav-active & {
    //    padding: .75em;
    //  }
    //    .stickynav-active & {
    //    padding: .75em;
    //  }
    .fluke-icon {
      font-size: 15px;
      content: "\f0c9";
      font-family: FontAwesome;
      color: $white;
    }
  }
}
.mmenu-nav.mm-basic {
  background-color: $gray-very-dark; // Prev rgba($background-color-darkest, .75);
  a {
    color: $white;
  }
  .mm-header {
    height: 85px; // 70px
    border-color: transparent;
    color: $white;
    line-height: 70px;
    background: $gray-very-dark ; //$background-color-darkest
    padding-top: 185px;
    position: relative;
    img {
      position: absolute;
      top: 1em;
      left: 1em;
    }
  }
  &.mm-vertical li.mm-opened > ul {
    padding-left: 0;
    & > li > ul > li {
      padding-left: 1em;
    }
  }
  .mmenu-mm-list-level-2
  .second-lvl {
    padding-left: .5em;
  }
  &.mm-vertical li {
  }
  .mm-list > li > a.mm-subopen {
    &:after {
      top: 10px;
    }
  }
  .mm-list {
    .mm-subopen {
      background: $country-picker-inactive;
      width: 90px;    //Added
      &:before {
      }
      @include icon(after, angle-right, true); //replaced plus
      font-size: $font-size-h2; //added font size
      &:after {
        @include transform(rotate(0deg));
        border: none;
        top: 10px;
      }
      &:active {
        background: rgba($gray-medium, .5);
      }
    }
  }
}
.mmenu-block-title {
  display: inline-block;
  padding-left: .5em;
}
.mmenu-nav.mm-basic.mm-vertical li.mm-opened {
  > ul li {
  }
  > a {
    &.mm-subopen {
      @include icon(after, minus);
      color: $special-action-color;
      &:after {
        @include transform(rotate(0deg));
      }
    }
    &:not(mm-subopen) {
      color: $special-action-color;
      @include icon(before, angle-down);
    }
  }
}
li.mobile-top-lvl > a {
  &:before {
    content: ''!important;
  }
}
.mmenu-nav.mm-basic > .mm-list li {
  border-top: none;
  border-bottom: 1px solid $gray-darkest;
  //line-height:$spacing-3; //Prev 40px
  &:last-child {
    border-bottom: none;
  }
  a {
    display: inline;
  }
}
.mmenu-nav {
  .mobile-second-lvl{
    a {
      padding-left: 25px;
      //@include icon(before, angle-right); //Removed icon
      &:before {
        margin-left: -8px;
        padding-right: 8px;
      }
      &:not(.mm-subopen) {
        color: $white;
      }
    }
  }
}
// When this is mobile, don't show the display of "other Fluke companies"
// edw 20190531
#mm-superfish-3 {
  li {
    width: 100%;
    &:nth-child(1){
      display: none;
    }
  }
}

/* Menu nav */
.mmenu-nav:not(.mm-menu) {
  display: none;
}
/* Moves Menu item panel under header */
.mmenu-nav.mm-basic.mm-hasheader > .mm-panel {
  padding-top: 95px;
}
/* Mobile Navigation Styles */
.mm-menu.mm-hasheader li.mm-subtitle {
  display: block;
  background: none;
  border-bottom: none;
}
.mm-menu .mm-list > li > a.mm-subclose {
  background: none;
  color: $base-font-color;
  text-shadow: none;
  &:before {
    border-color: transparent;
  }
}
//Move first list down below header + search
.mm-menu .mm-list {
  //margin: $spacing-3 0 0;
  padding: 0;
}
//Lines up title with < and X icons
.mmenu-nav.mm-basic > .mm-header .mm-title {
  //font-size: $font-size-h3;
  font-family: $headings-font-family;
  position: absolute;
  top: 125px;
  //right: $spacing-1;
  text-align: right;
  padding: 0;
  margin-right: 1.5rem;
}
//Adds additional spacing to search widget
//Search block added to MMenu header area.
.mmenu-search{
  position: absolute;
  z-index: 10;
  width: 100%;
  padding: 15px;
  top: 75px;
  input {
    border: none;
    border-radius: 30px;
    font: inherit;
    line-height: 30px;
    outline: none;
    display: block;
    height: 30px;
    margin: 0;
    background: rgba(255, 255, 255, 0.3);
    color: rgba(255, 255, 255, 0.6);
    &[type="submit"]{
      display: none;
    }
  }
}
//Close button - This is added in the module footer.
//TODO: Move to the proper "header" section. I couldn't get that + title and back simultaniously.
.mmenu-close {
  background: rgba(255, 255, 255, 0.2);
  padding: 17px;
  position: fixed;
  //right: $spacing-1;
  //top: $spacing-1;
  &:after {
    content: "\f0c9";
    font-family: FontAwesome;
    color: $white;
  }
}
//Remove default MMenu footer styles
.mmenu-nav.mm-basic > .mm-footer {
  background: none;
}
//Prev submenu button
.mmenu-nav.mm-basic > .mm-header .mm-prev {
  //left: $spacing-1;
  top: 12.5rem;
  border-color: $white;
  // Makes the back arrow a little bigger
  &:before {
    border-color: white;
    border-width: 3px;
  }
}
// All menu tiers the same font size with sliding menus
#mmenu_left li:not(.mobile-top-lvl) {
  font-size: 1.6rem;
  background: #373737; // Matches Lang Chooser
  margin: 0 15px 0;
}
// Removed rogue bottom border under top level menu.
//TODO: Find source without !important
.mm-list > li.mmenu-collapsed {
  border-bottom: none !important;
  //margin: 0 $spacing-1 0;
}
//Adjust counter size
.mmenu-nav.mm-basic em.mm-counter {
  color: $white;
  //right: $spacing-3;
  font-size: 1.6rem;
  z-index: 3;
}
//Add logo to header
#mmenu_left .mm-header:before {
  content: url(/sites/all/themes/ig_neato/logo.svg);
  position: absolute;
  //top: $spacing-1;
  //left: $spacing-1;
}
li.mobile-second-lvl {
  //margin: 0 $spacing-1 0;
}
.mmenu-nav.mm-basic > .mm-list li > a:not(.mm-subopen):not(.mm-subclose):not(.mm-fullsubopen) {
  padding: 10px 17px;
}
