//.page-eform-submit-service-request-form{
//  #block-system-main{
//    @include outer-container();
//    .horizontal-tabs-panes{
//
//      .product-tab{
//        .fieldset-wrapper {
//          width: 100%;
//          display: flex;
//        }
//      }
//      .request-tab{}
//      .customer-information-tab{}
//      .fieldset-wrapper{
//        //width: 100%;
//        //display: flex;
//
//
//        .eform-left,
//        .eform-right{
//          display: inline-block;
//          flex-basis: 50%;
//        }
//        .eform-right{
//          border: 1px solid $base-font-color;
//        }
//      }
//    }
//    #edit-field-select-product-und{
//      display: flex;
//      justify-content: space-between;
//      flex-wrap: wrap;
//
//      .form-item{
//        display: inline-block;
//        flex-basis: 33%;
//        position: relative;
//        margin-bottom: 1em;
//      }
//      label{
//        position: relative;
//        height: 160px;
//        width: 160px;
//        @include clearfix;
//        display: inline-block;
//        padding-top: 160px;
//        background: none;
//        border: none;
//        font-weight: 700;
//        text-align: center;
//        &:before{
//          content: "";
//          border: 1px solid $gray-light;
//          position: absolute;
//          top: 0px;
//          left: 0;
//          height: 150px;
//          background-repeat: no-repeat;
//          background-size: 90%;
//          text-align: center;
//          margin: 0 auto;
//          display: block;
//          width: 100%;
//          background-position: center;
//        }
//      }
//      input{
//        opacity: 0;
//        position: absolute;
//        top: 0;
//        left: 0;
//        background: none;
//      }
//    }
//    label[for='edit-field-select-product-und-raysafe-xi']{
//      &:before{
//        background: url(../images/service-request/Xi_system_prestige_RaySafe.png);
//      }
//    }
//  }
//}