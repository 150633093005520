.view-mode-banner_with_image_and_text,
.banner-img-left-text-right,
.banner-img-right-text-left{
  //margin-left: -1em;
  //margin-right: -1em;
  position: relative;
  @include media($screen-sm){
    height: 400px;
  }
  //.background-section{
  //  position: absolute;
  //  bottom: 0;
  //
  //  @include media($screen-sm){
  //    width: 100%;
  //    height: 100%;
  //    top: 0;
  //    left: 0;
  //    z-index: 1;
  //    overflow: hidden;
  //    img{
  //      position: absolute;
  //      top: 0;
  //      //bottom: 0;
  //      right: 0;
  //      height: 100%;
  //      width: auto;
  //      max-width: none;
  //    }
  //  }
  //
  //}

  //.foreground-section{
  //  position: relative;
  //  z-index: 2;
  //  display: flex;
  //  width: 100%;
  //  justify-content: space-between;
  //  flex-direction: column;
  //  align-items: center;
  //  @include media($screen-sm){
  //    height: 400px;
  //    flex-direction: row;
  //  }
  //  .foreground-image,
  //  .text-area{
  //    display: block;
  //    @include media($screen-sm){
  //    display: inline-block;
  //    flex: 0 1 48%;
  //    overflow: hidden;
  //    width: 48%;
  //  }
  //
  //
  //  }
  //  .foreground-image{
  //    height: 100%;
  //    overflow: hidden;
  //    position: relative;
  //    @include media($screen-sm){
  //      height: 100%;
  //
  //    }
  //    .field{
  //      height: 100%;
  //      >.field-items{
  //        height: 100%;
  //        >.field-item{
  //          height: 100%;
  //          display: flex;
  //          flex-direction: column;
  //          justify-content: space-around;
  //        }
  //      }
  //    }
  //
  //      img{
  //        width: 100%;
  //        max-width: 100%;
  //        height: auto;
  //        max-height: 100%;
  //
  //      }
  //  }
  //
  //
  //  .text-area{
  //    flex-direction: column;
  //    justify-content: space-around;
  //    height: auto;
  //    margin: auto;
  //    padding: 1em;
  //    @include media($screen-sm){
  //      padding-left: 2em;
  //    }
  //    >.field{
  //      flex: 0 1 100%;
  //      width: 100%;
  //    }
  //    >div{
  //      display: inline-block;
  //    }
  //  }

  //}
  .foreground-image{}
}
