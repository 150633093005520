.view-fbc-resources{
  td{
    margin: .5em;
  }
  &.view-display-id-block_7,
  &.view-display-id-block_2{
    .views-exposed-widgets{
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      width: 100%;
    }
    .views-exposed-widget{
      display: inline-block;
      float: none;
      clear: both;
      .form-item-term-node-tid-depth,
      .form-item-field-resource-type-tid-selective{
        flex: 0 1 75%;
        display: inline-block;
        line-height: 40px;
        .form-type-bef-link{
          display: inline-block;
          margin-right: 1em;
          a{
            @include button($white, transparentize($color-primary, .25), .25em .5em);
            -webkit-border-radius:2px;
            -moz-border-radius:2px;
            border-radius: 2px;
          }
        }
      }
    }
    .views-widget-filter-field_language_tid{
      flex: 0 1 25%;
      margin-top: -30px;
      label{
        display: inline-block;

      }
      .views-widget{
        display: inline-block;
        select{
          padding: .25em .5em;
        }
      }
    }
    .views-row{
      display: flex;
      margin-bottom: 1em;
      justify-content: flex-start;
      &>div{
        display: inline-block;
        flex: 0 1 33%;
        margin-left: 1em;
        font-size: 1.4rem;
        @include media($screen-lg){
          font-size: $base-font-size;
        }
      }
      .views-field-download{
        flex: 0 1 66%;
        margin-left: 0;
        img{
          height: 14px;
          width: 14px;
        }
      }
    }
  }
  &.view-display-id-block_8 {
    h3 {
      font-weight: 600;
      text-align: left;
      background: linear-gradient(to bottom, rgba(51, 51, 51, 0.5) 0, rgba(40, 40, 40, 0.5) 100%);
      border-top: none;
      color: #fff;
      padding: 0.75rem 1rem;
    }
    .views-row {
      display: flex;
      width: 100%;
      padding: 0.5em 0;
      justify-content: space-between;

      &:hover {
        background: $gray-lightest;
      }
      > .views-field {
        display: inline-block;
      }

      .views-field-title {
        width: 70%;
      }
      .views-field-field-page-sections-et{
        .field-content {
          background: white;
          border: 1px solid $color-secondary;
          padding: 0.3em 2em;
          -webkit-border-radius: 1em;
          -moz-border-radius: 1em;
          border-radius: 1em;

          &:hover {
            background: darken($white, 10%);
            border: 1px solid darken($color-secondary, 5%);

          }
        }

        a {
          font-family: $font-proxima-nova-semibold;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  &.view-display-id-block_1,
  &.view-display-id-block_3{

    h3{
      .field-name-field-product-category{
       @extend %table-caption;
        margin-bottom: 1em;
      }
    }
    .views-row{
      position: relative;
      .field-name-field-product-category{
        position: absolute;
        z-index: 1;
        top: -5px;
        left: -5px;
        transform: rotate(-5deg);
        background: $base-font-color;
        color: $white;
        padding: 3px 5px;

      }
      .views-field-field-video-1{
        position: relative;
        &:after{
          pointer-events: none;
          position: absolute;
          height: 50px;
          width: 50px;
          text-align: center;
          padding-left: 5px;
          top: 0;
          bottom: 0;
          display: block;
          left: 0;
          right: 0;
          margin: auto;
          content: '\f04b';
          font-family: FontAwesome;
          color: $color-primary;
          font-size: 30px;
          line-height: 47px;
          border: 2px solid $color-primary;
          -webkit-border-radius: 1em;
          -moz-border-radius:1em;
          border-radius: 1em;
          background: transparentize($white, .5);
        }

      }
    }

  }
  &.view-display-id-block_10,
  &.view-display-id-block_14 {
    display: flex;
    flex-direction:column;
    .view-header{
      order: 2;
      text-align: right;
      font-style: italic;
    }
    .view-filters{
      order: 1;

    }
    .view-content{
      order: 3;
    }
    .item-list{
      order: 4;
    }
    label{
      display: inline-block;
      flex: 0 1 20%;
      width: 20%;
      padding: 0 1em;


    }
    .views-submit-button,
    .views-reset-button{
      display: inline-block;
      margin-right: 1em;
      width: auto;
    }
    .views-widget{
      display: inline-block{
        flex: 0 1 80%;
      }

    }
    .view-grouping{
      margin-bottom: 2em;
      .view-grouping-header{
        padding: .5em;
        background: $gray-lighter;
        p{
        margin: 0;
        font-family: $font-proxima-nova-semibold;}
      }
      table{
        margin: 0;
        caption{
          background: none;
          background: $gray-lightest;

          color: $base-font-color;
        }
        thead{
          display: none;

          tr{}
          th{
            background: none;
            color: $base-font-color;
            font-weight: normal;
            font-size: 1.5rem;
            border: 0;
            &:nth-child(2){
              width: 15%;
            } &:nth-child(3){
              width: 20%;
            }
          }
        }
        tbody{
          tr{
            &:hover{
              background: transparentize($gray-lightest, 0.50);
            }
          }
          td {
            border: 0;
            &:first-child{
              width: 60%;
              padding-top: .5em;
            }
            &:nth-child(2){
              width: 20%;
            }
            &:nth-child(3){
              width: 20%;
            }
          }
        }

      }
    }
    .views-widget-filter-field_h1_title_value{
      background: $gray-lighter;
      color: $base-font-color;
      margin-bottom: 2em;
      position: relative;

      .description{
        position: absolute;
        bottom: -26px;
        left: 3em;
      }
    }
    .views-exposed-form .views-exposed-widget{
      //width: 100%;
      float: none;
      clear: both;
    }
    .views-widget-filter-field_category_tid{



    }
    .views-widget-filter-title_selective{

    }
    .views-widget-filter-field_category_tid,
    .views-widget-filter-title_selective{
      padding: 0;
      background: $gray-lighter;
      .form-select{
        &:after{
          top: 10px;
        }
      }
    }
  }
}
.view-resource-downloads {
  .view-content {
    padding-left: 5px;
    .views-row {
      margin-bottom: 7px;
      .button {
        margin: 0
      }
    }  
  }
}

#block-views-resource-downloads-block, #block-views-resource-downloads-block-1 {
  h3 {
    font-weight: normal;
  }
}