@mixin slide-height{
  height: 400px;
  @include media($screen-xs){
    height: 500px;
  }
  @include media($screen-md){
    //border: 1px solid orangered;
    height: 600px;
  }
  @include media($screen-lg){
    height: 600px;
    //border: 1px solid blue;
  }
}
.front {

  //  MAIN CONTAINER AREA
  .pane-fbc-product-categories-front-page {
    .view-fbc-product-categories-front-page {

      width: 100%;
      @include outer-container();

      .view-content {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 0 1em;
        min-height: 200px;

        @include media($screen-xs) {
          padding: 0;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-around;
        }
        @include media($screen-md) {
          flex-wrap: nowrap;
          padding: 2em 0;
        }

        .views-row {
          flex: 0 1 100%;
          display: flex;
          flex-direction: row;
          text-align: left;
          justify-content: flex-start;
          margin-bottom: 2em;
          padding: 0 5vw;
          @include media($screen-xs) {
            flex: 0 1 40%;
            text-align: center;
            flex-direction: column;

          }
          @include media($screen-md) {
            padding: 0;
            flex: 0 1 20%;
          }

          a:nth-child(2) {
            position: relative;
            width: 100%;
            display: table;
            height: 40px;
            padding-right: 2em;
            @include media($screen-xs) {
              height: 50px;
            }
            @include media($screen-xs) {
              height: 60px;
            }

          }

          .logo-category {
            flex: 0 1 10%;
            margin: auto;
            margin-right: 1em;
            min-height: 100px;
            @include media($screen-xs) {
              margin-right: 0;
              width: 100%;
              text-align: center;

            }
            @include media($screen-md) {
              text-align: left;
              //width: auto;
              flex: 0 1 100%;
            }

            a.icon-wrap {
              height: 100px;
              width: 100px;
              background-repeat: no-repeat;
              background-image: url('../images/icons/hexagon-solid-gray.svg');
              background-size: 100%;
              position: relative;
              display: block;
              background-position: 100%;
              margin: auto;

            }
            img {
              max-width: 60%;
              max-height: 60%;
              height: auto;
              width: auto;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;

            }

          }
          .description {
            flex: 0 1 90%;
            margin: auto;
            @include media($screen-xs) {
              flex: 0 1 100%;

            }
            h3 {
              display: block;
              color: $base-link-color;
              font-size: 2.0rem;
              @include media($screen-xs) {
                text-align: center;
              }
            }
          }
        }

      }
    }
  }

  .view-fbc-ctas {
    .slick--view--fbc-ctas--block{
      .view-mode-home_page {
        background: $gray-lightest;
        height: 400px;
        &.card-wide-layout{
          margin:0;
          padding:0;
        }
      }

      .card-content {
        @include outer-container();
        display: flex;
        padding: 0;
        height: 100%;

        .card-image {
          order: 1 !important;
          display: flex;
          flex: 0 1 40%!important;
          width: 40%;
              img{
                width: 100%;
                object-fit: contain;
              }

          .field-name-new-bug{
            position: absolute;
            top:3em;
            left: 3em;
            max-width: 50px;
            transform: rotate(-35deg);
          }
        }
        .card-text {
          order: 2;
          padding: 2em 0;
          margin: auto;

          & > div {
            display: inline-block;
          }

          .field-name-field-headline {

            @extend h3;
            color: $base-font-color;
          }

          .field-name-field-cta-link {
            a {
              @include button($color: $white);
            }
          }
        }
      }

    }


    &.view-display-id-block_1{
      background-color: #efeff9;
      display: block;
      .view-content{
        @include outer-container();
        display: flex;
        width: 100%;
        background: none;
        padding: 2em 0;
        flex-wrap: wrap;
        @include media($screen-md){
          flex-wrap: nowrap;
        }
      }
      .views-row{
        display: inline-block;
        margin: auto;
        background-color: transparent;
        padding: 0;
        position: relative;
        flex: 0 1 100%;
        max-width: 500px;
        @include media($screen-md){
          flex: 0 1 33%;
        }

        &:after {
          content: '';
          position: absolute;
          right: 20%;
          left: 20%;
          bottom: 0;
          height: 2px;
          background: transparentize($gray-light, .5);
          @include media($screen-md) {
            right: 0;
            left: auto;
            top: 20%;
            bottom: 20%;
            height: auto;
            width: 2px;
            background: transparentize($gray-light, .5);
          }
        }

        &:last-child {
          &:after {
            background: none;
          }
        }
        .card-tall-layout {
          padding: 0;
        }
        .card-content {
          flex-direction: column;
          text-align: center;
          justify-content: space-between;
          height: 300px;

          .card-image {
            text-align: center;
            max-height: 160px;
            margin-bottom: 1em;
            flex: 0 1 40%;
            min-height: 160px;

            img {
              max-height: 160px;
              max-width: 100%;
            }
          }
          .card-text {
            flex: 0 1 40% !important;
            p {
              color: $gray-dark;
            }
            h3 p {
              font-size: 2.8rem;
              line-height: 3rem;
              font-family: $font-proxima-nova-bold;
            }
          }
          .card-link {
            flex: 0 1 20%;
            order: 3;
            a {
              background: none;
              color: $action-color;
              font-family: $font-proxima-nova-bold;
            }
          }
        }
      }
    }
  }

  %logos-wrapper {
    .wrapper {
      position: relative;
      display: flex;
      margin: 0 auto;
      justify-content: space-around;
      height: 230px;
      width: 350px;

      @include media($screen-xs) {
        width: 400px;
      }

      @include media($screen-md) {
        width: calc(3 * 133px + 3 * 10px);
        height: auto;
        &:after {
          content: '';
          position: absolute;
          top: 47%;
          bottom: 100%;
          border-top: 2px solid rgba($yellow, .7);
          width: 1000%;
          z-index: -1;
          left: -500%;
        }

      }

      a {
        display: block;
        margin: 1em auto;
        position: absolute;
        background-color: transparent;
        background-size: 100%;
        height: 110px;
        width: 135px;
        @include media($screen-xs) {
          height: 125px;
          width: 150px;
        }

        @include media($screen-md) {
          background-color: $white;
          height: 114px;
          margin: 0;
          position: relative;
          top: auto;
          left: auto;
          right: auto;
        }
        &:nth-child(1) {
          top: -20px;
          left: 0;
          right: 0;
          @include media($screen-md) {
            top: auto;
            bottom: auto;
            left: auto;
            right: auto;
          }

        }
        &:nth-child(2) {
          left: 0;
          top: 40px;
          @include media($screen-md) {
            top: auto;
            bottom: auto;
            left: auto;
            right: auto;
          }
        }
        &:nth-child(3) {
          right: 0;
          top: 40px;
          @include media($screen-md) {
            top: auto;
            bottom: auto;
            left: auto;
            right: auto;
          }
        }

        img {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 70%;
          margin: auto;

        }

      }

    }
  }

  .pane-fbc-product-categories {
    padding-top: 2em;

    .view-display-id-block_8 {

      width: 100%;
      padding: 2em 0;
      @include outer-container();

      .view-content {
        //flex: 0 1 75%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 0 1em;
        @include media($screen-md) {
          padding: 0;
          flex-direction: row;
        }
        .views-row {
          padding: 0 30px;

          position: relative;
          flex: 0 1 100%;
          display: flex;
          flex-direction: row;
          text-align: left;
          justify-content: flex-start;
          @include media($screen-md) {
            flex: 0 1 20%;
            flex-direction: column;
          }

          h3 {
            display: table-cell;
            font-size: 2.0rem;
            color: $gray;
            vertical-align: middle;
          }
          .icon {
            position: absolute;
            top: 0;
            left: 0;
            height: 24px;
            width: 24px;

            .icon-wrap {
              background: none;
              img {
                max-width: 100%;
                height: auto;
                width: auto;
                max-height: 100%;
              }
            }
          }
          h3 {
            margin-top: 0;
          }
        }

      }
    }
  }
  //  CTAs for front page views (not panels)
  .view-id-fbc_ctas,
   .view-display-id-attachment_1{
    .view-content {
      background: $gray-lightest;
      //padding: 1em;
      @include media($screen-xl) {
        padding: 0;
        //padding-bottom: 1em;
      }

      .card-wide-layout {
        padding-bottom: 0;
      }
    }
    .card-image {
      flex: 0 1 30% !important;
      text-align: right;
      //img {
      //  width: auto !important;
      //}
    }
    .card-text {
      flex: 0 1 60% !important;
      .field-name-field-headline {
        @extend h3;
      }
      .field-name-field-cta-link {
        a {
          @include button($color: $white);
          white-space: normal;
        }
      }
    }
    .slick__slider{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .slick-list, slick-dots{
        display: inline-block;
      }
    }
    .slick-dots{
      position: absolute;
      bottom: 1em;
      right: 1em;
      width: auto;
    }
  }

  #block-block-12 {

    position: fixed;
    bottom: 2em;
    right: 0em;
    a {
      background-color: transparentize($blue, .75);
      color: transparentize($black, .75);

      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      box-shadow: 0 -2px 5px $base-font-color;
      -webkit-transition: background-color .5s, color .5s;
      -moz-transition: background-color .5s, color .5s;
      -ms-transition: background-color .5s, color .5s;
      -o-transition: background-color .5s, color .5s;
      transition: background-color .5s, color .5s;
      &:hover {
        color: transparentize($black, 0);
        background-color: transparentize($blue, 0);
        -webkit-transition: background-color .5s, color .5s;
        -moz-transition: background-color .5s, color .5s;
        -ms-transition: background-color .5s, color .5s;
        -o-transition: background-color .5s, color .5s;
        transition: background-color .5s, color .5s;

      }
    }
  }
}
// Front page CTA banners
.view-display-id-attachment_1,
.view-fbc-ctas.view-display-id-block {
 .view-content {
   background: none;
   //padding: 1em;
   @include media($screen-xl) {
     padding: 0;
     //padding-bottom: 1em;
   }

   .card-wide-layout {
     padding-bottom: 0;
   }
 }
 .card-image {
   flex: 0 1 30% !important;
   text-align: right;
   //img {
   //  width: auto !important;
   //}
 }
 .card-text {
   flex: 0 1 60% !important;
   .field-name-field-headline {
     @extend h3;
   }
   .field-name-field-cta-link {
     a {
       @include button($color: $white);
       white-space: normal;
     }
   }
 }
 .slick__slider{
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   .slick-list, slick-dots{
     display: inline-block;
   }
 }
 .slick-dots{
   position: absolute;
   bottom: 1em;
   right: 1em;
   width: auto;
 }
}

.slick--view--fbc-front-banner--block-3,
.view-display-id-block_3,
.slick--view--frontpage,
.slick--view--frontpage--attachment-1{
  .slick__slider{
    height: 400px;
    margin-bottom: 12em;
    @include media($screen-xs){
      height: 480px;
      margin-bottom: 2em;
    }

    @include media($screen-md){
      height: 600px;
    }
  }
  .slick-dots{
    position: absolute;
    bottom: 1em;
    text-align: right;
    margin-right: 1em;
    @include media($screen-md){
      text-align: center;
      margin-right: 0;
    }
  }
  .slide__content{
    @include media($screen-md){
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    }

  }
  .slick__slide{
    height: 400px;
    margin-bottom: 200px;
    position: relative;

    @include media($screen-sm){
      margin-bottom: 0;
      height: 600px;
      }

    .image-area {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height:  400px;
      width: 100%;
      overflow: hidden;

      @include media($screen-sm) {
        height: 600px;
      }

      video {
        position: relative;
        z-index: 1;
        display: none;

        @include media($screen-md) {
          display: block;
        }
      }
      picture {
        z-index: 0;
      }
    }

    img{
      min-height: 100%;
      min-width: 100%;
      max-width:none;
      width: auto;
      max-height: 400px;
      margin-right: 0;
      position: absolute;
      top: 0;
      right: 0;
      left: auto;
      margin-left: auto;
      @include media($screen-md) {
        max-height: 600px;
      }
    }
  }

  .text-area-wrapper{
    @include outer-container();
    text-align: center;
    padding-top: 400px;
    z-index: 3;
    @include media($screen-xs){
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding-top: 0;
      width: 1260px;
      @include outer-container();
      margin: 0 auto;
      padding-left: 1em;
      height: 476px;
      @include media($screen-md){
        height: 600px;

      }
      @include media($screen-lg){
        padding-left: 0;
      }
    }
  }

  .text-area{
    text-align: left;
    color: $black;
    position: relative;
    margin: auto;
    padding: 1em;
    @include media($screen-xs){
      padding: 0;
      position: absolute;
      bottom: 1em;
      display: table;
      background: none;
    }
    @include media($screen-md){
      top: 25%;
      bottom: 25%;
      max-width: 50%;

    }
      img{
      margin-left: 0;
      height: 44px;
        max-height: 44px;
      width: auto;
      display: inline-block;
      margin-bottom: 2rem;
        position: absolute;
        top: -60px;
        left: 1em;
        min-height: 0;
        min-width: auto;

        @include media($screen-xs){
          top: 0;
          left: 0;
          margin-bottom: 1em;
          position: relative;
        }

        }
    h2,
    h2 > p{
      font-size: 2.3rem;
      font-family: $font-proxima-nova-light;
      color: $black;
      font-weight: normal;
      line-height: 1;
      margin-bottom: 2rem;
      text-align: center;
      @include media($screen-xs){
        font-size: 3.5rem;
        text-align: left;
      }
      @include media($screen-md){
        font-size: 4rem;
      }
    }
    h3,
    h3 > p{
      color: $black;
      font-size: 1.9rem;
      font-family: $font-proxima-nova-light;
      font-weight: normal;
      line-height: 1;
      margin-bottom: 2rem;
      text-align: center;
      @include media($screen-xs){
        font-size: 2.5rem;
        text-align: left;
      }
      @include media($screen-md){
        font-size: 3rem;
      }
    }
    a{
      @include button($color: $white);
      display: table;
      margin: auto;
      @include media($screen-xs){
        text-align: left;
        display: inline-block;
      }
    }
    span{
      font-family: $font-proxima-nova-light;
    }
    .no-wrap{
      white-space: nowrap;
    }
  }



}