//input[type="text"],
//select{
//  background: $gray-lightest;
//  border-style:none;
//  border: 1px solid darken($gray-lightest, 10%);
//  height: 36px;
//}

//.form-type-select,
//.form-select,
//.select-wrapper{
//  position: relative;
//  @include icon(after, select-arrows);
//  &:after{
//    position: absolute;
//    top: 12px;
//    right: .5em;
//    pointer-events: none;
//    @include media($screen-md){
//      top: 28px;
//
//    }
//  }
//
//}
//select{
//  -webkit-appearance: none;
//  -moz-appearance: none;
//  appearance: none;
//  border-radius: 0;
//  padding: .5em;
//  width: 100%;
//
//}
//.form-submit,
//input[type="submit"]{
//  height: 36px;
//  padding: .5em 1em;
//
//}
.page-node-5912{
  .paragraphs-item-text-only{
    @include outer-container();
    margin-top: 5em;
    padding: 1em;
    background: $gray-lightest;
  }
  .paragraphs-item-related-view{
    padding: 0;
    margin: 0 0 2em 0;
  }
}
.view-fbc-where-to-buy{
  .view-filters{
    margin-bottom: 1em;
    .views-exposed-widgets{
      position: relative;
      .views-submit-button{
        width: auto;
      }
    }
    .views-exposed-widget{
      float: none;
      display: block;
      width: 100%;
      padding: 1em;
      margin: 0;
      @include media($screen-lg){
        padding: 0;
      }
      .form-item{
        padding: 0;
        margin: 0;
      }
      .form-select{
        line-height: 24px;
        height: 30px;
        margin:0;
        margin-bottom: 0.5em;
        padding: 0;
      }

      label{
        display: block;
        @include media($screen-xs){
          display: inline-block;
          width: 25%;
        }

        }
      .views-widget{
        padding: 0;
        margin: 0;
        display: inline-block;
        @include media($screen-md){

        }
      }
    }
  }
  .item-list{
    @include clear-ul;
    li{
      padding: 1em!important;
    }
  }
  .views-row{
    padding-bottom: 2em;

    border-bottom: 1px solid $gray-lightest;
    margin-bottom: 2em;

    .partner{
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
      @include media($screen-md){
        flex-wrap: nowrap;
      }
      .info-section{
        flex: 0 1 100%;
        @include media($screen-md){
          flex: 0 1 25%;

        }
        .info-label{
          display: inline-block;
          width: 125px;

        }
        .info-value{
          display: inline-block;

        }
        &.gsa-partner-logo{
          flex: 0 1 15%;
          @include media($screen-md){
            flex: 0 1 10%;

          }
        }
        &.title-address{
          flex: 0 1 75%;
          margin-bottom: 2em;
          @include media($screen-md){
            flex: 0 1 15%;

          }
        }

        &.contact-info,
        &.gsa-partner-info{
          flex: 0 1 50%;
          @include media($screen-md){
            flex: 0 1 35%;

          }
        }

      }
    }
  }
  &.view-id-fbc_where_to_buy{
    .views-row{
      //padding-bottom: 2em;
      .partner{
        //.info-section{
        //  flex: 0 1 100%;
        //  @include media($screen-md){
        //    flex: 0 1 22%;
        //
        //  }
        //  .info-label{
        //    display: inline-block;
        //    width: 125px;
        //
        //  }
        //  .info-value{
        //    display: inline-block;
        //    width: 200px;
        //
        //  }
        //  &.gsa-partner-logo{
        //    flex: 0 1 15%;
        //    @include media($screen-md){
        //      flex: 0 1 10%;
        //
        //    }
        //  }
        //  &.title-address{
        //    flex: 0 1 75%;
        //    margin-bottom: 2em;
        //    @include media($screen-md){
        //      flex: 0 1 25%;
        //
        //    }
        //  }
        //
        //  &.contact-info,
        //  &.gsa-partner-info{
        //    flex: 0 1 50%;
        //    @include media($screen-md){
        //      flex: 0 1 25%;
        //
        //    }
        //  }
        //
        //}
      }
    }
  }
}