.not-logged-in {
    #block-views-fbc-attribution-urls-block-1 {
        display: none;
    }
}

#block-views-fbc-attribution-urls-block-1 {
    position: fixed;
    top: 300px;
    left: -600px;
    height: 600px;
    width: 600px;
    box-shadow: -2px -2px 5px #555;
    padding: 1em;
    background-color: $white;
    transition: left 0.5s;
    z-index: 3;

    &:hover {
        left: 0;
        transition: left 0.5s;

        &::after {
            animation: unicorn 3s 5s ease-in-out 1;
        }
    }

    &::before {
        border-radius: 0 4px 4px 0;
        box-shadow: -2px -2px 5px #555;
        font-family: FontAwesome;
        content: "\f0c1";
        height: 50px;
        width: 50px;
        position: absolute;
        top: 1em;
        right: -50px;
        background-color: lightpink;
        color: #fff;
        font-size: 40px;
        line-height: 50px;
        text-align: center;
    }

    @keyframes unicorn {
        0% {
          opacity: 0;
          top: 0px;
            right: 600px;
        }
        50% {
            opacity: 1;
            top: 500px;
            right: -300px;
          }
        100% {
          opacity: 1;
          top: -1000px;
         right: -3000px;
        }
      }


    &::after {
        content: '';
        height: 250px;
        width: 250px;
        background-image: url(../images/clipart207713.png);
        background-repeat: no-repeat;
        background-size: 250px;
        position: absolute;

        top: 0px;
        right: 1000px;

        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
        z-index: -10;
    }
    .small {
        font-size: 1.2rem;
        font-style: italic;
    }
    .button {
        background-color: $gray-light;
        padding: 0.25em;
        border-radius: 4px;
        cursor: pointer;

    }
    .view-empty {
        padding: 1em;
    }
    .error {
        color: red;
        font-size: 1.8rem;
    }
}