.views-exposed-widgets{
  width: 100%;
  clear: both;
  float: none;

  input[type="submit"]{
    @include button($color: $white, $type:$color-primary, $padding: 0.5em 1em)
  }
  label{
    line-height: 40px;
    //vertical-align: top;
    display: inline-block;
    margin-right: 1em;
  }
  .views-widget{
    display: inline-block;
  }
}