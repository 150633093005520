.field-name-paragraphs-accordion-formatter {
  .panel-heading{
    background: #EBF0F5;
  }
  h3 {
    font-size: 2.4rem;
    font-family: $font-proxima-nova-regular;
    font-weight: normal;
  }
  .custom-collapse .panel-heading a[data-parent='#accordion'] {
    padding: .6em;
    &:after {
      padding: .4em 0;
      top: 0;
      left: 0;
    }
    
  }
}

details{
  margin-bottom: 0px;
  position: relative;

  summary {
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;
    
    &:before{    
      width: 50px;
      height: 50px;
      margin-right: 5px;
      position: absolute;
      top: 0;
    }
  }

  &[closed] {
    summary {
      &:before {
        content: '\25BA';
      }
    }
    }

  &[open] {
  summary {
      &:before {
        content: '\25BC';

  }
    }


  }
}

