.page-partner-tools,
.page-channel-tools,
.page-node-20277 {

  .view-partner-dashboard {
    @include outer-container();

    & > .view-content {
      .page-header-wrapper {
        @include extended-background();
        background-image: url('/sites/all/themes/fluke_health/images/backgrounds/hexagons-category-3000x604.png');
        max-height: 100px;
        overflow: hidden;
        margin-bottom: 2em;
        background-position: bottom;
        .page-header {
          @include outer-container();
          padding: 1em 0;
          display: flex;
          width: 100%;
          h1 {
            display: inline-block;
            width: 50%;
            margin: auto;
          }
          .welcome-message {
            display: inline-block;
            width: 50%;
            margin: auto;
          }
        }

      }
    }
    .attached-views {
      width: 100%;
      .grid--3-col{
        display: inline-block;
        width: 68%;
        vertical-align: top;
      }
      .grid--1-col {
        display: inline-block;
        width: 30%;
        background: $gray-lightest;
        padding: 1em;
      }
    }
    .view-fbc-channel-enablement {
      margin-bottom: 2em;

      &.view-display-id-block {
        .view-content {
          display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        h3 {
          flex: 0 1 100%;
          width: 100%;
          margin: 1em 0;
        }
          .views-row {
            width: 23%;
            display: inline-block;
            box-shadow: 0 0 3px $gray-lighter;
            margin-right: 1em;
            text-align: center;
            margin-bottom: 1em;
            .views-field-title {
              h3 {
                padding: 0 1em;
              }

            }

            &:hover {
              box-shadow: 0 0 6px $gray-light;
            }
            a {
              &:hover {
                text-decoration: none;
              }

              h3 {
                color: $blue;
              }
            }
          }
        }
      }
    }
  }
  .view-display-id-attachment_1 {
    background: none;
  }
}
  // End partner Dashboard section

  // Start resource page
.node-type-channel-enablement {

  .resource-page .header-wrapper {
    height: 150px;

    .field-name-breadcrumbs {
      position: absolute;
      top: -38px;
    }
  }
  .resource-body-wrapper {
    display: block;
    flex-wrap: wrap;
  }
  .resource-page {
    .resource-body {
      width: 100%;
      flex: none;
      display: block;
      width: 100%;
      vertical-align: top;

    }
  }

  .group-parent {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1em;

    & > .field {
      width: 100%;
      background-color: $gray-light;
      padding: 1em;
      .field-label {
        font-size: 3rem;
        font-weight: 700;
        line-height: 1;
        ;
      }

    }
    .group-left {
      display: inline-block;
      width: 60%;
      padding: 1em;
    }
    .group-right {
      display: inline-block;
      width: 40%;

      & > .field {
        background-color: $gray-lightest;
        padding: 1em;
      }

        .grid--column-2 {
          .view-content {
            display: flex;
            flex-wrap: wrap;
            .views-field-field-resource-thumbnail {
              background: $white;
              position: relative;
              img {
                max-height: 115px;
                height: 115px;
                object-fit: contain;
                margin: auto;
              }
            }
            .views-row {
              display: inline-block;
              width: calc(50% - 1em);
              margin-right: 1em;
              margin-bottom: 1em;
            }
          }
      }
    }
  }
  .quicktabs-views-group {
    display: flex;
    height: 50px;
    padding: 0.5em;

    &:hover {
      background: rgba($gray-lighter, 0.15);
    }

    & > div {
      width: 50%;
      padding-right: 1em;
      vertical-align: middle;
    }
  }

  .view-fbc-videos {
    display: flex;
    h3 {
      width: 100%;
    }
    .views-row {
      display: inline-block;
      width: calc(30% - 4em);
      min-width: calc(30% - 4em);
      margin-bottom: 1em;
      margin-right: 1em;
      vertical-align: top;

      .view-display-id-block_2 {
        a {
          font-size: 1.5rem;
        }
      }
    }
  }

  // End resource page

  // start quicktabs
  .quicktabs-tabs {
    border-bottom: 1px solid $gray-lighter;
    padding: 0.5em;

    li {
      border: 1px solid $gray-lighter;
      border-radius: 4px 4px 0 0;
      padding: 0.5em;
      margin: 0;
      background: $gray-lightest;

      &.active {
        border-bottom: 3px solid $white;
        background: $white;
      }

    }

    a {
      font-weight: 700;
      &:hover {
        text-decoration: none;
        color: darken($blue, 0.5);
      }
    }

    .active a {
      color: $base-font-color;
      text-decoration: none;
    }
  }

}
