.field-name-field-related-product {
  .field-items {
    .field-item{
      &.even{
        .card-image{
          order: 2;
        }
        .card-text{
          order: 1;
        }
      }
    }
  }
}