// .not-logged-in{
//   .messages.error{
//     display: none;
//   }
// }

.pink{
  background: pink;
}

.atlwdg-trigger{
  z-index:1000;
}