.node-type-application-2021{
    h1, h2, h3, h4, h5, h6, p {
        line-height: 1.25;
    }

    .entity-paragraphs-item{
        padding: 0;
        margin: 0;
    }

    .text-align-left {
        & > h1,
        & > h2,
        & > h3,
        & > h4,
        & > h5,
        & > h6,
        & p {
            text-align: left;
        }
    }
    .text-align-center {
        & > h1,
        & > h2,
        & > h3,
        & > h4,
        & > h5,
        & > h6,
        & p {
            text-align: center;
        }
    }
    .text-align-right {
        & > h1,
        & > h2,
        & > h3,
        & > h4,
        & > h5,
        & > h6,
        & p {
            text-align: right;
        }
    }

    iframe {
        border: none;
    }
}
