.field-name-field-app-webform {
  form {
    .form-wrapper {
      padding: 0;
    }
    .form-item {
      label,
      input {
        display: inline-block;;
      }
    }
  }

#webform-ajax-wrapper-19779,
#webform-client-form-19731 {
  .webform-component--list-all-products-of-interest {
    display: none;
  }
}

  footer {
    display: none;
  }
}
