.mobile-only {
  display: block;
  @include media($screen-md) {
    display: none !important;
  }
}

.desktop-only {
  display: none;
  @include media($screen-md) {
    display: block !important;
  }
}

#quicktabs-view__advantage_training__block {
  .item-list {
    ul.quicktabs-tabs {
      border-bottom: 2px solid $gray-medium;
      li {
        background-color: $gray-light;
        margin: 0;
        border-radius: 1em 1em 0 0;
        display: inline-block;
        max-width: 12%;
        &.active,
        &:hover {
          background-color: $gray-medium;
          color: white;
          border-bottom: 2px solid $gray-medium;
          a {
            color: $white;
            &:hover {
              text-decoration: none;
            }
          }
        }
        a {
          white-space: normal;
          text-align: center;
          font-weight: 700;
          display: flex;
          justify-content: center;
          height: 100px;
          margin: auto;
          padding: 1em 0.25em;
        }
      }
    }
  }
  .views-label {
    @extend h3;
    margin: 1em 0 0.5em 0;
    display: block;
  }

  .courses {
    width: 100%;
    display: block;

    .view-content {
      display: block;

      @include media($screen-md) {
        display: flex;
        gap: 10%;
      }

      .views-row {
        display: block;
        width: 100%;
        margin-bottom: 1em;

        @include media($screen-md) {
          display: inline-block;
          max-width: 33%;
          margin-bottom: 0;
        }
      }
    }
  }

  .field-name-field-course-link {
    font-size: 1.7rem;
    font-weight: 700;
    padding: 0 0 1em 0;
    line-height: 1.25;
    margin-top: 0.5em;
  }
  .tab-content {
    display: block;
    @include media($screen-md) {
      display: flex;
    }
    .tab-body {
      display: block;
      @include media($screen-md) {
        display: inline-block;
        width: 70%;
        padding-right: 2em;
      }
    }
    .tab-right-bar {
      display: block;
      text-align: center;
      @include media($screen-md) {
        display: inline-block;
        width: 30%;
      }
    }
  }
}

.slick--view--fbc-videos {
  padding: 0 3em;
  &.slick--view--fbc-videos--block-5,
  &.slick--view--fbc-videos--block-6 {
    h4 {
      font-size: 1.7rem;
      font-weight: 700;
      padding: 0 0 1em 0;
      line-height: 1.25;
      margin-top: 0.5em;
    }
    .slick__arrow {
      height: 100%;
      width: 100%;
      margin-left: -3em;
      top: 0;
      bottom: 0;
      .slick-arrow {
        height: 67%;
        width: 2em;
        top: 0;
        background-color: $gray-light;

        &:hover {
          background-color: $gray-medium;
          &:after {
            color: $white;
          }
        }
        &:after {
          top: calc(50% - 2.4rem);
          left: -88px;
        }
      }
    }

    .slick__slide {
      margin-right: 1em;
    }

    .colorbox-load {
      position: relative;
      min-height: 100px;
      display: block;

      &:before {
        content: "";
        position: absolute;
        top: calc(50% - 30px);
        left: calc(50% - 30px);
        height: 60px;
        width: 60px;
        border-radius: 30px;
        background-color: $blue;
      }

      &:after {
        content: "";
        position: absolute;
        top: calc(50% - 15px);
        left: calc(50% - 10px);
        width: 0;
        height: 0;
        border: 15px solid transparent;
        border-top: 0;
        border-bottom: 30px solid white;
        transform: rotate(90deg);
      }
    }
  }
}
