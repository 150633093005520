/**
 * - Back To Top Module
 * ----------------------------------------------------------------------------
 */

#backtotop {
    border-radius: 0;
    border: none;
    bottom: 3rem;
    color: $white;
    font-size: 3.8rem;
    height: 5rem;
    opacity: .4;
    padding: .6rem .9rem .5rem .9rem;
    right: 3rem;
    width: 5rem;
    &:hover {
        opacity: 1;
        background: $color-primary;
    }
}
