.view-frontpage.view-display-id-block_2 {
  @include outer-container();

  margin-top: 2em;
  margin-bottom: 2em;

  .slick--view--frontpage {
    .slick__slide {
      height: auto;
      margin: 0 0.5em;

      &:hover .slide__caption{
            height: 100%;
            transition: height 0.25s;
            pointer-events: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

      .slide__content {
        position: relative;
      }

      .slide__caption {
        height: 40px;
        padding: 0;
        transition: height 0.25s;
        overflow: hidden;
        background: transparentize($color: $black, $amount: 0.75);

        .slide__title {
          color: $white;
          font-size: 1.5rem;
          font-weight: 700;
          padding-bottom: 1em;

        }
        .slide__description {
          color: $white;
          font-size: 1.5rem;
        }
      }
    }

  }

}