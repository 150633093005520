body {
  @include font-feature-settings("kern", "liga", "pnum");
  -webkit-font-smoothing: antialiased;
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

*{
  font-family: $base-font-family;
}

%p,
p {
  font-size: $base-font-size * .90;
  margin: 0 0 $small-spacing * .90;
  line-height: $base-line-height * .90;
  padding-bottom: $base-line-height * .90;
  @include media($screen-sm){
    font-size: $base-font-size * .95;
    margin: 0 0 $small-spacing * .95;
    line-height: $base-line-height * .95;
    padding-bottom: $base-line-height * .95;
  }

  @include media($screen-md){
    font-size: $base-font-size;
    margin: 0 0 $small-spacing;
    line-height: $base-line-height;
    padding-bottom: $base-line-height;
  }
}

// these font sizes are in variables/typography.scss
$heads: (h1, $font-size-h1),
        (h2, $font-size-h2),
        (h3, $font-size-h3),
        (h4, $font-size-h4),
        (h5, $font-size-h5),
        (h6, $font-size-h6);

@each $head, $headvar in $heads{
  #{$head},
  %#{$head}{
    font-family: $heading-font-family;
    font-size: 0.85 * $headvar;
    font-weight: 500;
    line-height: 1.25 * $headvar;
    margin: 0 0 (.5 * $headvar) 0;
    color: $base-font-color;
    @include media($tablet){
      font-size: 0.90 * $headvar;
    }
    @include media($desk){
      font-size:  $headvar;
    }
  }
}

h2,
h2 a,
h2 > p{
    padding: 0;
    font-weight: normal;
}
h3,
h3 > p{
  font-family: $font-proxima-nova-semibold;
}
h4,
h4 > p{
  color: $base-font-color;
  font-family: $font-proxima-nova-semibold;
}

hr {
  //border-bottom: $base-border;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: $base-spacing 0;
}


li{
  //@extend p;
}

figcaption{
  font-size: 1.4rem;
  font-family: $base-font-family;
}

.field-label{
  line-height: 2;
}

.treatment-light-text {
  color: $white;
}

.treatment-dark-text {
  color: $base-font-color;
}
