@mixin sidebar-label{
    background: $teal-light;
    padding: .5em;
    border-bottom: 1px solid $gray-light;
}

@mixin remove-sidebar-label{
  background: none!important;
  padding: 0!important;
  border-bottom: 0px solid transparent!important;
}
@mixin page-title{
  legend{
    border-bottom: 1px solid $gray-light;
    width: 100%;
  }
  .fieldset-legend{
    font-weight: bold;
    font-size: 2rem;
    padding: .5em;
  }
}


.node-type-webform,
.page-node-submission,
.page-node-5926{
  background: $gray-lightest;
  #node-522,
  .webform-client-form-522{
    fieldset{
      margin: 0;
      padding: 0;
      border: none;
    }


    .form-wrapper{
      @include outer-container();
      .webform-progressbar{
        -webkit-box-shadow: inset 0 0 3px $gray-light;
        -moz-box-shadow: inset 0 0 3px $gray-light;
        box-shadow: inset 0 0 3px $gray-light;
        margin-bottom: 1em;
        height: 3em;
        padding: 0 5em;
        position: relative;
        &:before{
          content: '';
          position: absolute;
          top: -5px;
          left: -5px;
          right: -5px;
          width: calc(100% + 10px);
          height: calc(3em + 10px);
          border: 10px solid $gray-lightest;
          border-bottom: none;
        }


      }


      label{
        font-weight: bold;
      }

    }

    .section {
      .fieldset-wrapper{
        display: flex;
        .fieldset-wrapper{
          display: block;
        }

        .section-body {
          display: inline-block;
          flex: 0 1 55%;
          margin: 0;
          padding: 0 1em 0 0;
      }

        .section-overview{
          display: inline-block;
          flex: 0 1 45%;
          margin: 3em 0 0 0;
          padding: 0;
          .webform-next{
            margin-top: -2em;
          }
        }
      }
    }
    .webform-component--product-section{
      .section{
        &>.section-body{
          flex: 0 1 55%;
        }
        &>.serial-numbers{
          flex: 0 1 45%;
        }
      }
    }

    .webform-previous{
      display: inline-block;
      width: auto;
      background: $gray;
    }
    .webform-next{
      width: auto;
      float: right;
    }

    // listing of products in body section
      #edit-submitted-product-section-section-product-body-select-product-select-system-and-parts{
        margin-top: 3em;
      display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;

        & > .form-item{
          display: inline-block;
          flex: 0 1 30%;
          text-align: center;
          position: relative;

          img{
            background: $white;
            -webkit-box-shadow: 0 0 2px $gray;
            -moz-box-shadow: 0 0 2px $gray;
            box-shadow: 0 0 2px $gray;
            -webkit-box-shadow: 0 0 2px $gray;
            -moz-box-shadow: 0 0 2px $gray;
            box-shadow: 0 0 2px $gray;
            width: 150px;
            vertical-align: center;
            height: 150px;
          }


          input[type="radio"] + label{
            border: none;
            background: none;
          }
          input{
            visibility: hidden;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left:0;
            border: none;
          }
          label{}
        }

}
    // product serial numbers for product list
    .serial-numbers{
      fieldset{
        border: none;
      }
      .fieldset-wrapper{                      // outer wrapper
        padding: 0;
        legend{
          display: none;
        }
        .fieldset-wrapper{                    // inner wrapper
          box-shadow: 0 0 2px $gray;
          -webkit-box-shadow: 0 0 2px $gray;
          -moz-box-shadow: 0 0 2px $gray;
          box-shadow: 0 0 2px $gray;
          background: $white;
          .fieldset-description{
            padding: 1em;
            background: $teal-light;
          }
        }


      }
      .form-item{
        border-bottom: 1px solid $gray-lighter;
        position: relative;
        padding: 0 10px 0 100px;
        label,
        input{
          height: auto;
        }
        .description{
          text-align: left;
          margin-bottom: 0;
          img{
            position: absolute;
            top: -10px;
            left: 0;
            max-height: 100px;
          }
        }
      }
    }
  }
}

//Page Titles

.webform-component--request-section--section-request-body,
.webform-component--upgrades-section--section-upgrades-body,
.webform-component--accessories-section--section-accessories-body{
  @include page-title;
}

/*
Overview sections
*/

  .webform-component--request-section--section-request-overview,
  .webform-component--upgrades-section--section-upgrades-overview,
  .webform-component--upgrades-section--section-accessories-overview,
  .webform-component--accessories-section--section-accessories-overview,
  .webform-component--customer-section{

    .webform-component--product-section,
    #edit-submitted-request-section-section-request-overview-products-summary-request,
    #edit-submitted-upgrades-section-section-upgrades-overview-product-summary-upgrades,
    #edit-submitted-accessories-section-section-accessories-overview-product-summary-accessories,
    #edit-submitted-customer-section-section-details-overview-product-summary-customer{
      background: $white;
      label{
        @include sidebar-label;
      }
      .fieldset-wrapper{
        display: flex!important;
        .webform-component--product-section--section-product-body{
          display: inline-block;
          flex: 0 1 25%!important;
          text-align: center;
          .webform-component--product-section--section-product-body--select-product{
            legend{
              display: none;
            }

          }
          .webform-component--product-section--section-product-body--select-product--select-system-and-parts{
            label{
              display: none;
            }
          }

        }
        .webform-component--product-section--section-product-overview{

          display: inline-block;
          flex: 0 1 75%!important;
          .fieldset-wrapper{
            display: block!important;
            -webkit-box-shadow: none!important;
            -moz-box-shadow: none !important;
            box-shadow: none!important;
            .fieldset-description{
                display: none;
            }
            .form-item{
              padding: 0 .5em!important;
              border-bottom:  none!important;


              label{
                background: none!important;
                border-bottom:  none!important;
              }
            }
          }

        }
      }
    }

    //Request section page items
    .webform-component--request-section,
    #edit-submitted-accessories-section-section-accessories-overview-request-types-accessories,
    #edit-submitted-upgrades-section-section-upgrades-overview-request-types,
    #edit-submitted-customer-section-section-details-overview-request-types-customer{
      background: $white;
      label{
        @include sidebar-label;
      }
      .webform-component--request-section--section-request-body{
        display: block!important;
        padding: 0!important;
        legend{
          display: none;
        }
        .form-item{
          margin: 0;
          border-bottom: 1px solid $gray-light;
          padding: .5em;
        }
      }
      .webform-component--request-section--section-request-body--enter-reason-for-repair{
        label{
          display: none;
        }
      }



    }
    //Upgrades section page items

    .webform-component--upgrades-section,
    #edit-submitted-accessories-section-section-accessories-overview-upgrades-summary-accessories,
    #edit-submitted-customer-section-section-details-overview-upgrades-summary-customer{
      background: $white;
      label{
        @include sidebar-label;
      }
      .webform-component--upgrades-section--section-upgrades-body--options,
      .webform-component--upgrades-section--section-upgrades-body--upgrades,
      .webform-component--upgrades-section--section-upgrades-body{
        display: block!important;
        padding: 0!important;
        legend{
          display: none;
        }
        .form-item{
          margin: 0;
          border-bottom: 1px solid $gray-light;
          padding: .5em;
        }
      }


    }

    #edit-submitted-customer-section-section-details-overview-accessories-summary-customer{
      background: $white;
      label{
        @include sidebar-label;
      }
      .webform-component--accessories-section--section-accessories-body{
        display: block!important;
        padding: 0!important;
        legend{
          display: none;
        }
        .form-item{
          margin: 0;
          border-bottom: 1px solid $gray-light;
          padding: .5em;
          label{
            background: none;
            display: inline-block;
            border-bottom: none;
            font-weight: normal;
          }
        }
      }
    }

}




  //CUSTOMER INFO FORM
.webform-component--customer-section--section-details-body{
  .webform-component--customer-section--section-details-body--contact-info,
  .webform-component--customer-section--section-details-body--customer-details,
  .webform-component--customer-section--section-details-body--return-address-section,
  .webform-component--customer-section--section-details-body--quotation-section,
  .webform-component--customer-section--section-details-body--billing-info-section,
  .webform-component--customer-section--section-details-body--additional-comments-section

  {
    @include page-title;
  }
  input[type="email"]{
    background: #eaeaea;
    border-style: none;
    border: 1px solid #d1d1d1;
    height: 36px;
  }
  .webform-component--customer-section--section-details-body--return-address-section--shipping-date{
    .webform-datepicker{
      display: flex;
      .form-item{
        display: inline-block;
        margin-right: 1em;
        select{
          padding: 0.5em 1em .5em .5em;

        }
        &:after{
          right: 0.2em;
          top: 17%;
        }
      }
      input.webform-calendar{
        margin-top: .5em;
        height: 30px;
      }
    }
  }
  .webform-component--customer-section--section-details-body--billing-info-section--billing-type{
    #edit-submitted-customer-section-section-details-body-billing-info-section-billing-type{
      display: flex;
      .form-item{
        display: inline-block;
        width: 25%;

      }
    }
  }

  .webform-component--customer-section--section-details-body--additional-comments-section--upload-file{
    .form-managed-file{
      display: flex;
    }
    input[type="submit"]{
      width: auto;
    }

  }

}


//Review page
.webform-client-form-522.webform-conditional-processed{
  .section-product-info,
  .section-request-info,
  .section-billing-info{
    display: block;
  }



  .webform-component--request-review-section{
    display: block;
        label{
          display: none;
        }
    fieldset{
      line-height: 1;
    }
      .fieldset-wrapper{
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      #edit-submitted-request-review-section-product-summary-review,
      #edit-submitted-request-review-section-request-types-review,
      #edit-submitted-request-review-section-upgrades-summary-review,
      #edit-submitted-request-review-section-accessories-summary-review {
        display: inline-block;
        width: 48%;
        background: $white;
        label {
          display: block;
          @include sidebar-label;
        }
      }
      .webform-component--product-section{
        .fieldset-wrapper{
          display: flex;
          justify-content: space-between;
          .webform-component--product-section--section-product-body{
            display: inline-block;
            flex: 0 1 25%;
            .webform-component--product-section--section-product-body--select-product--select-system-and-parts{
              label{
                display: none!important;
              }
            }


          }
        .webform-component--product-section--section-product-overview{
          display: inline-block;
          flex: 0 1 75%;
          .fieldset-description{
            display: none;
          }
          .form-item{
            margin: 0;
          }
          .webform-component-display{   // label for each model name
            label{
              display: block;
              @include remove-sidebar-label;
            }
          }
        }


        }
      }
      .webform-component--product-section--section-product-overview{         // product serial numbers
        .fieldset-wrapper{
          display: block;
          justify-content: space-between;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;


        }
      }
      #edit-submitted-request-review-section-request-types-review{
        .webform-component--request-section--section-request-body{
          flex: 0 1 100%;
          legend{
            display: none;
          }
        .webform-component--request-section--section-request-body--enter-reason-for-repair{
          label{
            display: none;
          }
        }
        }
      }
      #edit-submitted-request-review-section-upgrades-summary-review{}
      #edit-submitted-request-review-section-accessories-summary-review{}




  }

  .section-request-info,
  .section-billing-info{
      fieldset {
        label {
          display: inline-block;
          width: 50%;
        }
        span {
          display: inline-block;
          width: 50%;
        }
      }
      .fieldset-wrapper{
        display: flex;
        justify-content: space-between;

        .form-item{
          display: inline-block;
          width: 30%;
          background: $white;
          label{
            @include sidebar-label;
          }
          .section-body{
            flex: none;
            display: block;
            legend{
              display: none;
            }
            .fieldset-wrapper .fieldset-wrapper{
              .form-item{
                margin: 0;
                display: block;
                width: 100%;
                label{
                  @include remove-sidebar-label;
                  display: block;
                  width: 100%;
                }
              }

            }
          }

        }
      }
    }
  #edit-submitted-request-info-customer-details,
    #edit-submitted-request-info-contact-info-summary,
  #edit-submitted-request-info-return-address-summary{
  }

  .section-billing-info{
    #edit-submitted-billing-info-billing-summary{}
    #edit-submitted-billing-info-billing-address-summary{}
  }

  #edit-submitted-request-info-contact-info-summary{
    width: 33%;
  }
  #edit-submitted-request-info-return-address-summary{

  }
}
