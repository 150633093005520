%menu-style {
  width: 25%;
  background: $gray-lightest;
  padding: 1em;
}

.field-name-ds-blog-author-name {
  .field-item {
    display: flex;
    line-height: 30px;
    margin-bottom: 1em;
  }
  .user-picture {
    height: 30px;
    width: 30px;
    display: inline-block;
    margin-right: 0.5em;
    img {
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
    }
  }
}

.field-name-field-blog-category {
  text-transform: uppercase;
  color: $gray-medium;
  font-size: 1.3rem;
  .field-item {
    font-family: $font-proxima-nova-bold;
    display: inline-block;
    margin-right: 1em;
    padding-right: 1em;
    border-right: 1px solid $gray-light;

    &:last-child {
      border-right: 0px solid transparent;
    }
  }
}
.page-node-19698 {
  .breadcrumbs {
    display: none;
  }
  .paragraphs-item-content-in-columns {
    .field-name-field-page-sections {
      > .field-items {
        > .field-item {
          &:first-child {
            flex: 0 1 75%;
            width: 75%;
          }
          &:last-child {
            flex: 0 1 25%;

            width: 25%;
          }
        }
      }
    }
  }
}

.view-fbc-blog-posts {
  @include outer-container();
  &.blog-post-four {
    > .view-content {
      .views-row {
        margin: 0;
      }
    }
    .views-row {
      margin: 0;
      .card-content {
        padding: 0;
        .card-image {
          display: none;
        }
        .card-text {
          .field-name-ds-blog-author-name,
          .field-name-field-preview-summary,
          .field-name-node-link {
            display: none;
          }
        }
      }
      &.views-row-first {
        margin-bottom: 10px solid black;
        .card-image {
          display: block;
        }
        .card-text {
          .field-name-ds-blog-author-name,
          .field-name-field-preview-summary,
          .field-name-node-link {
            display: block;
          }
        }
      }
    }
  }
  .attachment-before {
    float: left;
    padding-bottom: 100%;
    @extend %menu-style;
  }
  > .view-content {
    padding: 0;
    @include media($screen-sm) {
      padding: 1em 2em;
    }
    .views-row {
      margin-bottom: 1em;
    }
    @include media($screen-md) {
      padding: 0;
    }
    .card-wide-layout {
      margin: 0;
      padding: 0;
    }
    .card-tall-layout {
      margin: 0;
      padding: 0;
    }
  }
}

.node-type-blog-post {
  .body-wrapper {
    display: flex;
    width: 100%;
    position: relative;
    margin-bottom: 2em;
    flex-wrap: wrap;

    @include media($screen-md) {
      flex-wrap: nowrap;
    }

    .blog-body,
    .related-info {
      display: inline-block;
    }
    .blog-body {
      flex: 0 1 100%;

      @include media($screen-md) {
        flex: 0 1 70%;
        padding-right: 2em;
      }
    }

    .related-info {
      flex: 0 1 100%;
      @include media($screen-sm) {
        flex: 0 1 50%;
      }

      @include media($screen-md) {
        flex: 0 1 30%;
      }
    }
  }
  .field-name-ds-blog-author-profile {
    padding: 1em;
    .pic-n-name {
      display: flex;
    }
    .blogger-picture {
      margin-right: 1em;
      img {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
      }
    }
    .blogger-name {
      margin: auto 0;
    }
    .blogger-description {
      margin-top: 1em;
      p {
        font-size: 1.4rem;
      }
    }
  }
  .learn-more-toggle {
    &:hover {
      cursor: pointer;
    }
  }

  .view-mode-full {
    @include outer-container();
    .left {
      width: 25%;
      .view-display-id-block_3 {
        .view-content {
          @extend %menu-style;
        }
      }
    }
    .right {
      width: 75%;
    }
  }

  .body-wrapper {
    background: $white;
    position: relative;
    z-index: 2;
    display: flex;
    width: auto;
    flex-wrap: wrap;
    padding: 5vw;
    margin: -7em 1em 0 1em;

    @include media($screen-sm) {
      margin: -7em 2em 0 2em;
    }

    @include media($screen-lg) {
      @include outer-container();
      width: 100%;
      padding: 2em 0 2em 2em;
      margin: -7em auto 0 auto;
    }
    .intro-text {
      flex: 0 1 100%;
      width: 100%;
    }
  }

  .secret-squirrel {
    padding: 1em;
    background: lighten($color-primary, 50%);
    -webkit-box-shadow: 1px 1px 10px $gray-darker;
    -moz-box-shadow: 1px 1px 10px $gray-darker;
    box-shadow: 1px 1px 10px $gray-darker;
    position: fixed;
    top: 20%;
    left: -400px;
    width: 400px;
    display: block;
    transition: left 0.5s ease-out;
    height: 500px;

    &:hover {
      left: 0;
      transition: left 0.5s ease-out;
    }

    &:after {
      font-family: FontAwesome;
      content: "\f292";
      font-size: 2em;
      margin: auto;
      color: $white;
      background: $color-primary;
      height: 40px;
      width: 40px;
      position: absolute;
      top: 0;
      right: -40px;
      border-radius: 0 5px 5px 0;
    }
  }

  .view-mode-blog_big_hero {
    .hero-wrapper {
      width: 100%;
      overflow: hidden;
      height: 300px;
      @include media($screen-sm) {
        height: 400px;
      }

      @include media($screen-md) {
        height: 500px;
      }
      @include media($screen-lg) {
        height: 600px;
      }
      picture {
        height: 100%;
        width: 100%;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .view-mode-blog_no_hero {
    .header-wrapper {
      background-image: url("../images/backgrounds/hexagons-category-2000x604.png");
      padding-top: 100px;
      background-repeat: no-repeat;

      img {
        object-fit: cover;
      }

      .header {
        @include outer-container();
        background: $white;
        display: flex;
        flex-wrap: wrap;
        @include media($screen-md) {
          flex-wrap: nowrap;
        }
      }
      .intro-text,
      .hero-wrapper {
        display: inline-block;

        height: auto;
        padding: 5vw;
        flex: 0 1 100%;
        width: 100%;
        @include media($screen-md) {
          padding: 2em;
          flex: 0 1 50%;
          width: 50%;
        }
      }
    }
    .body-wrapper {
      margin-top: 0;
    }
  }

  .field-name-field-related-products {
    & > .field-items {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      & > .field-item {
        flex: 0 1 100%;
        width: 100%;

        @include media($screen-md) {
          height: 500px;
        }
        @include media($screen-lg) {
          flex: 0 1 47%;
          width: 47%;
        }
      }
    }
  }
}
.field-name-ds-blog-form {
  margin-top: 1em;
  display: block;
  background: $gray-lightest;
  overflow: hidden;
  .views-row {
    margin-bottom: 0;
  }
  p {
    padding: 1em;
  }
  a.learn-more-toggle {
    background: $color-pop;
    display: block;
    text-align: center;
    color: $base-font-color;
    padding: 0.5em;
    font-weight: bold;
    &:hover {
      text-decoration: none;
      background: darken($color-pop, 10%);
    }
  }
  .inactive {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s;
  }
  .active {
    max-height: 1000px;
    height: auto;
    transition: max-height 0.5s;
  }
  .hidden-form {
    display: block;
    @include clearfix;

    .field-name-title {
      h3 {
        background: $base-font-color;
        color: $white;
        text-align: center;
        padding: 0.5em;
      }
    }
  }
}
