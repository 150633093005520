.paragraphs-item-application-banner {
  * {
    transition: all 1s;
  }

  h1 {
    margin-bottom: 0;
  }

  &.text-align-right {
    .app-banner-text-content {
      margin: 0 0 0 auto;
      text-align: right;
    }
  }

  &.text-align-center {
    .app-banner-text-content {
      margin: 0 auto;
      text-align: center;

    }
  }

  &.text-align-left {
    .app-banner-text-content {
      margin: 0 auto 0 0;
      text-align: left;
    }
  }

  .app-banner-text-wrapper {
    max-width: 100%;
    margin: auto;
    height: 100%;

    @include media($screen-lg) {
      max-width: $screen-lg;
    }

    .app-banner-text-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding: 1em;
      
      @include media($screen-xs) {
        width: 100%;
      }
      @include media($screen-sm) {
        width: 80%;
      }
      @include media($screen-md) {
        width: 60%;
      }
      @include media($screen-lg) {
        padding: 0;
        max-width: 50%;
      }
    }
  }
}
