.paragraphs-item-two-column {
  &>.content {
    @include media($screen-md){
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }


  .paragraphs-items-field-column-one{
    width: 100%;

    @include media($screen-md) {
      vertical-align: top;
      display: inline-block;
      flex: 0 65%;
      width: 65%;
      // padding-right: 2em;
    }

    .paragraphs-item-highlighted-section {
      &.card-wide-layout {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        -webkit-box-shadow: 0 -10px 5px -10px $gray-dark;
        -moz-box-shadow: 0 -10px 5px -10px $gray-dark;
        box-shadow: 0 -10px 5px -10px $gray-dark;
        .card-content {
          padding: 0;
        }
      }
    }
  }
  .paragraphs-items-field-column-two {
    width: 100%;

    @include media($screen-md) {
      vertical-align: top;
      display: inline-block;
      flex: 0 1 32%;
      width: 32%;
    }
  }
}