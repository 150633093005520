.view-fbc-faqs{
  &.view-display-id-block{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .view-header,
      .view-filters,
      .view-content{
        display: inline-block;
        width: 100%;

      }
      .view-header{
        order: 2;
        margin-bottom: 1em;

      }
      .view-filters{
        order: 1;
        margin-bottom: 2em;
      }
    .view-content{
      order: 3;
    }

    .view-header{
      //width: 300px;
      //float: right;
      //text-align: center;
      order: 2;
    }
    .view-filters{
      order: 1;
    }
    .field-content{
      display: flex;
      margin-bottom: 2em;
      padding-bottom: 2em;
      border-bottom: 1px solid $gray-lightest;
      flex-wrap: wrap;
      @include media($screen-sm){
        flex-wrap: nowrap;
      }

    }
    .question, .answer{
      display: inline-block;
    }
    .question{
      flex: 0 1 100%;
      width: 100%;
      font-family: $font-proxima-nova-semibold;
      @include media($screen-sm){
      flex: 0 1 25%;
      width: 25%;
      }


    }
    .answer {
      flex: 0 1 100%;
      width: 100%;
      @include media($screen-sm) {
        padding-left: 1em;
        flex: 0 1 75%;
        width: 75%;
      }
    }
  }
}