.add-slide{
  margin-top: 300px;
  transition: background .5s, margin-top .5s;

}

.slide{
  margin-top: 0px;
  transition: background .5s, margin-top .5s;
}


.card-tall-layout,
 .card-wide-layout{
//   padding: 2em 1em;
//    @include media($desk-wide){
//    padding: 2em 0;
//  }
   h4{
     padding-bottom: .5em;
   }
  .teaser-content,
  .card-content{
    display: flex;
    flex-wrap: nowrap;
    max-width: 100%;
    flex-direction: column;

    @include media($tablet){
      flex-direction: row;
      @include outer-container();
      padding: 2em 0;
    }
    @include media($screen-lg){
     flex-direction: column;
    }

    .teaser-image,
    .teaser-text{
      display: inline-block;
      flex: 0 1 100%;
    }

    .teaser-image,
    .card-image{
      position: relative;
      flex: 0 1 40%;
      margin-bottom: 1em;
      order: 1;

      @include media($screen-md){
        flex: 0 1 50%;
        margin-bottom: 0;
      }
      @include media($screen-lg){
        flex: 0 1 100%;
        margin-right: 0;

      }

      img{
        height: auto;
        width: auto;
      }

    }

    .teaser-text,
    .card-text{
      order: 2;
      flex: 0 1 60%;
      //padding: 0 1em;

      @include media($tablet-large){
        flex: 0 1 50%;
      }
      @include media($desk){
        flex: 0 1 100%;

      }

      .field-label{
        @extend h4;
        margin-top: 1em;
      }
    }
    .field-name-field-cta-link{
      a{
      display: inline-block;
      margin: 1em 0;
        &.button{
          display: block;
          text-align: center;
          width: 50%;
          font-weight: bold;
          @extend button;
          margin: 0 auto;
        }
    }
    }
  }
.field-name-field-h1-title{
  .field-item{
    @extend h3;
  }
}

 }

.front{
  .card-wide-layout {
    -webkit-box-shadow: none!important;
    -moz-box-shadow: none!important;
    box-shadow: none!important;
  }

  }

.paragraphs-item-link-with-teaser-image{
  .field-items{
    display: flex;
    justify-content: space-around;
    width: 100%;
    @include media($screen-md){

    }
    .field-item{
      display: inline-block;
      max-width: 30%;
    }
  }
}

.card-wide-layout{
  //border-top: 1px solid $gray-lightest;
  //@include extended-background;
  //background: transparent;
  @include extended-background($white);
  -webkit-box-shadow: 0 -10px 25px -16px #555;
  -moz-box-shadow: 0 -10px 25px -16px #555;
  box-shadow: 0 -10px 25px -16px #555;



  .card-content{
    flex-direction: column;
    @include outer-container();

    @include media($tablet-large){
      flex-direction: row;
    }
    @include media($desk-wide){
    }
    .card-image{
      position: relative;
      margin-left: 0;
      order: 1;
      @include media($screen-md){
        flex: 0 1 40%;
        max-width: 40%;
        margin-right: 1em;
      }
      img{
        display: block;
        margin: auto;
        max-height: 400px;
      }

      .slick{
        img{
          width: 100%;
          height: auto;
        }
      }
    }
    .card-text{
      @include clearfix;

      @include media($tablet-large){
        flex: 0 1 60%;
        order: 1;
      }

      h2>p{
        @extend h2;
      }
      a>h2>p{
        color: $action-color;
      }

      h3{
        margin-top: 0;
      }
    }
  }
}

// Don't have a top line for content pages
.field-name-field-content-section {
  >.field-items {
    >.field-item:first-of-type{
      .card-wide-layout {
        -webkit-box-shadow: 0 -10px 25px -16px transparent;
        -moz-box-shadow: 0 -10px 25px -16px transparent;
        box-shadow: 0 -10px 25px -16px transparent;
      }
    }
  }
}
// Don't have a top line for views pages with card wide
.view:not(&.view-id_fbc_products) {
  .view-content {
    .views-row:first-of-type {
      .card-wide-layout {
        -webkit-box-shadow: 0 -10px 25px -16px transparent;
        -moz-box-shadow: 0 -10px 25px -16px transparent;
        box-shadow: 0 -10px 25px -16px transparent;
      }
    }
  }
}


.card-sensors.card-wide-layout{
  .card-content{
    display: block;
  }
  .sensor-highlights,
  .sensor-details{
    display: flex;
    width: 100%;

    .card-images, .card-features{
      display: inline-block;
    }
    .card-images,
    .card-text{
      flex: 0 1 75%;

    }
    .card-features,
    .card-video{
      flex: 0 1 25%;
    }


    .field-name-field-slideshow-images{
      .field-item{
        display: inline-block;
      }
    }
    .card-text{
      order: 1;
    }
    .card-video{
      order: 2;
    }


  }
  .sensor-highlights{
    margin-bottom: 2em;
  }


}
