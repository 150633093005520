.paragraphs-item-application-quote {
    .app-quote {
        .app-subsection-wrapper {
            display: flex;
            width: 100%;
            @include media($tablet) {
                @include outer-container();
            }
        }
        
        .app-quote-image-wrapper,
        .app-quote-text-wrapper {
            display: inline-block;
        }

        .app-quote-image-wrapper{
            min-width: 100px;
            width: 100px;
            height: 100px;
            overflow: hidden;
            border-radius: 50%;
            object-fit: contain;

            @include media($screen-lg){
                min-width: 150px;
            width: 150px;
            height: 150px;
            }

            img{
                max-width: 350px;
                min-height: 200px;
                object-fit: cover;
            }
        }
        .app-quote-text-wrapper {
            width: calc(80% - 1em);
            margin-left: 2em;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            .app-quote-attribution {
                position: relative;
                padding-left: 1.5em;

                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: "—";
                }
            }

            .field-name-field-app-quote-body {
                font-size: 2.5rem;
                margin-bottom: 1em;
                font-style: italic;
            }
            .field-name-field-app-quote-name {
                font-weight: 700;
            }
        }
    }
}
