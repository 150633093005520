.node-type-tabula-rasa {

  .main-container{
    padding: 0;
    margin: 0;
    min-height: 0;
    header,
    footer {
      display: none !important;
    }
  }
}
