.node-type-channel-alert {
  .resource-page {
    .header-wrapper {
      height: 150px;
    }
  }

  .resource-sidebar {
    & > .field {
      margin-bottom: 1em;
      padding: 1em;
      background: $gray-lightest;
    }
  }
}
