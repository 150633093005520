
div.horizontal-tabs {
  margin: 0 0 1em 0; /* LTR */
  padding: 0;
  border: none;
  position: relative; /* IE6/7 */

}

.horizontal-tabs ul.horizontal-tabs-list {
  display: block;
  margin: 0;
  border: 0;
  padding: 0px;
  position: relative; /* IE6 */
  list-style: none;
  list-style-image: none; /* IE6 */
  border-right: none; /* LTR */
  width: auto;
  height: auto;
  clear: both;
  @include extended-background(transparent);
  box-shadow: 0px 1px 3px $gray-lightest;

  @include media($screen-lg){

  }

}

.horizontal-tabs fieldset.horizontal-tabs-pane {
  padding: 1em 0;
  border: 0;
}

fieldset.horizontal-tabs-pane > legend,
fieldset.vertical-tabs-pane fieldset.horizontal-tabs-pane > legend {
  display: none;
}

/* Layout of each tab */

.horizontal-tabs ul.horizontal-tabs-list{
  background: $white;
}

.horizontal-tabs ul.horizontal-tabs-list li {
  background: none;
  border-right: none; /* LTR */
  padding: 1px;
  padding-top: 0;
  margin: 0;
  min-width: auto; /* IE7 */
  float: none; /* LTR */
  transition: border-bottom .5s;
  border-bottom: 3px solid transparent;
  margin-right: 1em;

  &:hover,
  &:focus,
  &.selected{
    border-bottom: 3px solid $color-primary;
    transition: border-bottom .5s;
  }
}
.horizontal-tabs ul.horizontal-tabs-list li.selected {
  background-color: #fff;
  padding: 0 0 1px 0;
}

.horizontal-tabs ul.horizontal-tabs-list li a {
  display: block;
  text-decoration: none;
  padding: 0.5em 0.6em;
}
.horizontal-tabs ul.horizontal-tabs-list li a:hover {
  outline: none;
  background-color: transparent;


}
.horizontal-tabs ul.horizontal-tabs-list li:hover,
.horizontal-tabs ul.horizontal-tabs-list li:focus {
  background-color: transparent;

}
.horizontal-tabs ul.horizontal-tabs-list :focus {
  outline: none;
}
.horizontal-tabs ul.horizontal-tabs-list li a:focus strong,
.horizontal-tabs ul.horizontal-tabs-list li a:active strong,
.horizontal-tabs ul.horizontal-tabs-list li a:hover strong {
  text-decoration: none;
  outline: none;
}
.horizontal-tabs ul.horizontal-tabs-list li a,
.horizontal-tabs ul.horizontal-tabs-list li.selected a {
  display: block;
  text-decoration: none;
  padding: 0.5em 0.6em 0.3em 0.6em;
  position:relative;
  top: 0px;
}
.horizontal-tabs ul.horizontal-tabs-list .selected strong {
  color: $base-font-color;
}
.horizontal-tabs ul.horizontal-tabs-list .summary {
  display: block;
}
.horizontal-tabs ul.horizontal-tabs ul.horizontal-tabs-list .summary {
  line-height: normal;
  margin-bottom: 0;
}

/**
 * tab content
 */
div.field-group-htabs-wrapper .field-group-format-wrapper {
  clear: both;
  padding: 0 0 0.6em;
}
/*hide*/
.horizontal-tabs .horizontal-tab-hidden {
  display: block;
  position: absolute;
  top: -100000px;
  width: 100%;
}
