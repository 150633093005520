.category-page {
  position: relative;
  &.light {
    .breadcrumbs,
    .header-wrapper {
      h1,
      h2,
      h3,
      h4,
      h5,
      p {
        color: $white;
      }
      .header-content {
        background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 40%,
          rgba(0, 0, 0, 0.85) 70%,
          #000
        );
        @include media($screen-sm) {
          background-image: none;
        }
      }
    }
    .field-name-field-background-texture-image {
      &:before {
        @include media($screen-sm) {
          background-image: linear-gradient(
            to right,
            rgba(0, 0, 0, 0.75),
            rgba(0, 0, 0, 0)
          );
        }
      }
    }
  }
}
.header-wrapper {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -3;
    min-width: 100%;
    height: 480px;
    background-repeat: no-repeat;
    background-size: auto;
    background-image: url(../images/backgrounds/hexagons-category-768x604.png);
  }
  @include media($screen-xs) {
    &:after {
      background-image: url(../images/backgrounds/hexagons-category-960x604.png);
    }
  }
  @include media($screen-sm) {
    min-height: 400px;
    height: 400px;
    &:after {
      min-height: 400px;
      height: 400px;
      background-image: url(../images/backgrounds/hexagons-category-1260x604.png);
    }
  }

  @include media($screen-lg) {
    &:after {
      background-image: url(../images/backgrounds/hexagons-category-2000x604.png);
    }
  }
  @include media($screen-xl) {
    &:after {
      background-image: url(../images/backgrounds/hexagons-category-3000x604.png);
    }
  }

  .header-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: -2;
    height: 480px;
    overflow: hidden;
    min-height: 480px;
    width: auto;
    object-fit: cover;

    @include media($screen-sm) {
      min-height: 400px;
      height: 400px;
    }

    .field-name-field-background-texture-image,
    .field-name-field-header-banner {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        height: 480px;
        overflow: hidden;

        @include media($screen-sm) {
          min-height: 400px;
          height: 400px;
        }
      }
      img {
        min-height: 480px;
        width: auto;
        background-size: cover;
        max-width: none;
        @include media($screen-sm) {
          min-height: 400px;
          min-width: 100%;
        }
      }
    }
  }

  .header-content {
    @include outer-container($screen-xl);
    width: 100%;
    display: flex;
    z-index: 4;
    height: 100%;
    position: relative;
    min-height: 480px;
    display: block;
    height: 480px;
    overflow: hidden;
    z-index: 3;
    display: flex;
    flex-direction: column;
    padding: 1em;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.95) 80%,
      #fff
    );

    @include media($screen-sm) {
      flex-wrap: wrap;
      @include outer-container();
      min-height: 400px;
      height: 400px;
      flex-direction: row;
      background-image: none;
    }
    @include media($screen-lg) {
      padding: 1em 0;
    }
    .breadcrumbs,
    .header-image,
    .header-text {
      width: 100%;
    }
    .header-image {
      @include media($screen-sm) {
        width: 40%;
        flex: 0 1 40%;
      }
    }
    .header-text {
      @include media($screen-sm) {
        width: 60%;
        flex: 0 1 60%;
      }
    }
  }
}

.body-wrapper {
  position: relative;
  z-index: 5;
}
.body-content {
  @include outer-container();
  min-height: 80vh;
}
.field-name-field-body {
  padding: 1em;
  @include media($screen-lg) {
    padding: 0;
  }
}

.field-name-ds-products-compare-mobi,
.view-display-id-block_11 {
  font-size: 1.3rem;
  padding: 1em;
  display: block;
  h3 {
    position: relative;
  }
  .new {
    &:after {
      position: absolute;
      content: "";
      top: 2em;
      left: 0px;
      height: 100px;
      width: 100px;
      background: url("../images/icon-new-no-tab.png") no-repeat;
      -webkit-background-size: 100%;
      background-size: 100%;
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
  }
  @include media($screen-sm) {
    display: none;
  }

  h3 {
    margin-top: 2em;
    &:first-child {
      margin-top: 0;
    }
  }
  .table-label {
    width: 65%;
    display: inline-block;
    vertical-align: top;
  }
  .table-cell {
    width: 35%;
    display: inline-block;
    font-size: 1.3rem;
    padding-left: 5px;
    text-align: center;
    p {
      font-size: 1.3rem;
      vertical-align: top;
      line-height: 1.3rem;
    }
  }
  .views-row {
    padding: 6px;
    border-bottom: 1px solid $gray-lightest;
  }
  .value-no,
  .value-yes {
    margin-left: 0;
    width: 20px;
    margin-right: 5px;
    display: inline-block;
    height: 20px;
    &:after {
      font-size: 20px;
      line-height: 1.3rem;
    }
  }
}

.field-name-ds-products-compare {
  display: none;
  margin-top: 2em;

  @include media($screen-sm) {
    display: block;
  }
  h2 {
    padding: 0 1rem;
    @include media($screen-lg) {
      padding: 0;
    }
  }
}

#site-map {
  @include outer-container();
  padding: 1em;
  @include media($screen-lg) {
    margin-top: 2em;
    padding: 0;
  }
}

.view-id-fbc_landing_pages {
  padding: 1em;
  @include media($screen-lg) {
    padding: 2em 0;
  }
  .view-content {
    @include media($screen-md) {
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      columns: 2;
      column-gap: 6em;
    }
  }
  .views-row {
    margin-bottom: 2em;
    break-inside: avoid;
    border: 1px solid transparent;
    //padding: 1em;
    //margin-top: 1em;
    //border-top: 1px solid $gray-lightest;
    .field-name-field-preview-summary {
      font-weight: normal;
    }
  }
}
