.sidebar-downloads.open ~ .sidebar-quick-contact,
.sidebar-quick-contact.open ~ .sidebar-downloads {
  transition: right 0.5s ease-in-out;
}

.sidebar-quick-contact,
.sidebar-downloads {
  position: fixed;
  top: 120px;
  padding-left: 1em;
  right: -1.7rem;
  max-width: 100%;
  transition: left 0.5s ease-in-out;
  z-index: 5;
  height: 200px;

  @include media($screen-md) {
    top: calc(50% - 60px);
  }

  .fbc-toggle {
    background: $color-pop;
    border-radius: 0.25em 0 0 0.25em;
    position: absolute;
    top: 0;
    height: 54px;
    width: 68px;
    font-size: 1.1rem;
    text-align: center;
    padding: 0.5em;
    -webkit-box-shadow: -3px 0 3px $base-font-color;
    -moz-box-shadow: -3px 0 3px $base-font-color;
    box-shadow: -3px 0 3px $base-font-color;
    color: $base-font-color;
    z-index: 2;
    &:hover,
    &:active,
    &:focus {
      cursor: pointer;
    }

    &:after {
      content: "\f105";
      font-family: FontAwesome;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      font-size: 4em;
      font-weight: bold;
      transition: opacity 2s;
      width: 100%;
      text-align: center;
      color: $base-font-color;
    }
    svg {
      height: 30px;
      fill: $base-font-color;
      opacity: 1;
      transition: opacity 1s;
    }

    &.closed {
      left: -65px;
      transition: left 0.5s ease-in-out;

      & + .toggle-wrap {
        left: 4px;
        transition: left 0.5s ease-in-out;
      }
    }
    &.open {
      left: -32rem;
      z-index: 2;
      transition: left 0.5s ease-in-out;
      @include media($screen-md) {
        left: -73.8rem;
      }

      &:after {
        opacity: 1;
        transition: opacity 1s;
      }

      img,
      svg,
      .toggle-text {
        opacity: 0;
        transition: opacity 1s;
      }
      & + .toggle-wrap {
        left: -25.2rem;
        transition: left 0.5s ease-in-out;
        @include media($screen-md) {
          left: -67rem;
        }
      }
    }

    .toggle-text {
      opacity: 1;
      transition: opacity 1s;
    }
  }
  .toggle-wrap {
    position: absolute;
    top: 0;
    height: 200px;
    width: 26rem;
    font-weight: normal;
    -webkit-box-shadow: -2px 0 3px $base-font-color;
    -moz-box-shadow: -2px 0 3px $base-font-color;
    box-shadow: -2px 0 3px $base-font-color;
    background: $gray-lightest;
    @include media($screen-md) {
      height: 200px;
      width: 67rem;
    }
    h3 {
      font-weight: 700;
    }
    .toggle-wrap {
      left: 0;
    }
  }
}

//Resources specific

.sidebar-downloads {
  z-index: 7;
  height: 400px;
  top: 130px;
  @include media($screen-md) {
    top: calc(50% + 3px);
  }
  .toggle-wrap {
    height: 400px;
  }
  .view-fbc-resources {
    background: $gray-lightest;
  }

  .view-filters {
    padding: 0.5em;
  }
  .bef-checkboxes {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-item {
      position: relative;
      display: inline-block;
      width: 48%;
      border: 1px solid $blue;
      background: $teal-light;
      padding-left: 0.25em;
      margin-bottom: 0.25em;
      label {
        font-size: 1.3rem;
        font-weight: normal;
        display: block;
        vertical-align: middle;
        height: auto;
        margin-top: -1.3em;
        padding-left: 1.3em;
      }
    }
  }

  .form-type-select {
    position: relative;
    &:after {
      content: "";
    }
  }
  .views-exposed-widgets {
    display: flex;
    .views-widget,
    .views-submit-button {
      display: inline-block;
    }
    .views-submit-button {
      margin-top: 0.5em;
      padding: 0;
    }
  }

  .view-content {
    height: 25vh;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    .views-row {
      display: flex;
      width: 100%;
      height: 40px;
      .field-content {
        font-size: 1.4rem;
        line-height: 1rem;
        a {
          height: 100%;
          display: flex;
          justify-content: space-around;
          .image {
          }
          .title {
            height: 34px;
            margin: auto;
            line-height: 16px;
          }
        }
      }
      img {
        height: 34px;
        margin-right: 1em;
      }
    }
  }
}

.sidebar-quick-contact {
  .toggle-wrap {
    height: 400px;
    @include media($screen-md) {
      flex-direction: row;
      height: 200px;
    }
    .top-section {
      //background: $base-font-color;
      color: $base-font-color;
      height: 55px;
      padding: 1em;
      margin: 0 1em;
      display: flex;
      justify-content: flex-start;
      border-bottom: 1px solid $gray-light;
      img,
      svg {
        display: inline;
        height: 20px;
        fill: $color-pop;
        width: 40px;
        path {
          fill: $base-font-color;
        }
      }
      h3 {
        display: inline;
        margin-left: 0.5em;
        font-weight: 700;
        //color: $color-pop;
      }
    }
    .bottom-section {
      display: flex;
      padding: 1em;
      font-size: 1.2rem;
      text-align: center;
      justify-content: space-between;
      flex-direction: column;
      @include media($screen-md) {
        flex-direction: row;
      }

      .section {
        display: inline;
        padding: 1em;
        &:nth-child(1) {
          border-bottom: 1px solid $gray-light;
          @include media($screen-md) {
            flex: 0 1 36%;
            border-bottom: none;

            border-right: 1px solid $gray-light;
          }
        }
        &:nth-child(2) {
          flex: 0 1 24%;
          border-bottom: 1px solid $gray-light;
          @include media($screen-md) {
            flex: 0 1 36%;
            border-bottom: none;

            border-right: 1px solid $gray-light;
          }
        }
        &:nth-child(3) {
          flex: 0 1 36%;
        }
        img,
        svg {
          width: 50px;
          height: 30px;
          fill: $base-font-color;
        }
      }

      h3 {
        padding: 0;
        margin: 0;
        font-size: 1.5rem;
        font-weight: 700;
        color: $base-font-color;
      }
    }
  }
}
