// .view-id-frontpage.view-display-id-attachment_2 {
//     background: $gray-lightest;
//     .view-content {
//         display: flex;
//         max-width: $screen-lg;
//         padding: 2em 0;
//         justify-content: space-between;

//         .views-row {
//             width: 33%;
//             flex: 0 1 33%;
//             display: inline-block;
//             border-right: 1px solid $gray-lighter;
//             margin: 0;

//             &:last-child {
//                 border-right: none;
//             }

//             .card-content {
//                 padding: 0 2em;
//                 flex-direction: column;
//                 text-align: center;
//                 height:  100%;
//             }
//         }
//     }
// }

.view-frontpage,
.view-front-page-2020 {
  .view-display-id-attachment_2,
  .view-fbc-ctas.view-display-id-block_1 { // Smaller CTAs
    background: $gray-lightest;
    .view-content {
      @include outer-container;
      display: flex;
      max-width: $screen-lg;
      padding: 0;
      justify-content: space-between;
      flex-wrap: wrap;
      @include media($screen-md){
        flex-wrap: nowrap;
      }

      .views-row {

        width: 100%;
        flex: 0 1 100%;
        min-width: 100%;
        display: inline-block;
        // border-right: 1px solid $gray-lighter;
        margin: 0;
        @include media($screen-md){
          width: 33%;
          flex: 0 1 33%;
          min-width: 33%;
        }

        &:last-child {
            border-right: none;
        }

        .card-content {
            padding: 2em;
            flex-direction: column;
            text-align: center;
            height:  100%;
        }
        .card-link {
          order: 3;
        }
      }
    }
    .view-footer {
      padding-top: 1em;
      background: $white;
    }
  }
}
