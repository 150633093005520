.has-background{

  &.brand-default-light{
    background: $light-color-primary;
  }
  &.oneqa{
    background: #EBF0F5;
  }

  &.full-width-light-gray,
  &.light-full-width {
    @include extended-background($gray-lightest);

  }

  &.gray-light,
  &.light{
    background: $gray-lightest;
    h1,h2,h3,p{
      color: $base-font-color;
    }
  }

  &.gray-medium,
  &.medium{
    background: $gray-medium;
    * {
      color: $white;
    }
  }

  &.medium-full-width {
    @include extended-background($gray-light);
    *{
      color: $white;
    }
  }

  &.gray-dark,
  &.dark{
    background: $gray-dark;
    *{
      color: $gray-lightest;
    }
  }
  &.dark-full-width {
    @include extended-background($gray-medium);
    *{
      color: $white;
    }
  }

  &.solid-teal{
    background: $teal;
    h1,h2,h3,p{
      color: $gray-lightest;
    }
  }
  &.transparent{}
    background: transparent;
  &.white-to-gray{
    @include linear-gradient($white, $gray-light);
  }
  &.white-to-teal{
    @include linear-gradient($white, $teal);
  }


  .entity-paragraphs-item &{
    padding: 1em;
    //margin: 0 -1em;
  }
}

.Centered {
  text-align: center;
}
.Left {
  text-align: left;
}
.Normal {
  h2{
    font-size: 30px;
    font-weight: 700;
  }
  h3 {
    font-size: 22px;
    font-weight: 700;
  }
}
.Big {
  h2 {
    font-size: 40px;
    font-weight: 700;
  }
  h3 {
    font-size: 30px;
  }
}

.Bigger {
  h2 {
    font-size: 46px;
    font-weight: 700;
  }
  h3 {
    font-size: 36px;
  }
}
