/*Probably shouldn't do this this way, but it was easeri than changing the whole theme.*/

.page-admin-appearance-styleguide{
  .main-container{
    @include outer-container();

    #styleguide-header{
      @extend %adds-toggle-plus;
      padding-left: 2em;
      a[data-parent='#accordion']{
        top: -12px;
        left: -18px;
      }

      .item-list{
        float: none;
        clear: both;
        position: relative;
        
        .panel-collapse{
          display: none;

        }
      }
    }
  }
}