.ui-accordion{
  >div{
    margin-bottom: 1em;
  }
  .ui-accordion-icons{
    padding-left: .5em;
  }
  .ui-accordion-header{
    .ui-accordion-header-icon{
      @include icon(before, plus);

    }
  }

  .ui-accordion-header{
    background: none;
    padding: .5em 4em .5em .7em;
    &:before{
      color: $base-link-color;
      position: absolute;
      right: 1em;
      top: .5em;
    }
    a{
      color: $base-link-color;
      font-weight: 700;
    }
    .ui-icon{
      background-image: none;

    }
  }
  .ui-accordion-header-active.ui-state-default{
    background: #e6e6e6;
    @include icon(before, minus);
    &:before{
      color: $base-font-color;
    }
  }

  .ui-state-default{
    @include icon(before, plus);
    border: none;

  }
  .ui-accordion-content{
    padding: 1em;

  }
  .ui-widget-content{
    border: none;
    a{
      color: $base-link-color;
    }
  }
}



