//#navbar{
//  height: 90px;
//  //transition: height .25s ease-in-out, box-shadow .25s ease-in-out;
//  border-bottom: 4px solid $teal-light;
//  //box-shadow: 0 1px 2px transparent; /* Vendor Prefixes no longer required for box shadow */
//  background: transparentize($white, .1);
//  position: relative;
//
//  .container{
//    display: flex;
//    height: 100%;
//    .navbar-header{
//      width: 20%;
//    }
//    .navbar-collapse{
//      width: 100%;
//
//      .region-navigation{
//        display: flex;
//        justify-content: flex-end;
//        .block-menu-block{
//          width: 70%;
//          section{
//            display: inline;
//          }
//        }
//      }
//    }
//  }
//
//  &.stickynav-active {
//    position: fixed;
//    //height: 60px;
//    //box-shadow: 0 1px 2px $gray-medium; /* Vendor Prefixes no longer required for box shadow */
//    //transition: height .25s ease-in-out, box-shadow .25s ease-in-out;
//    //
//    //ul.menu > li{
//    //  line-height: 60px;
//    //  transition: line-height .25s ease-in-out;
//    //  ul{
//    //    li{
//    //      line-height: 1;
//    //    }
//    //  }
//    //  &.products,
//    //  &.service-support{
//    //    ul{
//    //      top: 60px;
//    //    }
//    //  }
//    //}
//    //#block-search-form,
//    //.search-bar{
//    //  line-height: 60px;
//    //  transition: line-height .25s ease-in-out;
//    //}
//    //
//    //a.logo {
//    //
//    //  img{
//    //
//    //  }
//    //}
//
//
//  }
//  .navbar-collapse {
//    display: none;
//    @include media($tablet) {
//      display: block;
//    }
//  }
//  a.logo {
//    display: inline-block;
//    height: 100%;
//    padding: .5em 0;
//    margin-left: -3em;
//
//    @include media($tablet) {
//    }
//    @include media($desk-xl) {
//    }
//    img {
//      max-height: 100%;
//      width: 100%;
//    }
//  }
//
//
//  ul.menu{
//    margin: 0;
//    > li{
//      padding: 0 1em;
//      line-height: 90px;
//      transition: line-height .25s ease-in-out;
//      ul li{
//        line-height: 1;
//      }
//    }
//  }
//
//  #block-search-form,
//  .search-bar{
//    line-height: 90px;
//    transition: line-height .25s ease-in-out;
//  }
//
//
//}