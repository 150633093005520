.view-fbc-service-and-support{
  &.view-display-id-block{
  .views-exposed-form{
    background: $gray-lighter;
    padding-left: 1em;
  }

.view-content{
  margin-top: 2em;
}
    .views-row{
      margin-bottom: 2em;
      padding-bottom: 2em;
      border-bottom: 1px solid $gray-lightest;

      &:last-child{
        border-bottom: 0px solid transparent;
      }

      .service-info{
        display: flex;
        width: 100%;
        justify-content: flex-start;
      }
      .contact-info{
        display: inline-block;
        width: 50%;
        padding: 1em;
        background: $gray-lightest;
        //@include media($screen-md){
        //  width: 30%;
        //
        //}
        //
        //.paragraphs-items-field-contact-information{
        //  width: 100%;
        //}
        .paragraphs-item-partner-contact-information{
          .field-label,
          .field-items{
            line-height: 1;
            display: block;
            width: 100%;
            vertical-align: top;
            padding-bottom: .5em;
            @include media($screen-sm){
              width: 50%;
              display: inline-block;

            }

          }
          .field-label{
            @include media($screen-sm){
            width: 180px;
            }
          }
          //.field-items{
          //  display: inline-block;
          //  width: 50%;
          //  vertical-align: top;
          //}
        }
      }
      .contact-details{

        display: inline-block;
        margin-top: 1em;
        width: 100%;

        .item-list{
          @include clear-ul;
        }
      }
      .field-name-field-company-website{
        margin-bottom: 1em;
      }
      .services{
        display: inline-block;
        padding-left: 1em;
        width: 50%;
        //@include media($screen-md){
        //  width: 70%;
        //
        //}
        h3{
          font-size: 1.4rem;
          font-family: $font-proxima-nova-semibold;
          line-height: 2rem;

          @include media($screen-md){
            font-size: 1.8rem;
            line-height: 2.4rem;
          }
        }
        .services-listing{
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          @include clear-ul;
        }

        ul{
          margin: 0;
          padding: 0;


          li{
            display: block;
            width: 100%;
            padding: 0;
            font-family: $font-proxima-nova-semibold;
            margin-bottom: .5em;

          }
          .service-value > .value-no{
            color: $gray-medium;
          }
        }

      }
      .instructions{
        margin-top: 2em;
        p{
          font-size: 1.5rem;
          font-style: italic;
          
        }
      }
    }
  }
}