
@media print{
  body{
    width: 80vw;
    margin-left: 10vw;
  }
  .error{
    display: none;
  }
  .developer-notes{
    display: none;
  }
  .sidebar-quick-contact{
    display: none;
  }
  .field-name-field-complete{
    display: none;
  }

  .field-name-field-short-title{
    display: none;
  }
  .field-name-field-notes{
    display: none;
  }
  .field-item:after{
    display: none;
  }
  .node-webform{
    display: none;
  }
  .colorbox-node{
    display: none;
  }
  a{
    display: none;
  }
  img{
    display: none;
  }
  .sidebar-downloads{
    display: none;
  }
  .breadcrumbs{
    display: none;
  }
  .tabs{
    display: none;
  }
  .horizontal-tab-button{
    display: none;
  }
  .slideshow{
    display: none;
  }
  .horizontal-tabs-list{
    display: none !important;
  }
  .horizontal-tab-hidden{
    visibility: visible !important;
    display: block !important;
    overflow: visible !important;
    max-height: none !important;
    height: auto;
    width: auto;
  }
  .page-specs .horizontal-tabs-panes, .view-mode-datasheet .horizontal-tabs-panes{
    visibility: visible !important;
    display: block !important;
    overflow: visible !important;
    max-height: none !important;
    height: auto;
    width: auto;
  }

}