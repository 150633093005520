@import "test-background";
//
//%three-by{
//  .views-row{
//    @include span-columns(12);
//    margin: 0 auto 2em auto;
//    @include media($tablet) {
//      @include span-columns(6);
//      &:nth-child(2n+2){
//        margin-right: 0%;
//      }
//    }
//    @include media($desk) {
//      padding: 1em;
//      &:nth-child(2n+2){
//        margin-right: 2.43902%;
//      }
//      &:nth-child(3n+3){
//        margin-right: 0;
//      }
//      &:nth-child(3n+1){
//        clear: left;
//      }
//      @include span-columns(4);     // apply span
//    }
//    h3 {
//      margin-top: .5em;
//    }
//    img{
//      height: auto;
//      min-width: 100%;
//      min-height: 100%;
//      width: auto;
//    }
//  }
//}


%gray-link{
  a{
    color: $gray-medium;
    &:hover{
      text-decoration: none;
    }
  }

}



%responsive-wrapper{
  max-width: 100%;
  padding: 3vw;
  //@include test-background('true');
  @include media($screen-xs){
    margin: 0 auto;
  }
  @include media($screen-sm){
    max-width: $screen-sm;
  }
  @include media($screen-md){
    max-width: $screen-md;
    padding: 2vw;
  }
  @include media($screen-lg){
    @include outer-container();
  }
}

