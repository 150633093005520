%alternate-cards{
  .views-row:nth-child(even) {

    .card-image {
      order: 2;
      margin: 0;

    }
    .card-text{
      order: 1;
    }
  }
}