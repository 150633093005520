.view-id-fbc_events {
    &.view-display-id-block_5 {
        .views-row {
            margin-bottom: 2em;
        }
        .view-content {
            .field-content {
                display: flex;
                justify-content: flex-start;
                .webinar-icon,
                .webinar-info,
                .webinar-registration {
                    display: inline-block;
                    margin-right: 1em;
                }
                .webinar-registration {
                    margin-right: 0;
                    
                    .button {
                        margin: 0;
                    }
                }
                .webinar-icon,
                .webinar-registration {
                    .button {
                        padding: 0.5em 1em;
                    }
                }
                .webinar-info {
                    width: 60%;
                }
                .webinar-icon {
                    img {
                        width: 100%;
                    }
                }

            }
        }
    }
}
