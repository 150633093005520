.view-fbc-ctas,
.slick--optionset--fbc-cta-slideshow,
.slick--view--frontpage--attachment-1{
  .node-call-to-action{
    max-width: 100%;
    height: auto;

  }
  .slick-dotted.slick-slider{
    margin-bottom: 0;
  }
  .slick-list {
    overflow: visible;
  }
}

.page-no-display-preview-ctas{
  .paragraphs-items{
    max-width: 100%;
  }
}
.node-type-landing-page {
  .node-call-to-action {
    margin-top: 60px;
  }
}
.node-call-to-action {
  position: relative;
  height: 400px;
  display: block;
  .banner-content {
    height: 400px;

    .background-section {
      max-height:  100%;
      overflow: hidden;

      .field-name-field-background-video {
        display: none;

        @include media($screen-lg) {
          display: block;
        }
      }

      .field-name-field-additional-overlay-text {
        height: 50px;
        position: absolute;
        z-index: 10000;
        width: 100%;
        background: $black;
        color: white;
        bottom: 0;
        text-align: center;

        p {
          font-size: 5vw;
          font-family: $font-proxima-nova-bold;
          line-height: 50px;

          @include media($screen-sm) {
            font-size: 30px;

          }
        }
      }
    }
  }


  // FONTS

  @include responsive-font(4vw, 16px, 18px, 16px);
  p {
    @include responsive-font(4vw, 16px, 18px, 16px);

    margin-bottom: 1em;
    font-weight: 500;
  }
  h2, h2 p {
    @include responsive-font(6vw, 18px, 24px, 24px);

    font-family: $font-proxima-nova-bold;
    margin-bottom: 4px;
    font-weight: 500;

    @include media($screen-md) {
      @include responsive-font(6vw, 24px, 30px, 20px);
    }
    @include media($screen-lg) {
      @include responsive-font(6vw, 24px, 40px, 30px);
    }
  }
  h3, h3 p {
    @include responsive-font(5vw, 20px, 20px, 20px);

    font-family: $font-proxima-nova-regular;
    margin-bottom: 4px;
    font-weight: 500;

    @include media($screen-md) {
      @include responsive-font(6vw, 24px, 24px, 20px);
    }
    @include media($screen-lg) {
      @include responsive-font(6vw, 24px, 28px, 24px);
    }
  }

  a {
    @extend %button-secondary;
    margin-right: auto;
    display: inline-block;
  }

  &.treatment-light-text {
    color: $white;
    text-shadow: 0 0 1px #000;

    h1, h2, h3, p {
      color: $white;
      text-shadow: 0 0 1px #000;
    }
  }
  &.treatment-dark-text {
    h1, h2, h3, p {
      color: $black;
    }
  }
  &.treatment-pop-text {
    h1, h2, h3, p {
      color: $yellow;
      text-shadow: 0 0 1px #000;
    }
  }

  .field-name-new-bug{
    position: absolute;
    top:2em;
    left: 2em;
    max-width: 50px;
    transform: rotate(-35deg);
    @include media($screen-md){
      top:3em;
      left: 3em;
    }
    .field-item{
      height: 50px;
      .field-items {
        height: 50px;
      }
    }
    img{
      max-height:50px;
      max-width: 50px;
    }
  }

  // BACKGROUND
  .background-section {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    height: 400px;
    .field-name-field-background-image {
      height: 100%;
      .field-items {
        height: 100%;
        .field-item {
          height: 100%;
        }
      }
    }
    img {
      min-width: 100%;
      min-height: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }

  // FOREGROUND
  .foreground-section {
    @include outer-container();
    display: flex;
    flex-direction: column;
    padding: 1em;

    @include media($screen-sm) {
      height: 400px;
      max-height: 400px;
      display: flex;
      flex-direction: row;
      padding: 0;
      justify-content: space-around;
      width: 100%;
    }
  }

  &.new {
    .foreground-section{
      position: relative;
      &:after {
        position: absolute;
        content: '';
        top: -18px;
        left: -1em;
        height: 100px;
        width: 150px;
        background: url('../images/icon-new-with-tab.png') no-repeat;
       background-size: 100%;
      }
    }
  }
}

// ******************************************
// THE BANNER WITH CENTERED TEXT SECTION
// ******************************************

.view-mode-banner_with_centered_text {

  // FOREGROUND
  .foreground-section {

    .text-area {
      display: block;
      padding: 2em 0;
      max-width: $screen-lg;
      width: 100%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      @include media($screen-sm) {
        max-width: 50%;
      }

      @include media($screen-lg) {
        text-align: left;
        max-width: 33vw;
        padding: 0;
      }
      img{
        max-height: 100px;
        width:auto;
        margin-bottom: .5em;
        object-fit: contain;
        object-position: left;
      }
    }
  }

  &.top .foreground-section .text-area,
  &.middle .foreground-section .text-area{
    margin-top: 0;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    //text-align: center;

  }
  &.bottom .foreground-section .text-area{
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    //text-align: center;
  }
  &.left,
  &.right{
    .text-area{
      //text-align: center;
      margin: 5vw;
    }
  }

  @include media($screen-sm){
    &.top .foreground-section .text-area{
      margin-top: 2vw;
      margin-bottom: auto;
      text-align: left;
      margin-bottom: 60px;
    }
    &.middle .foreground-section .text-area{
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding-top: 3em;
      padding-bottom: 4em;
    }
    &.bottom .foreground-section .text-area{
      margin-bottom: 0;
      text-align: left;
      padding-top: 3em;
      padding-bottom: 4em;
    }
    &.left .foreground-section .text-area{
      text-align: left;
      margin-left: 0;
      margin-right: auto;
      padding-left: 2vw;
    }
    &.center .foreground-section .text-area{
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      img{
        margin: auto;
      }
      a {
        margin: auto;
      }

    }
    &.right .foreground-section .text-area{
      text-align: left;
      margin-left: auto;
      margin-right: 0;
      padding-right: 2vw;

    }
  }
  @include media($screen-lg){
    &.left .foreground-section .text-area{
      padding-left: 0;
    }
    &.right .foreground-section .text-area{
      padding-right: 0;
    }
  }
}
// ******************************************
// THE IMAGE AND TEXT SECTION
// ******************************************
.view-mode-banner_with_image_and_text,
.view-mode-home_page{
  height: 400px;

  .foreground-section{
    font-family: $font-proxima-nova-regular;
    margin: auto;
    z-index: 1;
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;

    @include media($screen-sm) {
      flex-direction: row;
    }
    .foreground-image,
    .text-area {
      display: block;
      width: 100%;
      text-align: center;
      @include media($screen-sm) {
        min-height: 100px;
        display: flex;
        align-items: center;
        text-align: left;
      }
      img {
        width: 100%;
        object-fit: contain;
        max-height: 90%;
      }
    }

    .text-area {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: normal;
      justify-content: center;

      & > .field {
        margin-bottom: 1em;
      }
    }
  }

      &.img-left {
        @include media($screen-sm) {

          .foreground-image {
            order: 1;
          }
          .text-area {
            order: 2;
          }
        }
      }
      &.img-right {
        @include media($screen-sm) {

          .foreground-image {
            order: 2;
          }
          .text-area {
            order: 1;
          }
        }
      }

    }
