.node-type-product-display {

  h2 > p {
    @extend h2;
  }

  &.not-logged-in .developer-notes {
    display: none;
  }

  .switch-view-mode-field,
  .field-name-ds-switch-field {
    position: absolute;
    right: -2px;
    display: flex;
    flex-direction: column;

    span {
      @extend %button-white;
    }
  }

  //Add padding for the responsive parts.
  .breadcrumbs,
  .product-overview-wrapper,
  .field-name-field-feature-banner {
    padding: 0 1em;
    @include media($desk-wide) {
      padding: 0;
    }
  }

  .breadcrumbs {
    @include outer-container();
    margin: 1em auto 2em;
    font-size: 1.2rem;
    padding-top: 1em;
  }

  .main-container {
    max-width: 100%;
    overflow: hidden;
  }

  .overview-content-group {
    .field-name-field-voc-statement p {
      font-weight: 500;
      font-size: 2rem;
      max-width: 80%;
    }
  }

  .product-form-fields {
    .product-overview-wrapper {
      @include outer-container();
      display: flex;
      position: relative;
      flex-wrap: wrap;

      @include media($screen-md) {
        justify-content: space-around;
        flex-wrap: nowrap;
        padding-bottom: 0;
        min-height: 600px;
      }

      .product-overview-content {
        display: inline-block;
        width: 100%;
        flex: 0 1 100%;
        max-width: 100%;

        @include media($screen-md) {
          flex: 0 1 60%;
          padding-right: 2em;
          width: 60%;
          max-width: 60%;
        }
      }

      .form {
        display: inline-block;
        flex: 0 1 100%;
        max-width: 100%;
        margin-top: 1em;

        > .form {
          max-width: 500px;
          margin: 0 auto;
          display: block;
        }

        @include media($screen-md) {
          flex: 0 1 40%;
          margin-top: 0;
          max-width: 40%;
        }

        .field-name-field-summary {
          margin: 1.5em 0;
        }
      }
    }
  }

  .overview-slideshow {
    margin-bottom: 2em;
    margin-bottom: 2em;
    max-height: 525px;
    overflow: hidden;
    min-height: 525px;
  }

  .overview-resources {
    margin-top: 2em;
    margin-bottom: 3em;
  }

  .slideshow {
    .slick {
      .slide {
        img {
          max-height: 562px;
        }
      }
    }

    .slick-wrapper {
      position: relative;

      .slick--fbc {
        width: 100%;
        margin-bottom: 1em;

        @include media($screen-xs) {
          width: calc(100% - 100px);
          margin-left: 100px;
          max-height: 525px;
          overflow: hidden;
          margin-bottom: 0;
        }

        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          margin: auto;
        }
      }

      .slick--fbc-nav-vert {
        max-height: 60px;
        z-index: 3;

        @include media($screen-xs) {
          max-height: none;
          position: absolute;
          top: 50px;
          left: 0;
          width: 80px;
        }

        .slick-track {
          display: flex;
          @include media($screen-xs) {
            display: block;
          }
        }

        .slick__slide {
          height: 50px;
          width: 75px;
          border: 1px solid $gray-light;
          &:hover {
            cursor: pointer;
          }

          @include media($screen-xs) {
            margin-bottom: 1em;
          }

          img {
            margin: auto;
            max-height: 100%;
          }

          .slide__content {
            width: 100%;
            margin-left: 1em;
          }
        }

        .slick__arrow {
          position: absolute;
          top: -70px;
          bottom: 0;
          height: calc(100% + 90px);

          .slick-prev,
          .slick-next {
            height: 80px;
            width: 40px;
            &:after {
              left: -85px;
              line-height: 80px;
            }
          }

          .slick-prev {
            position: absolute;
            top: 0;
            left: 20px;
            transform: rotate(90deg);
          }

          .slick-next {
            position: absolute;
            left: 20px;
            top: calc(100% - 50px);
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  .field-name-field-form,
  .field-name-field-form-block {
    .field-items {
      input[type="submit"] {
        font-size: 1.6rem;
        background: $yellow;
        border: none;
        box-shadow: none;
        padding: 0.5em 1em;
      }
    }

    .webform-client-form,
    .block-webform {
      background-color: $gray-lightest;
    }

    .form-wrapper {
      .form-item {
        label {
          font-size: 1.5rem;
        }
      }
    }

    .field-name-title {
      background: $base-font-color;
      text-align: center;
      margin: 0;
      h2,
      h3 {
        color: $white !important;
        padding: 0.5em;
        font-size: 2.3rem;
        margin: 0;
      }
    }
  }

  .field-name-field-form-block {
    position: relative;
  }

  .resources,
  .overview-resources {
    display: flex;
    justify-content: space-around;
    width: 100%;

    > .field {
      display: block;
      position: relative;
      flex: 0 1 33%;
      text-align: center;
      font-weight: bold;
      height: 120px;

      &:hover:before,
      &:hover:after {
        filter: contrast(80%);
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-height: 60px;
        max-width: 50px;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0.5em auto;
        pointer-events: none;
        width: 100%;

        @media screen and (-ms-high-contrast: active),
          (-ms-high-contrast: none) {
          top: 10px;
        }
      }

      a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        padding-top: 80px;
      }

      .field-item {
        position: relative;
        height: 110px;
        width: 100%;
      }
    }

    .field-name-ds-specs-colorbox-node {
      &:after {
        @include colored-svg(
          url("../images/icons/specifications.svg"),
          $color-secondary,
          90%
        );

        @supports (-ms-ime-align: auto) {
          background-image: url("../images/icons/specifications.svg");
        }
      }
    }

    .field-name-demo-video {
      position: relative;

      &:after {
        @include colored-svg(
          url("../images/icons/video-demo-purple.svg"),
          $color-secondary,
          90%
        );
      }

      .field-name-field-title {
        display: none;
      }

      .field-item {
        position: relative;
        height: 120px;
        header {
          display: none;
        }

        img {
          height: 0;
          width: 0;
        }
      }
    }

    .field-name-field-overview-manual {
      &:after {
        @include colored-svg(
          url("../images/icons/download.svg"),
          $color-secondary,
          100%
        );
      }
    }
  }

  .field-name-ds-user-s-manual {
    .view {
      margin-top: 0.7em;
    }

    .view-content {
      margin-top: 0.5em;
    }

    .views-row {
      margin-bottom: 0.75em;
    }

    a {
      position: relative!important;
      padding-top: 0!important;
    }
  }

  .product-details-wrapper {
    @include outer-container(100%);

    min-height: 10vh;

    @include media($screen-lg) {
      @include outer-container();
    }

    .product-details-content {
      max-width: 100%;
    }

    .field-name-field-short-title {
      display: inline-block;
      padding: 0.5em 1em 0 0;
      font-weight: bold;
      color: $gray-dark;
      background: $white;
      width: 100%;
      @include media($screen-lg) {
        width: auto;
        background: none;
      }
    }

    .horizontal-tabs {
      .horizontal-tabs-list {
        display: flex;
        //padding: 1em;
        margin: 0;
        padding-top: 2.5em;
        width: 100vw;
        flex-wrap: wrap;
        height: 0;
        overflow: hidden;
        transition: height 0.5s;
        background: $gray-lightest;
        @include media($screen-md) {
          width: auto;
          @include extended-background($white);
          height: auto;
          padding: 0;
          flex-wrap: nowrap;
          margin-top: 0;
          justify-content: flex-start;
        }

        @include media($screen-lg) {
          height: 40px;
          margin-top: 0;
        }

        @include media($screen-md) {
          padding-left: 1em;
        }

        @include media(1272px) {
          padding-left: 2000px;
          padding-right: 2000px;
        }

        &.sticky-header {
          position: fixed;
          top: 0;
          margin-top: 60px;
          z-index: 4;
        }

        .field-name-field-short-title {
          position: absolute;
          top: 0px;
          padding: 1em;
          color: $base-font-color;
          min-width: 100vw;
          border-bottom: 1px solid $gray-lighter;
          width: auto;

          &:after {
            content: "\f107";
            position: absolute;
            top: 6px;
            left: 0;
            right: 0;
            bottom: 0;
            text-align: center;
            font-family: FontAwesome;
            color: $color-primary;
            font-size: 3rem;
            @include media($screen-md) {
              content: "";
            }
          }

          @include media($screen-md) {
            border-bottom: none;
            width: auto;
            min-width: 10em;
            position: relative;
            top: auto;
            left: auto;
            padding: 0.5em;
          }
          @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active),
            (-ms-ime-align: auto) {
            width: 15em;
          }
        }

        li {
          display: block;
          width: 100vw;
          border-bottom: 1px solid $gray-lighter;
          text-align: center;
          padding: 0.5em;

          &:hover {
            background: $white;
          }

          @include media($screen-md) {
            width: auto;
            padding: 0;
            background: none;
            display: inline-block;
            border-bottom: 0;
            text-align: left;
          }
        }

        &.header-opened {
          height: auto;
          transition: height 0.5s;
        }
      }

      .horizontal-tabs-panes {
        @include clearfix;
        padding: 1em;
        padding-top: 3em;

        @include media(1272px) {
          padding: 1em 0;
        }

        .field-label-abovec {
          > .field-label {
            @extend h2;
            font-weight: normal;
          }
        }

        fieldset {
          padding: 0;
          margin: 0;
        }

        .field-group-htab {
          @include outer-container();

          .field-name-field-product-overview,
          .field-name-field-applications {
            > .field-items {
              > .field-item {
                @include extended-background($white);
                -webkit-box-shadow: 0 -10px 25px -16px #555;
                -moz-box-shadow: 0 -10px 25px -16px #555;
                box-shadow: 0 -10px 25px -16px #555;

                .card-wide-layout {
                  padding-top: 2em;
                  border-top: none;
                }
              }
            }
          }

          .field-name-field-applications {
            > .field-items {
              > .field-item:first-of-type {
                box-shadow: none;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
              }
            }
          }
        }
      }

      .group-features {
        margin-top: 1em;
        .group-features-wrapper {
          @include basic-flex;
          margin: 0 0 3em 0;

          .field-name-field-key-features {
            padding-right: 1em;
            .field-label {
              flex: 0 1 100%;
              font-size: 3rem;
              font-weight: 500;
              display: block;
            }
          }

          .field-name-field-key-features-image {
            margin-top: 3em;
            padding-left: 2em;
          }
        }
      }

      .field-name-field-highlights-section-header {
        h4 {
          margin-top: 0.5em;
        }
      }

      .field-name-field-related-video {
        margin-top: 1em;

        .node-resource {
          display: flex;
          justify-content: flex-start;

          header h2 {
            display: inline-block;
            font-weight: normal;
            order: 2;
          }

          .field-name-field-video {
            margin-right: 1em;
            display: inline-block;
            order: 1;
          }
        }
      }

      .field-name-field-related-resources {
        margin-top: 1em;
      }
    }
  }

  .group-overview-card {
    > .card-content {
      padding-top: 2em;
      margin-bottom: 2em;
    }
  }

  .field-name-field-product-overview,
  .field-name-field-applications {
    .even {
      .card-image {
        order: 2;
      }
      .card-text {
        order: 1;
      }
    }
  }

  .view-fh-individual-product {
    th {
      &:first-child {
        width: 25%;
      }
    }
  }

  .views-exposed-form {
    select {
      background: lighten($gray-lightest, 6%);
      border: 1px solid lighten($gray-lighter, 8%);
      height: 40px;
      padding-right: 2em;
    }

    input[type="submit"] {
      background: $white;
      color: $blue;
      border-radius: 4px;
      padding: 0 1em;
      &:hover {
        background: darken($white, 0.1);
      }
    }
  }

  .field-name-field-feature-banner {
    background: $gray-lightest;
    padding: 2em;
    width: 100%;

    .node-feature-banner {
      margin: 2em;
      background: none;
      @include outer-container();

      > div {
        display: flex;
      }
    }

    .field-name-field-detail-image {
      display: inline-block;
      flex: 0 1 20%;
      width: 20%;
      left: 0;

      .field-item {
        height: 260px;
        width: 260px;
        min-width: 0;
        min-height: 0;
        overflow: hidden;
        border-radius: 50%;
        position: relative;

        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          min-width: 260px;
          min-height: 260px;
          width: auto;
          height: auto;
          max-height: none;
          max-width: none;
        }
      }

      .field-items {
        &:before {
          background: none;
        }
      }
    }

    .group-content {
      flex: 0 1 60%;
      width: 60%;
      padding-left: 2em;
    }

    .content-container {
      z-index: 1;
    }

    .field-name-field-quote p {
      font-weight: 700;
      font-size: 2.6rem;
    }
  }

  .field-name-get-more-info-button {
    margin: 2em 0 5em 0;
    a {
      font-size: 1.6rem;
      padding: 1em 3em;
      background: $yellow;
      border-radius: 3rem;
      box-shadow: none;
    }
  }
}

.feature-banner {
  width: 100%;
}

.field-name-ds-product-accessories-optional {
  padding-top: 2em;
  @include extended-background($white);
  -webkit-box-shadow: 0 -10px 25px -16px #555;
  -moz-box-shadow: 0 -10px 25px -16px #555;
  box-shadow: 0 -10px 25px -16px #555;
}

/*
Styling for models and accessories
*/

.view-fbc-products-individual-content.view-display-id-block_4 {
  .view-content {
    margin-bottom: 2em;
    & > h3 {
      background: $gray-lightest;
      display: block;
      padding: 0.5em;
      margin-top: 1em;
    }

    .views-row {
      width: 100%;
      border-bottom: 1px solid $gray-lightest;
      padding: 0.5em 1em;
      @include media($screen-md) {
        display: flex;
        justify-content: flex-start;
      }

      &:hover {
        background-color: rgba($gray-lightest, 0.25);
      }

      .views-field {
        display: inline-block;
      }

      .views-field-title-field,
      .views-field-field-model-name-et,
      .views-field-field-model-number-et {
        padding-bottom: 1em;
        display: block;
        @include media($screen-md) {
          flex: 0 1 20%;
          padding-right: 1em;
          padding-bottom: 0;
        }
        @include media($screen-md) {
          padding-right: 2em;
        }

        .field-content {
          font-family: $font-proxima-nova-bold;
        }
      }

      .views-field-field-preview-summary,
      .field-name-field-description,
      .views-field-field-description {
        flex: 0 1 100%;

        @include media($screen-md) {
          display: inline-block;
          flex: 0 1 80%;
          margin-top: 0.5em;
        }
      }

      .views-field-field-preview-summary {
        margin-left: 3em;
      }
    }
  }
}

.view-fbc-products-individual-content {
  &.view-display-id-block_4 {
    padding: 1em 0;
  }

  &.view-display-id-block_5 {
    padding: 1em 0 0 0;
  }
}

.field-name-field-ds-products-software,
.field-name-field-software {
  .card-wide-layout {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }

  .view-content {
    .attachment {
      @include extended-background($white);
      -webkit-box-shadow: 0 -10px 25px -16px #555;
      -moz-box-shadow: 0 -10px 25px -16px #555;
      box-shadow: 0 -10px 25px -16px #555;
    }
  }
}

.field-name-field-software-plugins,
.field-name-field-other-software {
  margin-bottom: 3em;
  > .field-items {
    > .field-item {
      margin-bottom: 1em;
    }
  }
}

.field-name-ds-care-plan-overview {
  .card-wide-layout {
    @include extended-background($white);
    -webkit-box-shadow: 0 -10px 25px -16px #555;
    -moz-box-shadow: 0 -10px 25px -16px #555;
    box-shadow: 0 -10px 25px -16px #555;

    &:first-of-type {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
    .card-image {
      max-height: 300px;
      img {
        max-height: 100%;
        width: auto;
        min-width: initial;
      }
    }

    &:nth-child(2) {
      .card-image {
        order: 1 !important;
      }
      .card-text {
        padding-left: 2em;
      }
    }
  }
}

.field-name-field-before-sensors-content {
  padding: 2em 0;
  max-width: 80%;
}

#block-views-fbc-admin-products-block-1,
.developer-notes {
  background: $gray-lightest;
  position: fixed;
  top: 20em;
  left: -229px;
  width: 229px;
  font-size: 1.2rem;
  transition: left 0.25s ease-out;
  z-index: 2;

  &:hover {
    transition: left 0.25s ease-in;
    left: 0em;
    z-index: 100;
  }

  .ui-accordion {
    .ui-accordion-header {
      padding: 0.1em;
      &:before {
        top: 0;
      }
    }

    .ui-accordion-content {
      padding: 0.25em;
    }
  }

  &:before {
    border-radius: 0 4px 4px 0;
    box-shadow: -2px -2px 5px $gray;
    font-family: FontAwesome;
    content: "\f21b";
    height: 50px;
    width: 50px;
    position: absolute;
    top: 1em;
    right: -50px;
    background: $color-primary;
    color: $white;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
  }
}

.developer-notes {
  top: 15em;
  transition: left 0.5s;
  padding: 1em;

  &:hover {
    transition: left 0.5s;
  }

  &:before {
    background: $gray-light;
    content: "\f249";
  }
  .field-label {
    font-size: 1.6rem;
    font-weight: normal;
  }

  .field-name-field-complete,
  .field-name-field-current-site-url {
    display: flex;
    .field-label,
    .field-items {
      font-size: $base-font-size !important;
      display: inline-block;
      margin-right: 1em;
    }
    .field-items {
      margin-right: 0;
    }
  }
  .field-name-field-notes {
    max-width: 90%;
    overflow: hidden;
  }
}

.field-name-ds-resources-in-table {
  .views-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;

    .views-field {
      width: 50%;
      display: inline-block;
    }

    .primary,
    .resources-primary {
      display: inline-block;
      margin-right: 1em;
    }

    .resource-secondary,
    .resources-secondary {
      display: flex;
      flex-wrap: wrap;
      margin-top: -0.5em;

      a {
        flex-basis: 25%;
        display: inline-block;
        font-size: 1.2rem;
        margin: 0.5em;

        &:nth-child(4n + 4) {
          flex-basis: 100%;
        }
      }
    }

    .views-field-nothing {
      .field-content {
        display: flex;
        > div {
          display: inline-block;
        }
      }
    }
  }
}
