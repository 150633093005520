.field-name-ds-products-compare{
  th,td{
    padding: .5em 1em;
  }
  tr{
    background-color: transparent;
    padding: 0;

  }
  td{
    background: none;
    border-bottom: 1px solid $gray-lightest;

    &:first-of-type{
      font-weight: 700;
    }
  }
}