.slick__slider{
  opacity: 0;
}

.slick__slider.slick-initialized{
  opacity: 1;
  transition: opacity .25s;
}

.slick--optionset--fh-carousel{
  position: relative;
  @include media($screen-md){

  }
  .slick--optionset{
    .slick__slide{
      &:hover{
        cursor: pointer;
      }
    }
  }
  .slick__slide{
    display: inline-block;
    margin-bottom: 1em;
    margin-right: 1em;
    @include media($screen-md){
    }



    img{
      width: auto;
      height: auto;
      max-height: 100%;
      max-width: 100%;
      margin: auto;
    }
    a{
      &:hover{
        cursor: pointer;
      }
    }
  }
  &:hover > .slick__arrow{
    opacity: 1;
    -webkit-transition: opacity .25s ease-out;
    transition: opacity .25s ease-out;
  }

  &.unslick{
    max-width: 25%;
    img{
      width: 100%;
      height: auto;
      display: block;
      margin: auto;
    }
  }
  .slick__arrow{
    .slick-next{
      right: 12px;
    }
  }
}

.slick--optionset--fpi-slideshow{
  position: relative;
  margin-bottom: 3vw;
  overflow: hidden;
  @include media($screen-md){
    //margin-bottom: 0;
  }

  .slick-media{
    @include aspect-ratio(4,3);
    img{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      min-width: 100%;
      height: auto;
    }
  }


  &:hover > .slick__arrow{
    opacity: 1;
    -webkit-transition: opacity .25s ease-out;
    transition: opacity .25s ease-out;
  }
  .slick__arrow{
    position: absolute;
    top: calc(50% - 50px);
    width: 100%;
    .slick-next{
      float: right;
    }
  }
}
.slick--optionset--fpi-slideshow-thumbs{
  margin:1em 0 2em;
  .slick__slide{
    margin-right: 8px;
    border: 1px solid $gray-very-light;
    &.slick-current{
      position: relative;
      &:after{
        position: absolute;
        content: '';
        top: 0%;
        left: 0;
        width: 100%;
      }
    }
    img{
      min-width:100%;
      width: auto;
      height: auto;
    }
  }
}

.slick__arrow{
  position: absolute;
  top: calc(50% - 50px);
  width: 100%;
  opacity: .5;
  -webkit-transition: opacity .25s ease-out;
  transition: opacity .25s ease-out;
  pointer-events: none;
  .slick-next{
    float: right;
  }

  .slick-prev,
  .slick-next{
    background: $gray-very-light;
    text-indent: 100px;
    height: 50px;
    width: 50px;
    overflow: hidden;
    position: relative;
    padding: 0;
    &:after{
      position: absolute;
      top: 0;
      left: -80px;
      line-height: 46px;
      font-size: 2.4rem;


    }
    &:hover{
      background: darken($gray-very-light, 10%);
    }




  }
  button{
    pointer-events: auto;
  }
}

  .slick-prev{
    position: absolute;
    top: 25%;
    left: 0;
    @include icon(after, angle-left);

  }
  .slick-next{
    position: absolute;
    top: 25%;
    right: 0;
    @include icon(after, angle-right);

  }
  .slick-dotted.slick-slider{
    //margin-bottom: 0;
  }

  .slick-dots{
    li{
      text-indent: 100px;
      position: relative;
      overflow: hidden;
      width: 10px;
      @include media($screen-md){
        width: 20px;
      }
      &:after{
        content: "";
        background: $gray-light;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-border-radius: 1em;
        -moz-border-radius: 1em;
        border-radius: 1em;
        
      }
      &.slick-active{
        &:after{
          background: $gray;
        }
      }

    }
  }
.view-fpi-industries-ct{
  .slick__arrow{
    bottom: calc(50% - 50px);

  }
}
