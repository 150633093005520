.view-display-id-attachment_3 {
  .view-content {
    display: flex;
    max-width: $screen-xl;
    margin: auto;
    margin-bottom: 1em;
    justify-content: space-evenly;

    > div {
      display: inline-block;
      max-width: 400px;
      width: auto;
      margin-right: 1em;
      .node-advpoll {
        border: 1px solid $gray;
      }

      .field-name-title-field {
        background: $gray;
        color: white;
        font-weight: bold;
        padding: 1em;
      }
    }
    form {
      padding: 0 1em 1em;
      input[type="checkbox"], input[type="radio"] {
        margin: 0.25em 0.25em 0.5em 0;
      }
    }


    form input[type="submit"] {
      background: $blue;
      color: $white;
      padding: 0.5em 1em;
    }
  }
}

.poll {
  padding: 1em;
  .bar .foreground {
    background-color: $blue;
  }

  .poll-message {
    display: none;
  }
  .percent {
    font-size: 1.2rem;
    font-style: italic;
  }
  .total {
    text-align: right;
    font-weight: bold;
    font-size: 1.2rem;
  }

}
.advpoll-ranking-wrapper {
  tr {
    background-color: transparent;
    td {
      border-bottom: 1px solid $gray-lighter;
      padding: 0.75em 0 0;
    }
  }
  a.tabledrag-handle .handle {
  padding: 0.75em;
  margin: -0.3em 0.25em 1em 1em;
  }

}
