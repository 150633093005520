.app-subsection-wrapper{
  @include outer-container();
}

.paragraphs-items-field-app-subsection-content-ite {
  .field-items {
    display: flex;
    flex-wrap: wrap;
    @include media($screen-md) {
      flex-wrap: nowrap;
      justify-content: space-between;
    }
    .field-item {
      display: inline-block;
      width: 100%;
      margin-top: 0;

      @include media($screen-md) {
        width: 40%;

        & > .paragraphs-item-application-subsection-body {
          width: 150%;
          padding-right: 4em;

          h1,
          h2,
          h3,
          h4 {
            .sub {
              font-weight: 700;
              display: block;
              font-size: 75%;
              margin-top: 1rem;
            }
          }

          h2 {
            font-size: 2.5rem;
          }

          h3 {
            font-size: 2rem;
          }
        }

        &:nth-of-type(2) {
          .paragraphs-item-application-subsection-body {
            margin-left: -50%;
            padding-left: 4em;

            p {
              width: calc(100% + 4em);
            }
          }
        }
      }

      .field-items {
        display: block;
        width: 100%;

        .field-item {
          display: block;
          width: 100%;
        }
      }
    }
  }

  .field-name-field-app-subsection-body-conten {
    margin-bottom: 2em;
  }

  .field-name-field-app-subsection-related-lin,
  .paragraphs-item-application-accordion-item,
  .field-name-ds-resource-link-as-title .field-item {
    margin: 0 0 0.65em 0;
  }

  .field-name-field-app-media-caption {
    font-style: italic;
    font-size: 1.4rem;
  }

  @include media($tablet) {
    @include outer-container();
    flex-wrap: nowrap;
  }
}

.field-name-field-app-subsection {
  & > .field-items {
    & > .field-item:not(:first-of-type) {
      margin-top: 2em;
    }
    & > .field-item:last-of-type {
      margin-bottom: 2em;
    }
  }
}
