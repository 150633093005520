.paragraphs-item-banner-slideshow{
  @include clearfix;
  height: 300px;
}

.paragraphs-item-banner-slideshow{
  .content{
    position: relative;
    @include clearfix;
    .group-image{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      right: 0;
    }
    .group-content{
      position: absolute;
      top: 0;
      left: 0;
      .field-name-field-headline{}
      .field-name-field-subheadline{}
      .field-name-field-text{}
      .field-name-field-banner-slideshow-link{
        a{
          @include button;
        }
      }

    }
  }

}