//$test-background: false;
//
@mixin test-background($boolean){
  @if $boolean == "true"{
    @include media($phone){
      background-color: firebrick;
    }
    @include media($tablet){
      background-color: greenyellow;
    }
    @include media($desk){
      background-color: orange;
    }
    @include media($desk-wide){
      background-color: hotpink;
    }
  }
}


@if $test-sections == true {
  header,
  main,
  aside,
  footer,
  aside{
    background: $section-test;
  }
  
  h1,h2,h3,h4,h5,h6{
    background: $section-test-alt;
  }
}

