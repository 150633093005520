#site-footer {
  clear: both;
  background: $gray;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  .container{
    max-width: 100%;
    @include media($screen-lg){
      overflow: visible;
    @include outer-container();
}
  }

  .footer-top{
    padding: 0 1em;
    a,p{
      color: $white;
      font-size: 1.4rem;
    }
    .container{
      display: flex;
      justify-content: space-between;
      padding: 2em 0;
      flex-wrap: wrap;

      @include media($screen-md){
        padding: 2em 5em 2em 0;

      }
      #block-block-24{                            //CONTACT BLOCK
        display: inline-block;
        flex: 0 1 100%;

        @include media($screen-xs){
        }
        @include media($screen-sm){
          flex: 0 1 25%;
        }
      }
      #block-menu-menu-fbc-footer-menu{           //MAIN MENU ITEMS
        display: inline-block;
        @include clear-ul;
        flex: 0 1 100%;
        >ul{
          width: 100%;
          display: flex;
          justify-content: space-between;
          >li{
            width: 50%;
            display: inline-block;
            font-weight: normal;
            &.expanded{
              text-transform: uppercase;
              padding-bottom: 1em;
              ul{
                margin-top: 1em;
              }
              li{
                text-transform: none;
              }
            }
          }
        }
        .nolink{
          color: $white;
          font-size: 1.4rem;
        }

        @include media($screen-xs){
        }
        @include media($screen-sm){
          flex: 0 1 50%;

        }

      }


      .footer-head{
        margin-bottom: 1em;
        display: inline-block;
        color: $white;
        font-weight:normal;
        font-size: 1.4rem;
        text-transform: uppercase;
        line-height: 2.1rem;
        a {
          font-family: $base-font-family;
        }
      }
    }
  }


  .footer-bottom{
    background: $black;
    height: 100px;
    padding: 0 1em;
    text-align: center;
    @include media($screen-sm){
      height: 50px;

    }
    @include media($screen-lg){
      padding: 0;
    }
    .container{
      display: flex;
      justify-content:center;
      width: 100%;
      flex-wrap: wrap;
      @include media($screen-sm){
        flex-wrap: nowrap;
      }
      section{
        order: 2;
        width: 50%;
        @include media($screen-sm){
          width: 25%;

        }
        p{
          line-height: 50px;
          display: flex;
          justify-content: space-around;
          @include media($screen-sm){
            justify-content: flex-end;

          }

          a{
            display: inline-block;
            @include media($screen-sm){
              padding-left: 1em;
            }
          }
        }
      }
      div{
        width: 100%;
        line-height: 50px;
        color: $white;
        @include media($screen-md){
          width: 25%;

        }

      }
    }
  }
}

footer{
  .block-webform{
    h3{
      color: $white!important;
      font-size: 1.4rem!important;
      text-transform: uppercase!important;
      font-weight: normal;
      line-height: 1.4rem!important;
      //font-family: $base-font-family;
    }
  }
  .webform-client-form{
  background: transparent;
    .form-wrapper{
      padding: 0;
    }

    .webform-component--email-address {
    flex: 0 1 100%;
  }}
}