.video-embed-description{
  visibility: hidden;
  height: 0;
}
.field-name-field-video{

  iframe{
    width: 400px;
    margin: auto;
    height: 225px;
    @include media($screen-sm){
      width: 500px;
      margin: auto;
      height: 282px;
    }
    @include media($screen-md){
      width: 100%;
      margin: auto;
      height: 100%;
    }
  }
}

.not-logged-in {
  .field-name-field-videos {
    footer {
      display: none;
    }
  }
}