.view-display-id-attachment_1{    // Larger CTAs
    .view-content {
      background: none;
    }
    .view-header {
      @include outer-container;
      display: flex;
      justify-content: space-between;
      margin: 1em auto;
      padding: 2em 0;
      flex-wrap: wrap;

      @include media($screen-md){
        flex-wrap: nowrap;
      }
      .row {
        display: inline-block;
        padding: 1em;
        text-align: center;
        flex: 0 1 100%;

        @include media($screen-md){
          flex: 0 1 25%;
        }
      }
    }
  }


  .view-id-fbc_product_categories_front_page.view-display-id-block_7 {
    @include outer-container;
    display: flex;
    justify-content: space-between;
    margin: 1em auto;
    padding: 2em 0;
    flex-wrap: wrap;

    @include media($screen-md){
      flex-wrap: nowrap;
    }
    .views-row {
      display: inline-block;
      padding: 1em;
      text-align: center;
      flex: 0 1 100%;

      @include media($screen-md){
        flex: 0 1 25%;
        width: 24%;
      }
      a {
        .description h3 {
        color: $color-primary;
        }

        .description p {
          color: $base-font-color;
        }

        &:hover {
          text-decoration: none;
        }
      }

    }
  }