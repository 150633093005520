#block-views-exp-fbc-search-page-1 {
  z-index: 3;

  form{
    position: relative;
    display: block;
    height:100%;
    width: 100%;
    select{
      background: none;
      border: none;
    }
    .views-exposed-form{
      font-size: 0;
      padding-top: 0;
      display: block;
      margin-top: 0;
      height: 30px;
      padding: 10px 0;
    }
    .views-exposed-widgets{
      margin: 0;

      .views-exposed-widget{
        float: none;
        clear: both;

      }
    }


    .views-widget-filter-combine{
      label{
        display: none;
      }
      input {
        overflow: hidden;
        position: absolute;
        z-index: 1;
        color: $white;
        border: none;
        width: 100%;
        height: 20px;
        margin: 0;
        padding: 0;
        background: none;
        height: 30px;
        border-bottom: 1px dotted #fff;


        // transition: background-color .25s;
        // background-color: lighten($gray-dark, 5%);
        // &:hover,
        // &:active,
        // &:focus {
        //   color: transparentize($white, .3);
        //   background-color: lighten($gray-dark, 10%);
        //   transition: background-color .25s;
        // }

      }
      /* Change Autocomplete styles in Chrome*/
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus {
        padding: 0.5em;
        margin-right: 1em;
        -webkit-text-fill-color: $white;
        font-weight: bold;
        -webkit-box-shadow: 0 0 0px 1000px $gray inset;
        transition: background-color 5000s ease-in-out 0s;
      }
    }
    .views-submit-button{
      position: absolute;
      top: 0;
      // left: 0;
      right: 0;
      z-index: 3;

      input[type="submit"]{
        text-indent: 100px;
        background: none;
        margin: 0;
        padding: 0px;
        height: 30px;
        line-height: 30px;
        width: 40px;
        text-align: center;
        border-radius: 0;
        z-index: 2;


      }
      &:after{
        pointer-events: none;
        content: "\f002";
        font-family: FontAwesome;
        color: #fff;
        position: absolute;
        font-size: 20px;
        line-height: 20px;
        top: 13px;
        right: -17px;
        height: 40px;
        width: 40px;
        margin: auto;
        display: block;
        z-index: 3;

      }

    }
  }
}

::placeholder{
// ::-webkit-input-placeholder, /* Chrome/Opera/Safari */
// ::-moz-placeholder,  /* Firefox 19+ */
// :-ms-input-placeholder,  /* IE 10+ */
// :-moz-placeholder { /* Firefox 18- */
  color: $white!important;
  font-weight: bold;
}