#block-webform-client-block-5930{           //SUBSCRIBE WEBFORM BLOCK
  .container{
    padding: 0!important;
    position: relative;
  }
  display: inline-block;
  flex: 0 1 100%;
  color: $white;
  @include media($screen-xs){
    flex: 0 1 38%;
  }
  @include media($screen-sm){
    flex: 0 1 38%;

  }
  .form-item{
    margin: 0;
  }
  .form-actions{
    margin: 0;
    flex: none;
    width: 100%;
  }
  .webform-component-radios,
  .webform-component--email-address{
    >label{
      display: none;
    }
    .form-type-radio{

      label{
        font-size: 1.2rem;
        display: inline-block;
        width: 80%
      }
    }

  }

  .webform-component-radios{
    margin-bottom: 1em;
  }

  .form-required{
    display: none;
  }
  .field-name-title h2{
    color: $white;
    font-weight:normal;
    font-size: 1.4rem;
    text-transform: uppercase;
    line-height: 2.1rem;

  }
  .description{
    color: $white;
    font-size: 1.4rem;
    margin: 0 0 1em 0;
    line-height: 2.0rem;
    width: 80%;
  }
  .error {
    margin-top: 3em;
  }
  input[type="text"]{
    width: 100%;
    border: 1px solid $color-primary;
    border-radius: 4px;
    background: $gray;
    color: $white;
    height: 4rem;

    @include media($screen-md){
      width: 56%;

    }
    &::-webkit-input-placeholder{ /* Chrome/Opera/Safari */
      color: $white;
      font-size: 1.4rem;

    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $white;
      font-size: 1.4rem;

    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $white;
      font-size: 1.4rem;

    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $white;
      font-size: 1.4rem;

    }


  }
  input[type="radio"] {
    margin-top: -1em;
  }
  input[type="submit"]{
    height: 4rem;
    width: 100%;
    color: $color-primary;
    background-color: $white;
    border-radius: 4px;
    display: inline-block;
    //text-transform: uppercase;


    @include media($screen-md){
      width: 40%;
      position: absolute;
      top: 54px;
      right: 0;
      margin-bottom: 0;
      margin-top: 0;

    }
  }
}

#block-block-33 {
  display: inline-block;
  flex: 0 1 100%;
  color: $white;
  font-weight: normal;

  @include media($screen-xs){
    flex: 0 1 25%;
  }
  @include media($screen-sm){
    flex: 0 1 25%;
  }

  h3 {
    font-family: $base-font-family;
    color: $white;
    font-weight:normal;
    font-size: 1.4rem;
    text-transform: uppercase;
    line-height: 2.1rem;
  }
  a {
    padding: 1em 3em;
    background: white;
    color: $color-primary !important;
    border: 1px solid $color-primary;
    border-radius: 4px;
    font-weight: bold;
    &:hover,
    &:active {
      color: $white !important;
      background: $color-primary;

    }
  }
}
