
.view-fbc-product-category-views,
.view-fh-product-category-views,
.view-fbc-product-categories{
  //@include outer-container(100%);
  .view-header{
    padding: 0 1em;
    @include media($desk-wide){
      padding: 0;
    }
  }

  &.view-display-id-block_2{     // top section of category page
    @include flex-items;
    width: 100%;


    @include media($tablet-large){
      justify-content: space-between;

    }
    @include media($desk-wide){
      padding: 0;
    }
    .views-row{
      @include clearfix;
      @include outer-container(100%);

      .field-content{
        flex-wrap: wrap;
        @include media($tablet-large){
          flex-wrap: nowrap;
          height: 358px;
          overflow: hidden;
        }

        .teaser-image{
          display: inline-block;
          order: -1;
          flex: 0 1 100%;

          @include media($tablet-large){
            order: 2;
            width: 50%;

          }
          @include media($desk-wide){
            width: 59%;

          }


          .field-name-field-header-image{
            min-height: 100%;
            .field-items{
              height: 200px;
              position: relative;
              overflow: hidden;
              @include media($tablet-large){
                height: 400px;

              }
            }
            .field-item{
              line-height: 0;
              height: 100%;
              @include center-image;
              @include media($desk-wide){
                @include remove-center-image;
              }
              img{
                height: auto;
                @include media($desk-wide){
                  @include aspect-ratio(25,12);
                  min-width: 100%;
                }

              }
            }

          }

        }
        .teaser-content{
          display: inline-block;
          padding: 1em;
          background-color: $purple;
          position: relative;
          overflow: hidden;
          //@include purple-background;       //from background.scss
          order: 2;
          flex: 0 1 100%;

          @include media($tablet-large){
            width: 50%;
            order: -1;
            margin-right: .5em;



          }
          @include media($desk-wide){
            width: 40%;

          }


          h2{
            color: $teal-light;
            font-weight: 700;
          }
          p{
            color: $white;
          }

        }

      }
    }
    .attachment-after{
      .view-display-id-attachment_2{
        padding: 0 1em;

        @include media($desk-wide){
          padding: 0;
        }

      }
    }
  }

}

.view-fbc-products.view-display-id-block_3 { //comparison chart on category page
  @include outer-container();

  margin-bottom: 3em;
  .view-header{
    padding: 0;
  }
  .view-content{
    overflow-x: auto;

    table{
      padding-top: 100px;
    }
    th{
      position: relative;
      img{
        position: absolute;
        top: -110px;
        max-height: 100px;
        width: auto;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
      .new {

        &:after {
          position: absolute;
          content: '';
          top: -151px;
          left: 0;
          height: 130px;
          width: 130px;
          background: url('../images/icon-new-no-tab.png') no-repeat;
          -webkit-background-size:50%;
          background-size: 50%;
          -webkit-transform: rotate(-90deg);
          -moz-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
          -o-transform: rotate(-90deg);
          transform: rotate(-90deg);

        }
      }
    }
    th,
    td{
      text-align: center;
      width: 100px;
      @include media($screen-md){
        width: auto;
      }
      &:first-child{
        width: 180px;
        text-align: left;
        @include media($screen-md){
          width: auto;
        }
      }
    }
  }

}

.pane-fh-product-category-views{
  @include outer-container();
  &>.field-items{
    display: flex;
    justify-content: space-between;
    width: 100%;
    .field-items{
      flex: 0 1 33.3%;


    }
  }


}

.view-fbc-products{
  &.view-display-id-block_1{
    .view-content{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .views-row{
        flex: 0 1 50%;
        //height: 50px;
        display: inline-block;
        img{
          display: inline-block;
          max-height: 100%;
          width: auto;
        }
        a{
          display: inline-block;
          vertical-align: top;
          line-height: 50px;
        }
      }
    }
  }

}

.field-name-ds-products-not-featured-list{
  padding: 1em 0;
  @include extended-background($gray-lightest);
  .views-row{
    padding: 1em 0;
  }


  .product-info{
    display: flex;
    .image{
      display: inline-block;
      flex: 0 1 25%;
    }
    .text{display: inline-block;
      flex: 0 1 75%;
    }
  }
}

.field-name-ds-products-discontinued-list{
  padding: 1em 0;
  .views-row{
    padding: 1em 0;
  }

  .views-row-even{
    background-color: transparentize($gray-lightest, .5);
  }
  .product-info{
    display: flex;
    .image{
      display: inline-block;
      flex: 0 1 20%;
    }
    .text{display: inline-block;
      flex: 0 1 65%;
    }
    .resources{
      display: inline-block;
      flex: 0 1 15%;
    }
  }
}