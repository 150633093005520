.calibration-only{
  color: rgb(244, 158, 66);
}
.limited-service{
  color: rgb(229, 244, 66);
}

.full-service{
  color: rgb(95, 244, 66);
}
.not-serviced{
  color: rgb(244, 66, 66);
}