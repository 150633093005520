.paragraphs-item-application-recommended-products {
  .app-products-section {
    .app-recommended-products {
      display: flex;
      flex-wrap: wrap;
      @include media($screen-md) {
        flex-wrap: nowrap;
      }
      .app-products-learn-more {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: transparentize(black, 0.97);
        padding: 1em;
        margin: auto;
        @include media($screen-md) {
          width: 30%;
          padding: 2em;
        }

        a {
          @include button;
          color: $white;
        }
      }
    }

    .field-name-field-app-related-products {
      width: 100%;
      @include media($screen-md) {
        width: 70%;
        margin-right: 2em;
      }
      .field-items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include media($screen-md) {
          flex-wrap: nowrap;
        }
        .field-item {
          width: 30%;

          .card-content {
            flex-direction: column;
            align-items: center;
            .card-image {
              .field-item {
                width: 100%;
                img {
                  max-height: 100px;
                  @include media($screen-md) {
                    max-height: 150px;
                  }
                }
              }
            }
            .card-text {
              .field-name-field-h1-title {
                .field-item {
                  width: 100%;
                  text-align: center;
                  h3 {
                    font-size: 1.4rem;
                    @include media($screen-md) {
                      font-size: 1.8rem;
                    }
                  }
                }
              }
              .field-name-field-preview-summary {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.node-type-application-2021 {
  #cboxLoadedContent {
    label,
    input {
      font-size: 1.25rem;

    }
    form input[type="email"], 
    form input[type="password"], 
    form input[type="tel"], 
    form input[type="text"], form input[type="time"], form input[type="url"], form textarea, form select {
      font-size: 1.25rem;
      height: 30px;
      padding: 0.25em;
    }

    .form-item select {
      padding: 0 0 0 .5em !important;
      background-position: right 0.5em center;
      max-width: calc(100% - 0.5em);
    }
  }
}
