@mixin colored-svg($image-path, $color: $color-primary, $mask-size: 80%){
  mask-image: $image-path;
  -webkit-mask-image: $image-path;
  background-color: $color;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-position: center;
  mask-size: $mask-size * .75;
  -webkit-mask-size: $mask-size * .75;
  z-index: 1;

  @include media($screen-sm){
    mask-size: $mask-size;
    -webkit-mask-size: $mask-size;
  }

  @include media($screen-md){
    mask-size: $mask-size;
    -webkit-mask-size: $mask-size;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
    mask-image: none;
    background-color: transparent;
    mask-repeat: no-repeat;
    background: $image-path;
    background-repeat: no-repeat;
  }
  @supports (-ms-ime-align: auto) {
    mask-image: none;
    background-color: transparent;
    mask-repeat: no-repeat;
    background: $image-path;
    background-repeat: no-repeat;

  }
}