@mixin clear-table{
  table{
    tr{
      border: none;
      background: none;

      th{
        border: none;
        background: $gray-lightest;
        color: $base-font-color;
      }
      td{
        background: none;
        border: none;

        &:first-child{
          font-weight:700;
        }
      }
    }
  }
}