.node-type-landing-page{
  $landing-primary-color: #4badcc;
  $landing-primary-darker: #46ADCE;
  $landing-primary-lighter: #EEFAFE;
  $landing-background: #EBF0F5;

  .messages.error {
    margin-top: 60px;
  }

    .button {
      border-radius: 3px;
      padding: 0.5em 2em;
    }
  .fluke-biomedical {
    border-bottom: 20px solid $gray-darkest;
    .landing-logo {
      background: $gray-dark;
    }
    .field-name-field-cta-link a {
      background-color: $fbc-blue;
      color: $white;
      &:hover,
      &:active {
        text-decoration: none;
        background-color: darken($color: $fbc-blue, $amount: 10%);
      }
    }
    a {
      color: $fbc-blue;
    }
    .button {
      background-color: $fbc-blue;
      color: $white;

      &:hover,
      &:active {
        text-decoration: none;
        background-color: darken($color: $fbc-blue, $amount: 10%);
      }
    }
  }
  .raysafe {
    .landing-logo {
      background:transparentize($color: #ffffff, $amount: 1);
    }
    .field-name-field-cta-link a {
      background-color: $raysafe-blue;
      color: $white;
      &:hover,
      &:active {
        text-decoration: none;
        background-color: darken($color: $raysafe-blue, $amount: 10%);
      }
    }
    a {
      color: $raysafe-blue;
    }
    .button {
      background-color: $raysafe-blue;
      color: $white;
      &:hover,
      &:active {
        text-decoration: none;
        background-color: darken($color: $raysafe-blue, $amount: 10%);
      }
    }

  }


  //background: $landing-background;
  *:not(a){
    color: $landing-font-color;
    font-family: $font-proxima-nova-regular;

  }

  &.role-authenticated-user {
    .main-container {
      .landing-logo{
        position: relative;
      }
    }

  }
  .roboto {
    font-family: "Roboto Slab";
  }
  .extra-strong{
    font-family: $font-proxima-nova-bold;
    font-size: 2rem;
  }

  a.button-secondary{
    font-size: 1.4rem;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    background: $landing-primary-color;
    border: 1px solid lighten($landing-primary-color, .75);
    &:hover{
      background: $landing-primary-darker;
      border-color: #122b40;
    }

    &:active:focus, &:focus {
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }
  }

  blockquote,
  .testimonial{
    border-left: 5px solid $landing-background;
    padding-left: 1em;
  }

  .field-name-ds-attribution {
    @include outer-container;
  }

  .main-container{
    margin-top: 0;

    .node-landing-page{
      // background: $white;
      max-width: 100%;
      overflow: hidden;
    }

    .landing-logo {
      max-width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 60px;
      z-index: 3;
      @include media($screen-lg) {
        max-width: 1260px;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: -100%;
          right: -100%;
          background: $gray-dark;
          height: 60px;
          z-index: -1;
      }
    }
  }
  .field-name-field-product-brand {
    float: left;
    margin: 0.5em 1em;
    @include media($screen-md) {
      margin: 0.5em 3em 0.5em 1em;
    }

    @include media($screen-lg) {
      margin: 0.5em 3em 0.5em 0;
    }

    .field-items {
      display: flex;
      align-items: center;
    }

    .field-item {
      display: inline-block;
      padding-right: 1rem;
    }
  
      .field-name-field-logo{

        .field-items {
          height: 100%;

          .field-item{
            height: 100%;

            img{
              max-height: 40px;
              margin: auto 0;
              width: auto;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .field-name-field-cta-link {
    margin-top: 1em;
  }
  .paragraphs-items-field-page-sections {
    @include outer-container();

    .field-name-field-page-sections {
      .entity-paragraphs-item {
        padding-top: 1em;
        padding-bottom: 1em;
        @include media($screen-md) {
          padding-top: 2em;
          padding-bottom: 2em;
        }
      }

      .paragraphs-item-text-with-image {
        .content-section {
          .field-name-field-section-body {
            display: flex;
              flex-direction: column;
              justify-content: center;
              height: 100%;
          }

        }
      }
    }
  }

  .node-webform{


    .field-name-title{
      background: $gray;
      h2{
        margin: 0;
        padding: 1em;
        color: $white;
        text-align: center;
      }
    }

  }

  .landing-page-footer{
    margin-top: 5em;
    .footer-separator{
      max-height: 80px;
      position: relative;
      @include media($screen-md) {
        max-height: 140px;
      }

      &:after{
        content: '';
        position: absolute;
        top: 48%;
        bottom: 50%;
        left: 0;
        right: 0;
        width: 100%;
        background-color: $color-pop;
        height: 2px;
        z-index: 0;
      }
      img{
        position: relative;
        max-height: 70px;
        width: auto;
        z-index: 2;
        @include media($screen-md) {
          max-height: 100%;

        }

      }
      div{
        padding: 0 0 0 10%;
        margin: 0;
        height: 100%;
        @include outer-container();
      }

    }

    .footer-information-social{
     @include outer-container();
      @include media($screen-md) {
        display: flex;
        padding: 2em 0 1em;
      }


      .information{
        padding: 0 2vw;

        @include media($screen-md){
          padding: 0;
          display: inline-block;
          flex: 0 1 75%;
          width: 75%;
        }
        p{
          padding: 0;
          margin-bottom: 1em;
          @include media($screen-md){
            margin: 0;
            line-height: 40px;

          }
        }
      }

      .social{
        padding: 0 2vw;

        @include media($screen-md){
          padding: 0;
          display: flex;
          flex: 0 1 25%;
          width: 25%;
          justify-content: flex-end;
        }

        .taxonomy-term,
        .field-name-field-social-media-links{
          display: inline-block;
          .field-items {
            display: flex;
          }
        }
        a {
          @extend %social-boxes;
          margin-bottom: 0;
        }
      }
    }
    .footer-links-copyright-wrapper{
      background: $gray-lightest;
      padding: 1em;
      font-size: 1.4rem;
      .footer-links-copyright{
        @include outer-container();
        width: 100%;
        @include media($screen-md){
          display: flex;
          justify-content: space-between;

        }

      }
      .links {
        margin-bottom: 1em;
        @include media($screen-md) {
          margin-bottom: 0;
          display: inline-block;
          width: 65%;
          .field-name-field-footer-links {
            .field-items {
              display: flex;
              .field-item {
                display: inline-block;
                border-right: 1px solid $gray-light;
                margin-right: 1em;
                padding-right: 1em;
                &:last-child{
                  border-right: none;
                }
              }
            }
          }

        }
        a{
          color: $base-font-color;


        }
      }
      .copyright{
        text-align: left;

        @include media($screen-md){
          text-align: right;
          display: inline-block;
          width: 35%;
        }

      }
    }
    .sub-footer{
      @include outer-container();
      padding: 2em 0;
    }
  }

  .view-mode-banner_with_image_and_text {
    .foreground-section {
      .text-area {
        flex-direction: column;
        margin: auto;
        min-height: auto;

        >.field {
          width: 100%;
          padding-bottom: 0.5em;
        }
        .field-name-field-related-logo {
          max-height: 100px;
          img {
            max-height: 100px;
            width: auto;
          }
        }
      }
    }
  }
  .shopify-buy-frame {
    display: none;
  }
}

.field-name-field-section-image-caption {
  padding-top: 0.5em;
  .field-item {
    font-size: 1em;
    font-style: italic;
  }
}
