fieldset {
  margin: 0 0 $small-spacing;
  padding: $base-spacing;
}

input,
label,
select {
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size;

}

form {
  .form-wrapper{
    padding: 1em;
  }
  label, input {
    padding: 0;
    margin: 0;
    line-height: 1;
    display: block;


  }
  input[type="email"],
  input[type="password"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  textarea,
  select {
    background: $gray-lightest;
    border: 1px solid $gray-lighter;
    height: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border-radius: 0;
    padding: .5em;
    width: 100%;
    line-height: 36px;
    margin-bottom: .5em;

    &:hover{
      cursor: pointer;
    }
  }

  select {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
    background-origin: content-box;
    background-position: right -1.75rem center;
    background-repeat: no-repeat;
    background-size: 9px 6px;
  }

  input[type="submit"] {
    font-size: 1.6rem;
    background: $color-pop;
    border: none;
    box-shadow: none;
    color: $base-font-color;
  }

  br {
    height: 0;
  }


}

select{
  padding: 0 .5em;
  margin-bottom: $base-spacing;
  max-width: 100%;
  width: auto;
}
select::-ms-expand {
  display: none;
}

label {
  font-weight: 600;
  margin-bottom: $small-spacing / 2;

  &.required::after {
    content: "*";
  }

  abbr {
    display: none;
  }
}

#{$all-text-inputs},
select[multiple=multiple],
textarea {
  background-color: $base-background-color;
  box-sizing: border-box;
  font-family: $base-font-family;
  font-size: $base-font-size;
  margin-bottom: $base-spacing / 2;
  padding: $base-spacing / 3;
  transition: border-color;
  width: 100%;

  &:focus {
    border-color: $action-color;
    outline: none;

  }
}

textarea {
  resize: vertical;
}

input[type="search"] {
  @include appearance(none);
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin-right: $small-spacing / 2;
}

input[type="file"] {
  padding-bottom: $small-spacing;
  width: 100%;
}

#cboxLoadedContent{
  background: white;
  padding: 1em;

  form{
    max-width: 96%;
  }
}

.form-item{
  select{
    padding: 0 1.5em 0 .5em !important;
    &.error{
      border: 2px solid $color-primary;
    }
  }
}

.views-exposed-form{
  .views-exposed-widget{
    .form-submit{
      margin-top: 0;
    }
  }
}

/* Overrides for Contact Sales form. */
form.webform-client-form-16 {
  input.form-text,
  select {
    background-color: $white;
  }
}
