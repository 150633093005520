.page-search{

  .attachment-before{
    text-align: right;
    margin-bottom: 1em;
    @include media($screen-md){
      margin-bottom: -1em;
    }
  }

  .main-container{
    padding: 0 1em;


    @include media($screen-lg){
      padding: 0;
      margin-top: 3em;
    }
  }

  h1{
        height: 100px;
        @include extended-background(white);
        padding-top: 1em;
      }

  .quicktabs-wrapper{
    .item-list{
      margin-bottom: 2em;
      ul{
        border-bottom: 1px solid $gray-lighter;
        padding-bottom: 2px;
        li{
          padding: 0 1em 1px 1em;
          a{
            color: $color-primary;
            font-weight: 700;
            &:hover,
            &:active,
            &:focus{
              text-decoration: none;
            }
          }

        &.active {
          border-bottom: 3px solid $color-primary;
          a {
            color: $base-font-color;

          }
        }
        }
      }
    }
  }
  .quicktabs-views-group{
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: 1px solid $gray-lightest;
    font-size: 1.4rem;
  }

  .views-field-title{
      font-weight: 700;
      font-size: 1.6rem;

  }

  .main-container-wrapper{
    @include outer-container();
    section{
      display: flex;
      width: 100%;
      .view-fbc-search{
        width: 100%;
      }
      &.block-facetapi{
        flex: 0 1 15%;
        margin-top: calc(100px + 2em);

      }

      .search-results{
        article{
          margin-bottom: 1em;
        }
      }

    }
  }
}