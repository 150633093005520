.node-type-landing-page {
  #block-menu-menu-oneqa-navigation,
  .field-name-one-qa-menu-block,
  .field-name-oneqa-navigation {
    display: none;
    @include media($screen-md) {
      display: inline-block;
      z-index: 2;
      width: 80%;
    }

    ul.menu:first-of-type {
      display: flex;
    }

    ul.menu {
      // Top level
      position: relative;
      justify-content: flex-start;
      @include clear-ul;
      padding-left: 0;
      margin: 0;
      background: none;
      z-index: 3;

      > li {
        display: inline-block;
        position: relative;
        color: $white;
        line-height: 60px;
        font-weight: bold;
        white-space: nowrap;
        padding: 0;
        font-size: 1.5rem;
        margin-right: 3em;
        
        @include media($screen-lg) {
          font-size: $base-font-size;
        }

        &:hover {
          cursor: pointer;
          color: $fluke-yellow;
          > ul.menu {
            display: flex;
          }
        }

        a {
          color: $white;
        }
        ul {
          // Applies to all levels below top level.
          background: transparentize($gray-dark, 0.1);
          min-width: 180px;
          display: flex;
          z-index: 3;
          flex-direction: column;
          border-left: 1px solid transparentize($color: #000000, $amount: 0);

          li {
            line-height: 30px;
            position: relative;
            padding: 0.5em 3em 0.5em 1em;
            border-bottom: 1px solid transparentize($color: white, $amount: 0.8);
            width: 180px;

            &:last-child {
              border-bottom: none;
            }

            &:hover {
              background: transparentize($white, 0.95);
              a {
                font-weight: 700;
              }
            }

            .nolink {
              color: $color-pop;
              font-weight: bold;
              font-size: 1.4rem;
            }
            a {
              color: $white;
              font-weight: 500;
              &:hover {
                text-decoration: none;
              }
            }

            &.expanded {
              &:after {
                content: "▸";
                position: absolute;
                right: 1em;
              }
            }
          }
        }
        > ul.menu {
          // Second level
          display: none;
          position: absolute;
          top: 60px;
          left: -15px;
          &:hover ul {
            display: block;
          }

          > li {
            display: inline-block;

            &:hover {
              > ul.menu {
                display: flex;
              }
            }

            > ul.menu {
              // third level
              display: none;
              top: 0;
              min-width: 180px;
              margin: 0;
              padding: 0;
              position: absolute;
              left: 180px;
              top: 0;
              height: auto;
              columns: 1;
              position: absolute;
              top: 0;
              right: 0;

              li {
                display: block;
                text-transform: none;
              }
            }
          }
        }
      }
    }
  }
}

.node-type-landing-page {
  #mmenu {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;

    @include media($screen-md) {
      display: none;
    }
  }

  $nav_bg: $gray-dark;
  $nav_bg_linear_gradient_top: $gray-dark;
  $nav_bg_linear_gradient_bottom: $gray-darkest;
  $nav_font_size: 12px;
  $nav_color: $white;
  $nav_padding_side: 20px;
  $nav_li_border_top: transparent;
  $nav_li_border_bottom: transparent;
  $nav_text_shadow: 0 1px transparent;

  $header_color: $nav_color;
  $header_height: 60px;
  $header_font_size: 2em;
  $header_title_width: 80%;
  $header_arrow_color: $nav_color;
  $header_arrow_width: 10%;
  $header_arrow_icon_width: 10px;

  $footer_bg: $nav_bg;
  $footer_color: $nav_color;
  $footer_font_size: 1.25em;
  $footer_height: 40px;

  $search_height: 50px;
  $counter_color: $nav_color;

  @mixin mmenu-linear-gradient($from, $to) {
    /* Old browsers */
    background: $from;
    /* FF3.6+ */
    background: -moz-linear-gradient(top, $from 0%, $to 100%);
    /* Chrome,Safari4+ */
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, $from),
      color-stop(100%, $to)
    );
    /* Chrome10+,Safari5.1+ */
    background: -webkit-linear-gradient(top, $from 0%, $to 100%);
    /* Opera 11.10+ */
    background: -o-linear-gradient(top, $from 0%, $to 100%);
    /* IE10+ */
    background: -ms-linear-gradient(top, $from 0%, $to 100%);
    /* W3C */
    background: linear-gradient(to bottom, $from 0%, $to 100%);
    /* IE6-9 */
    filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=0 )";
  }

  @mixin mmenu-opacity($value) {
    $IEValue: $value * 100;
    opacity: $value;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $IEValue +
      ")";
    filter: alpha(opacity=$IEValue);
  }

  body {
    -webkit-user-select: initial !important;
    -moz-user-select: initial !important;
    -ms-user-select: initial !important;
    -o-user-select: initial !important;
    user-select: initial !important;
  }

  /* Blocker */
  #mm-blocker {
    background: #000 !important;
    @include mmenu-opacity(0.5);
  }

  .mmenu-nav {
    z-index: 10000;
    background: $nav_bg;
    color: $nav_color;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.6);
    // font-family: Helvetica, sans-serif;
    font-size: $nav_font_size;
    box-shadow: 0 0 0px rgba(0, 0, 0, 0.5) !important;
    text-shadow: $nav_text_shadow;

    /* Icons */
    [class^="icon-"],
    [class*=" icon-"],
    .mmenu-block-icon {
      display: none;
      vertical-align: middle;
      font-style: normal;
      padding-right: 15px;
    }

    .mmenu-block-title {
      display: table-cell;
      color: transparentize($color: $white, $amount: 0.1);
    }

    /* Header */
    > .mm-header {
      border-color: $nav_li_border_bottom;
      color: $header_color;
      height: $header_height;
      line-height: $header_height;
      @include mmenu-linear-gradient(
        $nav_bg_linear_gradient_top,
        $nav_bg_linear_gradient_bottom
      );

      .mm-title {
        font-size: $header_font_size;
        padding: 0;
        width: $header_title_width;
        text-align: left;
      }

      .mm-prev {
        padding: 0;
        left: $nav_padding_side;
        right: ($header_title_width + $header_arrow_width);
        top: 0;
        bottom: 0;
        width: auto;

        &:before {
          margin: 0;
          width: $header_arrow_icon_width;
          height: $header_arrow_icon_width;
          z-index: 10;
          border-color: $header_arrow_color;
        }
      }

      .mm-next {
        padding: 0;
        left: ($header_title_width + $header_arrow_width);
        right: $nav_padding_side;
        top: 0;
        bottom: 0;
        width: auto;

        &:before {
          margin: 0;
          width: $header_arrow_icon_width;
          height: $header_arrow_icon_width;
          z-index: 10;
          border-color: $header_arrow_color;
        }
      }
    }

    /* List or Panel */
    > .mm-list {
      padding-top: 0;
      padding-bottom: 0;

      li {
          border: 1px solid $nav_li_border_top;
        border-top: 1px solid $nav_li_border_top;
        border-bottom: 1px solid transparentize($color: $white, $amount: .9);

        > a {
          &:not(.mm-subopen):not(.mm-subclose):not(.mm-fullsubopen) {
            // padding: 10px 20px;
            padding: 0.5em 1em;
            white-space: normal;
          }
          &.mm-subclose {
              color: transparentize($color: $white, $amount: .5);
              font-weight: 700;
          }
        }

        &:after {
          margin-left: 0 !important;
          border-color: rgba(0, 0, 0, 0);
        }

        &.mm-selected > a,
        &.mm-selected > span {
          background: inherit;
        }

        &.mmenu-expanded {
          .mmenu-block-collapsed {
            padding: 15px 20px !important;
            font-size: 1.25em;
          }

          .mmenu-block-expanded {
            padding: 5px 10px 5px 10px !important;
            @include mmenu-linear-gradient(
              $nav_bg_linear_gradient_top,
              $nav_bg_linear_gradient_bottom
            );
          }
        }
      }

      > .mm-list {
        margin: 0;
        padding: 0;
      }
    }

    /* Counter */
    em.mm-counter {
      color: $counter_color;
      right: 35px;
    }
    .mm-subopen,
    .mm-subclose {
      padding-left: 0;
      color: white;

      &:before {
        border-left: none;
      }
      &:after {
        border-color: $counter_color !important;
        width: 8px;
        height: 8px;
      }
    }
    em.mm-counter + a.mm-subopen + a,
    em.mm-counter + a.mm-subopen + span {
      margin-right: 65px;
      padding: 10px $nav_padding_side;
    }

    &.mm-vertical {
      li.mm-opened {
        > ul {
          padding: 0 0 0 ($nav_padding_side + 10);
          background: rgba(255, 255, 255, 0.05);

          li {
            &:first-child {
              border-top: none;
            }
            &:last-child {
              border-bottom: none;
            }
          }
        }
        > a.mm-subopen {
          background: rgba(255, 255, 255, 0.05);
          border-bottom: none;
          width: 40px;
        }
      }
    }
  }
}
