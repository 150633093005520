.paragraphs-items-field-category-intro-content {
  @include extended-background($gray-lightest);
  padding: 1em 0;

}

.entity-paragraphs-item.paragraphs-item-banner-marketing {
  &.has-background {
    padding: 0;
  }

  .promo-banner {
    @include outer-container;
    line-height: 0;
  }
  img {
    object-fit: contain;
    width: 100%;
  }
}