//@mixin hexagon(){
//    position: relative;
//    width: 300px;
//    height: 173.21px;
//    background-color: $teal;
//    margin: 86.60px 0;
//
//  &:before,
//  &:after{
//    content: "";
//    position: absolute;
//    width: 0;
//    border-left: 150px solid transparent;
//    border-right: 150px solid transparent;
//  }
//
//  &:before {
//    bottom: 100%;
//    border-bottom: 86.60px solid $teal;
//  }
//
//  &:after {
//    top: 100%;
//    width: 0;
//    border-top: 86.60px solid $teal;
//  }
//}



@mixin solid-hexagon($width: 600px, $height: $width * .55, $sides: $width * .5, $sides-color: $width * .25){
    width: $width;
    height: $height;
    background: red;
    position: relative;
  &:before {
    content: "";
    position: absolute;
    top: -$sides-color;
    left: 0;
    width: 0;
    height: 0;
    border-left: $sides solid transparent;
    border-right: $sides solid transparent;
    border-bottom: $sides-color solid red;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: -$sides-color;
    left: 0;
    width: 0;
    height: 0;
    border-left: $sides solid transparent;
    border-right: $sides solid transparent;
    border-top: $sides-color solid red;
  }
}

@mixin single-line-hexagon($color: $blue, $width: 400px, $height: $width * 1.25, $points: $width/6*2, $offset: $width/6){
  width: $width;
  height: $height;
  position: relative;
  background: transparent;
  border-top: 4px solid $color;
  border-bottom: 4px solid $color;
  &:before {
    content: "";
    position: absolute;
    margin: auto;
    top: 171.33333px;
    left: -73.66667px;
    width: 150px;
    height: 150px;
    transform: scaleX(2.125) rotate(225deg);
    -webkit-transform: scaleY(2.36) rotate(-225deg);
    -moz-transform: scaleX(2.125) rotate(-135deg);
    -o-transform: scaleX(2.125) rotate(-135deg);
    -ms-transform: scaleX(2.125) rotate(-135deg);
    border-right: 3px solid $color;
    border-bottom: 3px solid $color;


  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    margin: auto;
    bottom: 170px;
    right: -$width;
    width: 150px;
    height: 150px;
    transform: scaleX(2.125) rotate(45deg);
    -webkit-transform: scaleY(2.36) rotate(-45deg);  // THIS IS THE ONE for chrome
    -moz-transform: scaleX(2.125) rotate(45deg);
    -o-transform: scaleX(2.125) rotate(45deg);
    -ms-transform: scaleX(2.125) rotate(45deg);
    border-right: 3px solid $color;
    border-bottom: 3px solid $color;
  }
}


@mixin double-line-hexagon($color: $blue, $width: 500px, $height: $width * .55, $points: $width/6*2, $offset: $width/6){


  width: $width;
  height: $height;
  position: relative;
  background: $white;
  //border-left: 4px solid $color;
  //border-right: 4px solid $color;
  box-shadow:
          0 0 0 2px #000,
          0 0 0 3px #999,
          0 0 0 9px #fa0,
          0 0 0 10px #666,
          0 0 0 16px #fd0,
          0 0 0 18px #000;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    margin: auto;
    top: -$offset;
    right: 0;
    width: $points;
    height: $points;
    overflow: hidden;

    transform: scaleX(2.125) rotate(-130deg);
    -webkit-transform: scaleX(2.125) rotate(-135deg);  // THIS IS THE ONE for chrome
    -moz-transform: scaleX(2.125) rotate(-135deg);
    -o-transform: scaleX(2.125) rotate(-135deg);
    -ms-transform: scaleX(2.125) rotate(-135deg);
    //border-right: 3px solid $color;
    //border-bottom: 3px solid $color;
    box-shadow:
            0 0 0 2px #000,
            0 0 0 3px #999,
            0 0 0 9px #fa0,
            0 0 0 10px #666,
            0 0 0 16px #fd0,
            0 0 0 18px #000;
    z-index: -1;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    margin: auto;
    bottom: -$offset;
    right: 0;
    width: $points;
    height: $points;
    transform: scaleX(2.125) rotate(45deg);
    -webkit-transform: scaleX(2.125) rotate(45deg);  // THIS IS THE ONE for chrome
    -moz-transform: scaleX(2.125) rotate(45deg);
    -o-transform: scaleX(2.125) rotate(45deg);
    -ms-transform: scaleX(2.125) rotate(45deg);
    //border-right: 3px solid $color;
    //border-bottom: 3px solid $color;
    box-shadow:
            0 0 0 2px #000,
            0 0 0 3px #999,
            0 0 0 9px #fa0,
            0 0 0 10px #666,
            0 0 0 16px #fd0,
            0 0 0 18px #000;
    z-index: -1
  }
}