
.field-name-field-preview-summary{
  margin-bottom: 1rem;
  .page-taxonomy-term & {
    font-weight: bold;

  }
}

.field-name-field-preview-features{
  .field-items{
    .field-item{
      display: list-item;
      margin-left: 2em;
    }
  }
}