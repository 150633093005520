footer {
  .webform-client-form-16,
  .fhs-forms-iframe {
    height: 0;
    display: none !important;
  }
}

.webform-client-form-16,
.fhs-forms-iframe {
  .webform-component--header,
  .webform-component--footer {
    display: none;
  }
}

.page-taxonomy-term {
  #cboxLoadedContent form {
    max-width: 100%;
    .form-wrapper {
      padding: 1em;
      //padding-top: 125px;
    }
  }
  .view-mode-colorbox {
    .messages.error {
    }

    .webform-component--header {
      display: block;
      height: 80px;
      margin-bottom: 1em;
      //position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background: $white;
      padding: 1em;

      a {
        display: block;
        max-height: 80px;
        position: relative;

        &:after {
          content: '';
          background: $color-pop;
          height: 2px;
          position: absolute;
          top: 50%;
          bottom: 50%;
          left: -1em;
          right: -1em;
          z-index: 0;
        }

        img {
          position: relative;
          max-height: 80px;
          z-index: 1;
        }
      }
    }

    .webform-component--footer {
      display: block;
      background: $gray-lighter;
      padding: 1em;
      //position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      order: 10;
      p {
        margin: 0;
      }
    }

    .webform-component--intro {
      display: none;
    }

    .field-name-title,
    .fhs-forms-iframe-btn {
      display: none !important;
    }

    .webform-client-form {
      background: none !important;

      label {
        font-size: 1.5rem;
        padding-bottom: 2px;
      }
      input {
        height: 30px;
        &.webform-submit {
          height: auto;
          margin-bottom: 3em;
        }
      }
      select {
        height: 32px;
        padding: 0 !important;
      }

      .webform-component--country {
        width: 90%;
      }
      .webform-component--i-would-like-to {
        margin-bottom: 0 !important;
        margin-top: 1em;
      }
      .webform-component--contact-me,
      .webform-component--i-consent,
      .webform-component--after-submitting {
        //max-width: 65%;
        margin-top: 1em;
      }

      .form-actions {
        margin-top: 1em;
        //max-width: 30%;
        //flex: auto;
        //display: inline-block;
        text-align: right;
      }
    }
  }
}

// Contact Sales block.
#block-webform-client-block-16,
#block-block-42 {
  position: absolute;
  right: 3em;
  width: auto;
  top: 4px;
  z-index: 10;
  @include media($screen-md) {
    position: absolute;
    right: 1em;
  }

  @include media($screen-lg) {
    position: absolute;
    right: 0;
  }

  .node-webform {
    position: relative;
    z-index: 2;
  }

  .webform-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparentize($color: #000000, $amount: 0.25);
    z-index: 1;
  }

  .webform-component,
  .form-actions {
    z-index: 2;
  }

  .field-name-title,
  .fhs-forms-iframe-btn {
    padding: 2px 0.75em;
    height: 22px;
    margin-top: 17px;
    @include media($screen-md) {
      margin-top: 12px;
      padding: 2px 1em;
      height: 30px;
      width: 140px;
    }

    h3 {
      font-size: 1.4rem;
      line-height: 18px;
      @include media($screen-md) {
        font-size: 1.7rem;
        font-weight: 700;
        line-height: 28px;
      }
    }

    &.active {
      border-radius: 0;
      height: 61px;
      margin-top: -4px;
      padding: 24px 2em;
      transition: all 0.25s;
      position: relative;
      z-index: 2;
      @include media($screen-md) {
        padding: 18px 1em;
      }
    }
  }

  form {
    background: white;
    width: 100vw;
    &.active {
      height: auto;
    }

    @include media($screen-md) {
      max-width: 500px;
    }
  }

  iframe {
    width: 80vw;
    @include media($screen-md) {
      max-width: 600px;
      width: 100vw;
    }
  }

  iframe,
  form {
    position: absolute;
    top: 57px;
    right: -51px;
    text-align: left;
    z-index: 10;
    @include media($screen-md) {
      right: 0;
    }

    .form-wrapper {
      padding: 2em;
      @include media($screen-md) {
        padding: 1em;
      }
    }

    &.inactive {
      height: 0;
      box-shadow: none;
      transition: height 0.25s;
      overflow: hidden;
      &.webform-share-iframe {
        display: none;
      }
    }

    &.active {
      transition: height 0.5s;
      z-index: 2;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      }
    }
  }

  .webform-confirmation {
    position: absolute;
    top: 57px;
    right: -51px;
    width: 100vw;
    background: white;
    text-align: left;
    height: 280px;
    padding: 2em;
    @include media($screen-md) {
      right: 0;
      max-width: 500px;
    }

    h2,
    h3,
    p {
      line-height: 1;
      height: auto;
    }
  }

  .links {
    display: none;
  }

  .close-button,
  .cancel-button {
    position: absolute;
    right: 1em;
    left: auto;
    bottom: 0;
    background-color: #cccccc;
    padding: 0.75rem;
    font-size: 1.6rem;
  }
}

fieldset.captcha {
  border: none;
  max-width: 400px;
  margin: auto;
  font-size: 1.5rem;
  legend {
    display: none;
  }
}
.g-recaptcha {
  & > div {
    margin: auto;
    margin-top: 1em;
  }
}
#webform-ajax-wrapper-16--2 {
  .webform-confirmation {
    padding: 2em;
  }
  .links {
    display: none;
  }
}

.webform-component--i-consent {
  position: relative;
  margin-top: 1em;
  label {
    display: block;
    margin-left: 1.3em;
    margin-bottom: 0.5em;
    font-weight: 600;
  }

  input {
    position: absolute;
    top: 1px;
    left: 0;
  }
  .description {
    font-size: 1.3rem;
  }
}

.webform-component--after-submitting {
  p {
    padding-left: 22px;
    font-size: 1.4rem;
    display: inline-block;
    width: 90%;
    line-height: 1;
    margin-top: 1em;
  }
}

#block-webform-client-block-16,
#block-block-42 {
  .webform-component--what-do-you-like-to-do-for-fun {
    display: none !important;
  }
}

.grecaptcha-badge {
  display: none;
}
