.page-node-19533 {
  .body-wrapper {
    position: relative;

    .field-name-field-section-body {

      p {
        display: inline-block;
        button {
          margin-left: 1em;

        }
      }
    }

    .field-name-field-content-section {
      > .field-items {

        & > .field-item:nth-child(1) {
          p {
            display: block;
            border-bottom: 1px solid $gray-lighter;
          }
          .button-secondary {
            border-radius: 0;
            background: transparent;
            color: $base-font-color;
            border-bottom: 3px solid transparent;
            &:first-child {
              border-bottom: 3px solid $color-pop;
            }
            &.active {
              border-bottom: 3px solid $color-pop;
              font-family: $font-proxima-nova-bold;
            }
            &.inactive {
              border-bottom: 3px solid transparent;

            }
          }
        }
        & > .field-item:nth-child(2) {
          display: block;
        }
        & > .field-item:nth-child(3) {
          display: none;
        }

        & > .field-item:nth-child(2),
        & > .field-item:nth-child(3) {
          &.active {
            display: block !important;
          }
          &.inactive {
            display: none !important;
          }
        }

      }

    }
  }
}
.view-fbc-accessory-search{
  position: relative;


  .view-filters{
    margin-bottom: 2em;
  }

  &.view-display-id-block{
    .views-row{
      display: flex;
      margin-bottom: 1em;
      padding-bottom: 1em;
      border-bottom: 1px solid $gray-lightest;
      &>div{
        display: inline-block;
        flex: 0 1 70%;
        width: 70%;
        &:nth-child(2){
          flex: 0 1 30%;
          width: 30%;
        }
      }
      .text{
        display: inline-block;
        flex: 0 1 70%;
        width: 70%;
        vertical-align: top;
      }
      .image{
        display: inline-block;
        flex: 0 1 30%;
        width: 30%;
      }



    }
  }
  &.view-display-id-block_1{
    .views-row{
      margin: 0;
      padding: 0;
      border-bottom: none;
      .views-label-view{
        font-family: $font-proxima-nova-bold;
      }
    }
  }

  &.view-display-id-block_2{

    .field-name-field-h1-title h3 p{
      background: $gray;
      color: $white;
      padding: 1em;

    }

  }
  &.view-display-id-block_3{
    .view-content{
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      > h3{
        flex: 0 1 100%;
        background: $gray-light;
        padding: .5em;
        font-size: 1.6rem;
      }

    }

    .views-row{
      display: inline-block;
      flex: 0 1 50%;
      width: 50%;
      border-bottom: 1px solid $gray-lightest;
      padding-bottom: 1em;
      margin-bottom: 1em;
      padding-right: 1em;
      .image, .text{
        display: inline-block;

      }
      .image{
        flex: 0 1 20%;
        width: 20%;


      }
      .text{
        flex: 0 1 80%;
        width: 80%;
      }
    }
  }
}