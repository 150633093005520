.resource-page{
  .header-wrapper{
    height: 200px;
    display: flex;
    flex-direction: column;
    position: relative;
    background: url('../images/backgrounds/hexagons-category-2000x604.png') no-repeat;
    background-position: 0 0;
  }
.header-image{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  max-height: 200px;
  overflow: hidden;
  .field-name-field-header-image{
    height: 100%;
    .field-items{
      height: 100%;
      .field-item{
        height: 100%;
      }
    }
  }

  img{
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;

  }
}
  .header-text{
    @include outer-container();
    height: 100%;
    display: flex;
    width: 100%;
    padding: 0 1em;
    @include media($screen-lg){
      padding: 0;

    }
    .title{
      display: inline-block;
      margin: auto 0;
      position: relative;
      @include media($screen-md){
        width: 66%;

      }
    }
    .header-extras{
      position: absolute;
      top: 0;
      left: auto;
      right: auto;
      bottom: 0;
      width: 100%;
      max-width: $screen-lg;
      z-index: 1;
      .easy-breadcrumb{
        position: absolute;
        top: 1em;
        padding: .5em 0;
        left: 0;

        .easy-breadcrumb_segment{
          color: $white;
        }
      }
      .vocabulary-fbc-brands{
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
      }

    }
  }

.resource-body-wrapper{
  @include outer-container();
  display: flex;
  flex-wrap: wrap;
  padding: 1em;

  @include media($screen-md){
    flex-wrap: nowrap;
  }
  @include media($screen-lg){
    padding: 1em 0;

  }
  }
.resource-body{
  display: inline-block;
  max-width: 100%;
  position: relative;
  min-height: 300px;
  width: 100%;

  @include media($screen-md){
    width: 66%;
    flex: 0 1 66%;
    vertical-align: top;
    min-height: 400px;
  }
  h2{
    line-height: 1;
    margin-top: 1em;
  }
  .coming-soon {
    width: 100%;
    max-height: 300px;
    background: $gray-lightest;
    display: block;
    padding: 50% 0;
    text-align: center;
    font-weight: bold;
    color: $gray;
    border: 1px solid $gray;
  }
  .field-name-field-video-introduction {
    padding: 0 0 2em 0;
  }
  }
.resource-sidebar{
  max-width: 400px;
  margin: auto;
  @include media($screen-md){
    margin: 0;
    display: inline-block;
    flex: 0 1 33%;
    width: 33%;
    padding-left: 2em;
  }
  @include media($screen-lg){
    margin: 0;
  }

    .field-name-ds-open-resource-pdf {
    background: $gray-lightest;
    padding: 2em;
    text-align: center;
    margin-bottom: 2em;
  }

  .field-name-field-resource-type{
    width: 100%;

    .field-item{
      background: $gray;
      color: $white;
      text-align: center;
      padding: 1em 3em;
      font-size: 2rem;
      font-family: $font-proxima-nova-bold;
    }
  }
}
  .field-name-field-webform{
    margin-top: 1em;
    .field-name-title{
      background: $gray;
      padding: .5em;
      h3{
        color: $white;
        margin: 0;
      }
    }

  }
  .field-name-field-related-product {
    @include outer-container();
    .field-items{
      display: flex;
      width: 100%;
      >.field-item {
        display: inline-block;
        width: 33%;
        padding-right: 3em;
        &:last-child{
          padding-right: 0;
        }
        .card-content{
          display: flex;
          flex-direction: column;
          .field-items{
            width: 100%;
            display: block;
            .field-item{
              width: 100%;
              display: block;
            }
          }
          .field-name-field-teaser-image{
            margin-bottom: 1em;

            img {
              margin: auto;
              display: block;
              height: 200px;
            }

          }
        }
      }
    }
  }
  .field-name-field-webinar-date {
    .field-label,
    .field-items {
      line-height: 2;
    }
  }
  .certificate-form-hidden,
  .gate-form-hidden {
    display: none;
  }

  .certificate-form-displayed,
  .gate-form-displayed {
    display: block;
  }

  .video-container-blocked {
    position: relative;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: 'Complete form to view this video.';
      padding: 200px 0;
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      color: white;
      background: transparentize($color: $black, $amount: 0.35);
      z-index: 7;
      pointer-events: all;
    }
  }
}
//Resource template page
.view-fbc-resources {
  &.view-display-id-block_4 {
    .view-content {
      display: block;
      width: 100%;
      .views-row{
        width: 100% !important;
        flex: auto;
        .field-name-field-highlights {
          margin-top: .5em;
        }
        .field-name-ds-resource-link-as-title {
          @extend %button-secondary;
        }
      }
    }

  }
}
