#drop-down-login-wrapper {
  .button {
    width: 100px;
    text-align: center;
    color: $blue;
    background: none;
    text-transform: lowercase;
  }

  .dropdown{
    border: none;
    box-shadow: 2px 2px 10px #c8c8c8;
    border-radius: 2px;
    min-width: 200px;

    .form-item{
      margin: 0;
    }
    .item-list{
      @include clear-ul;

      ul{
        display: flex;
        width: 100%;
        justify-content: space-between;
        li{
          min-width: 0;
          display: inline-block;
          width: 49%;
          background: $teal;
          padding: .25em;
          a{
            font-size: 1.2rem;
            white-space: initial;
            line-height:1;
            color:$white;
          }
        }
      }
    }
    .form-submit{
      margin: 0;
      position: relative;
    }
  }

}