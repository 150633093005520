.field-name-field-promo-link{
  margin-top: 1em;
  padding-left: 2em;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    background-image: url('../images/icons/promo-icon.png');
    background-repeat: no-repeat;
    background-size: 100%;
  }
}