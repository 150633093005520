@mixin button($color: $base-font-color, $type:$action-color, $padding: 0.5em 2em){
    color: $color;
    background-color: $type;
    padding: $padding;
    text-align: center;
    border-radius: 4px;
  font-family: $base-font-family;
  line-height: 1;
    &:hover{
      background-color: darken($type, 10%);
      text-decoration: none;
    }
}



