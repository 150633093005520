.page-node-5338{
  .card-wide-layout{
    img{
      max-height: 250px!important;
      width: auto;
      min-width: 0!important;
      display: block;
      margin: auto;
    }
  }
}