// This is just the main view for the front page. The other views are in their
// subsequent files

.view-frontpage,
.view-frontpage-2020 {
  &.view-display-id-page{
    & > .view-content {
      position: relative;
      & > #slick-views-frontpage-2,
      & > #slick-views-frontpage-1 {
        width: 100%;
        height: 700px;
        max-height: 700px;
        overflow: hidden;
        position: relative;

        &:after {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+70&0.75+0,0+70 */
          background: -moz-linear-gradient(left,  rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 70%); /* FF3.6-15 */
          background: -webkit-linear-gradient(left,  rgba(0,0,0,0.75) 0%,rgba(0,0,0,0) 70%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to right,  rgba(0,0,0,0.75) 0%,rgba(0,0,0,0) 70%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bf000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */

        }
        }
        .field-name-field-slideshow-image,
        picture {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          min-height: 700px;
          max-height: 700px;
          img {
            object-fit: cover;
            min-height: 700px;
            max-width: none;
            width: 100%;
          }
        }
        video{
          display: none;
          @include media($screen-lg){
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            min-height: 700px;
            max-height: 700px;
            z-index: 0;
            object-fit: cover;
          }
        }
        .text-area-wrapper{
          max-width: 1200px;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          min-height: 700px;
          z-index: 2;
          margin: auto;

          .text-area {
            min-height: 300px;
            height: 300px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-left: 1em;
            @include media($screen-lg){
              min-height: 700px;
              height: 700px;
              justify-content: center;
              padding-left: 0;
            }
          }
          .treatment-light-text,
          .light-text{
            h2,h3 {
              color: $white;
            }
          }
          .treatment-dark-text,
          .dark-text{
            h2,h3 {
              color: $black;
            }
          }
          .treatment-pop-text{}
          h2{
            max-width: 600px;
            margin-bottom: 1em;
            color: $black;
            line-height: 1;
            margin-bottom: 0;
            font-size: 5rem;
            text-align: center;
            font-family: $base-font-family;

            @include media($screen-xs){
              text-align: left;
              font-size: 6rem;
            }
            @include media($screen-md){
              font-size: 8rem;
            }
          }

          h3{
            max-width: 600px;
            font-weight: 300;
            line-height: 1.25;
            margin-bottom: 1em;
            text-align: center;
            font-size: 3rem;
            font-family: $font-proxima-nova-light;

            @include media($screen-xs){
              text-align: left;
            }
            @include media($screen-md){
              font-size: 3.3rem;


            }

          }
          .field-name-field-slideshow-link{
            margin-bottom: 1em;
            @include media($screen-lg){
              margin-bottom: 0;
            }
            a {
            @include button($color: $white);
            display: table;
            margin: auto;
            font-weight: bold;
            @include media($screen-xs){
              text-align: left;
              display: inline-block;
            }
          }
        }
      }
        .field-name-field-background-texture-image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          min-height: 700px;
          z-index: 1;
          display: none;

          @include media($screen-lg){
            display: block;
          }
          img {
            object-fit: cover;
            min-height: 700px;
            max-width: none;
            width: 100%;
          }
        }
      }

  }
}