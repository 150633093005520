.node-type-application{
  .field-label{
    @extend h3;
  }

  h2 p{
    @extend h2;
  }
  .field-name-field-featured-resources{

    & > .field-items{
      display: flex;
      justify-content: space-between;
      .field-item{
        background: $gray-lightest;
        padding: 1em;
        margin-right: 1em;
        display: inline-block;
        flex: 0 1 33%;
      }
    }


  }
  .field-name-field-application-problem{
    margin-top: 2em;
    @include extended-background();
    @include gradient(to bottom, white, $gray-lightest);

  }
  .field-name-field-application-solution{
    .card-wide-layout .card-content .card-image{
      order: 2;
    }

  }



  .application-solution-content{
    .solution-info{
      .solution-image{
        display: inline-block;
        background: $gray-lightest;
        box-shadow: inset 0 0 5px $gray;
        height: 300px;
        width: 40%;
        vertical-align: top;
      }
      .solution-description{
        display: inline-block;
        width: 58%;
        padding-left: 1em;
        vertical-align: top;
      }
    }
    .solution-extras{
      display: flex;
      margin: 2em 0;


      .field-name-field-additional-products{
        .field-items{
          display: flex;
          justify-content: space-between;
          & > .field-item{
            flex: 0 1 25%;
            margin-right: 1em;
            .card-tall-layout{
              padding: 0;
            }
            .card-content{
              padding: 0;
              .card-image{
                width: 100%;
                height: 150px;
                background: $gray-lightest;
                box-shadow: inset 0 0 5px $gray;


              }
            }
            .field-items{
              display: block;
              .field-item{
                display: block;
              }
            }

          }
        }
      }


      .solution-resources{
        background: $gray-lightest;
        box-shadow: inset 0 0 5px $gray;

        padding-right: 1em;
        width: 25%;
        display: inline-block;
        vertical-align: top;
      }
    }
  }

}