.view-fbc-product-compatibility {
  &.view-id-fbc_product_compatibility {
    .view-grouping-header {
      margin-top: 2em;
      @extend %table-caption;
      &:first-child {
        margin-top: 0;
      }
    }
    h3 {
      margin-top: 2em;
      @extend %table-caption;
    }
    td {
      padding: 0.5em;
    }
    .product-intro {
      margin-bottom: 1em;
      border: 1px solid $gray-lightest;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      -webkit-box-shadow: 0 0 5px $gray-light;
      -moz-box-shadow: 0 0 5px $gray-light;
      box-shadow: 0 0 5px $gray-light;
      h3 {
        display: block;
        background: transparentize($base-font-color, 0.75);
        padding: 0.5em;
        font-size: 1.5rem;
      }
      img {
        margin: 1em auto 0;
        display: block;
        max-height: 100px;
        width: auto;
      }
      .views-row {
        width: 100%;
      }
      .buttons {
        padding: 0.5em;
      }
      a.colorbox-inline {
        border: 1px solid $blue;
        border-radius: 4px;
        display: block;
        margin: 0 auto;
        padding: 0.25em 0.5em;
        text-align: center;
      }
    }
    .product-info {
      //display: flex;
      width: 100%;
      .description {
        padding: 1em;
      }
      .images {
        .field-items {
          display: flex;
          justify-content: space-around;
          .field-item {
            display: inline-block;
          }
        }
      }
    }
    .card-header,
    h4 {
      margin: 0;
      padding: 0.5em;
      background: $gray-lightest;
      display: block;
      color: $base-font-color;
      min-height: 56px;
    }

    .part-number {
      text-align: right;
      font-size: 1.4rem;
    }

    &.view-display-id-block_3 {
      display: block;
      @include media($screen-md) {
        display: none;
      }
    }
    &.view-display-id-block_5 {
      display: none;
      @include media($screen-md) {
        display: block;
      }
    }

    table {
      //border: 1px solid $gray-lighter;
    }
    thead {
      tr {
        th {
          background-color: $gray-lighter;
          text-align: center;
          font-size: 2rem;
          color: $white;
          border-left: 2px solid $white;
          &:first-child {
            border-left: 0px solid transparent;
          }
        }
      }
    }
    tr.odd,
    tr.even {
      padding-top: 1em;
      background-color: transparent;
      td {
        // border-top: 2px solid $gray-lighter;
        margin-top: 1em;
        border-left: 1px solid $gray-lighter;
      }
    }
    tr td:first-child {
      padding-top: 1em;
      font-size: 1.8rem;
      font-family: $font-proxima-nova-semibold;
    }
    .views-field-colorbox {
      > a {
        max-width: 220px;
        //height: 220px;
        display: block;
        margin: 1em auto auto auto;
        position: relative;
        border: 1px solid $gray-lightest;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        -webkit-box-shadow: 0 0 5px $gray-light;
        -moz-box-shadow: 0 0 5px $gray-light;
        box-shadow: 0 0 5px $gray-light;
        &:hover {
          text-decoration: none;
          -webkit-box-shadow: 0 0 5px $gray-dark;
          -moz-box-shadow: 0 0 5px $gray-dark;
          box-shadow: 0 0 5px $gray-dark;
        }
      }
    }
    .field-name-field-slideshow-images {
      max-height: 110px;
      background: $white;
      img {
        display: block;
        margin: auto;
        height: 100px;
        width: auto;
        padding: 0.5em;
      }
    }
  }
}

#cboxLoadedContent {
  .product-info {
    padding: 1em;
    .field-name-field-slideshow-images {
      .field-items {
        display: flex;
        justify-content: space-around;

        .field-item {
          display: flex;
          flex: 0 1 30%;
          justify-content: space-around;

          img {
            margin: auto;
          }
        }
      }
    }
    .name-description {
      margin-top: 1em;
    }
  }
}
