.page-node-5922{
  @extend %alternate-cards;
  .card-wide-layout{
    .field-name-field-link{
      margin-top: 2em;
      a{
        @include button($white, $color-primary);
        padding: .25em 1em;
      }
    }
  }
}