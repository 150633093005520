//.icon-wrap{
//  width:125px;
//  background-image: url('../images/hexagon-solid-white.png');
//  background-repeat: no-repeat;
//
//}
//
//
.icon-wrap-double{
  width:123px;
  background-image: url('../images/hexagon-double-solid.png');
  background-repeat: no-repeat;
  background-color: $white;

}