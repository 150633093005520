.node-cta-bug {
    @include aspect-ratio (16,9);
    position: relative;
    margin-top: 1em;
    margin-bottom: 1em;
    overflow: hidden;

    .background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: cover;
        z-index: 0;

        img {
            min-height: 100%;
            width: auto;
            min-width: 100%;
            height: auto;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            object-fit: cover;
        }

    }

    .field-name-field-headline {
        font-size: 2.2rem;
        font-weight: 700;
        margin-bottom: 0.5rem;
    }
    .field-name-field-cta-link {
        a {
            @extend %button-secondary;
        }
    }
    &.top-left{
        .text {
            position: absolute;
            top: 1em;
            left: 1em;
            padding-right: 40%;
        }

    }
    &.top-right{
        .text{
            position: absolute;
            top: 1em;
        right: 1em;
        padding-left: 40%;
        text-align: right;
        }

    }
    &.bottom-left{
        .text{
            position: absolute;
            bottom: 1em;
            left: 1em;
            padding-right: 40%;

        }
    }
    &.bottom-right {
        .text{
            position: absolute;
            bottom: 1em;
            right: 1em;
            padding-left: 40%;
            text-align: right;
        }
    }
    &.dark {
        .text{
            color: $gray-dark;

        }
    }

    &.light {
        .text {
            color: transparentize($white, .1);
            outline: 1px $gray-dark;

        }
    }
}
