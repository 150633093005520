.node-webform{
  .field-name-title{

  }
  .field-name-ds-webform-footer{
    display: none;
  }
}
#colorboxWrapper{
}
#cboxContent{
  border-radius: 0;
  #cboxLoadedContent{
    padding: 0;
  }
  .node-webform {

    .field-name-title,
    .field-name-ds-webform-footer{
      display: block;
    }
    .field-name-title-field,
    .field-name-title{
      position: relative;
      height: 100px;
      text-align: center;
      color: $base-font-color;

      .field-items{
        z-index: 5;
        position: relative;
        display: block;
        height: 100%;
        .field-item{
          height: 100%;
          display: flex;
          justify-content: space-around;
        }
        h2{
          display: block;
          margin: auto;
        }
      }

    }

.footer-wrapper{
  background: $gray;
  position: relative;
  padding: 0.5em 0;
  &:after{

  }

  .logos{
    position: relative;
    display: flex;
    justify-content: flex-end;
    &:after{
      content: '';
      position: absolute;
      border: 1px solid $color-pop;
      top: 50%;
      bottom: 50%;
      left: 0;
      right: 0;
      z-index: 0;
    }
    a{
      width: 50%;
      display: flex;
      position: relative;
      justify-content: flex-end;
      z-index: 3;
      img{
        flex: 0 1 33%;
        display: inline-block;
        max-width: 100px;
        height: 78px;
      }
    }
  }
  .links{
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-top: -33px;
    padding: 0 1em 1em 1em;
    a{
      display: inline-block;
      font-size: 1.3rem;
      color: $white;
    }
  }
}

  }
}
//All webforms
.webform-client-form{
  label.option{
    white-space: normal;
  }

  span.error {
    font-size: 1.3rem;
    color: red;
  }

  .row{               //This is the wrapper around the form elements
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
  .webform-component{
    display: inline-block;
    margin: 0;
    padding: 0;
    flex: 0 1 100%;
  }

  .webform-component--first-name,
  .webform-component--last-name,
  .webform-component--country,
  .webform-component--zip-or-postal-code,
  .webform-component--business-phone,
  .webform-component--business-phone-rev,
  .webform-component--company {
    flex: 0 1 48%;
  }
  .webform-component--first-name{
  }
  .webform-component--last-name{
    padding-right: 0;
  }

  .webform-component--list-all-products-of-interest{}

  .form-actions{
    flex: 0 1 100%;
  }

  .webform-component--i-would-like-to{
    margin-bottom: 1em;
    .form-radios{
      display: flex;
      width: 100%;
      justify-content: space-around;

      .form-item{
        width: 33%;
        display: inline-block;
        position: relative;
        &:hover{
          cursor: pointer;
        }

        input[type=radio]{
          //opacity: 0;
          //position: absolute;
          //top: 0;
          //left: 0;
          //right: 0;
          //bottom: 0;
          //height: 100%;
          //width: 100%;
          //&:hover{
          //  cursor: pointer;
          //}
          //
          //&:hover ~ label{
          //
          //}
          //&:checked ~ label{
          //  &:before{
          //    position: absolute;
          //    top: 0;
          //    left: 0;
          //    content: "✔";
          //    color: $color-primary;
          //    font-size: 2.5rem;
          //    font-weight: 700;
          //  }
          //}
        }
        label{

          font-size: 1.5rem;
          //display: block;
          //color: $base-font-color;
          //padding: .5em;
          //font-family: $base-font-family-semibold;
          //padding-left: 20px;
          //&:after{
          //  position: absolute;
          //  top: 4px;
          //  left: 0;
          //  content: "☐";
          //  font-size: 2rem;
          //  pointer-events: none;
          //  &:hover{
          //    cursor: pointer;
          //
          //  }
          //}


        }

      }

    }
   }
  .webform-component--contact-me,
  .webform-component--i-consent{
    .form-item{
      margin: 0;
    }
    //padding-left: 1.5em;
    //input {
    //  margin-left: -1.5em;
    //  margin-top: -2em;
    //}
    //label {
    //  font-size: 1.5rem;
    //  line-height: 1.25;
    //  margin-top: .5em;
    //  display: inline-block;
    //}
  }

}
