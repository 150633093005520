.field-name-breadcrumbs {
  ol {
    list-style-type: none;
    padding-left: 0;
    li {
      list-style: none;
      display: inline;
    }
    a.easy-breadcrumb_segment,
    .easy-breadcrumb_segment {
      font-size: 1.4rem;
    }
    .easy-breadcrumb_segment-separator {
      display: inline;
    }
  }
}