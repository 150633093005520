.field-name-field-application-sections {
  .paragraphs-item-application-section,
  .paragraphs-item-application-recommended-products{
      padding: 1em;
      @include media($screen-lg) {
        padding: 3em 0;
      }
    }
    

  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2.5rem;
  }

  h1, h2, h3, h4 {
    .sub {
      font-weight: 700;
      display: block;
      font-size: 75%;
      margin-top: 1rem;
    }
  }

  .app-subsection-headlines {
    margin-bottom: 2em;
  }

  .background-white {
    background-color: $white;
  }
  .background-light {
    background-color: transparentize(black, 0.97);
  }
  .background-medium {
    background-color: transparentize(black, 0.75);
  }
  .background-dark {
    background-color: transparentize(black, 0.5);

  }

    .background-white,
    .background-medium,
    .background-light {
      * {
        color: $base-font-color;
      }
      a {
        color: $blue;
      }
    }

    .background-dark {
      * {
        color: $white;
      }
      a {
        color: $white;
        text-decoration: underline;
      }
    }

    .vert-align-top {
      .paragraphs-items-field-app-subsection-content-ite .field-items .field-item {
        margin-top: 0;
        margin-bottom: auto;
      }
    }
    .vert-align-middle {
        .paragraphs-items-field-app-subsection-content-ite .field-items .field-item {
          margin-top: auto;
          margin-bottom: auto;
        }
    }
    .vert-align-bottom {
      .paragraphs-items-field-app-subsection-content-ite .field-items .field-item {
        margin-top: auto;
        margin-bottom: 0;
      }
    }
}
