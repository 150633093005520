


@font-face {
  font-family: 'FontAwesome';
  font-display: block;
  src: url('../fonts/fontawesome/fontawesome-webfont.eot?v=4.6.3');
  src: url('../fonts/fontawesome/fontawesome-webfont.eot?#iefix&v=4.6.3') format('embedded-opentype'),
  url('../fonts/fontawesome/fontawesome-webfont.woff2?v=4.6.3') format('woff2'),
  url('../fonts/fontawesome/fontawesome-webfont.woff?v=4.6.3') format('woff'),
  url('../fonts/fontawesome/fontawesome-webfont.ttf?v=4.6.3') format('truetype'),
  url('../fonts/fontawesome/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular') format('svg');

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FontAwesomeFive';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url('../fonts/fontawesome5/webfonts/fa-solid-900.eot');
  src: url('../fonts/fontawesome5/webfonts/fa-solid-900.eot?#iefix') format('embedded-opentype'),
  url('../fonts/fontawesome5/webfonts/fa-solid-900.woff2') format('woff2'),
  url('../fonts/fontawesome5/webfonts/fa-solid-900.woff') format('woff'),
  url('../fonts/fontawesome5/webfonts/fa-solid-900.ttf') format('truetype'),
  url('../fonts/fontawesome5/webfonts/fa-solid-900.svg#fontawesome') format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url('../fonts/fontawesome5/webfonts/fa-solid-900.eot');
  src: url('../fonts/fontawesome5/webfonts/fa-solid-900.eot?#iefix') format('embedded-opentype'),
  url('../fonts/fontawesome5/webfonts/fa-solid-900.woff2') format('woff2'),
  url('../fonts/fontawesome5/webfonts/fa-solid-900.woff') format('woff'),
  url('../fonts/fontawesome5/webfonts/fa-solid-900.ttf') format('truetype'),
  url('../fonts/fontawesome5/webfonts/fa-solid-900.svg#fontawesome') format('svg');
}


// Light
@font-face {font-family: 'ProximaNova';
  font-display: swap;
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/ProximaNova/webfonts/36DD2F_C_0.eot');
 src: url('../fonts/ProximaNova/webfonts/36DD2F_C_0.eot?#iefix') format('embedded-opentype'),url('../fonts/ProximaNova/webfonts/36DD2F_C_0.woff2') format('woff2'),url('../fonts/ProximaNova/webfonts/36DD2F_C_0.woff') format('woff'),url('../fonts/ProximaNova/webfonts/36DD2F_C_0.ttf') format('truetype');
 }

// Light IT
@font-face {font-family: 'ProximaNova';
  font-display: swap;
  font-weight: 300;
  font-style: italic;
  src: url('../fonts/ProximaNova/webfonts/36DD2F_9_0.eot');
  src: url('../fonts/ProximaNova/webfonts/36DD2F_9_0.eot?#iefix') format('embedded-opentype'),url('../fonts/ProximaNova/webfonts/36DD2F_9_0.woff2') format('woff2'),url('../fonts/ProximaNova/webfonts/36DD2F_9_0.woff') format('woff'),url('../fonts/ProximaNova/webfonts/36DD2F_9_0.ttf') format('truetype');
 }

// regular
@font-face {font-family: 'ProximaNova';
  font-display: swap;
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/ProximaNova/webfonts/36DD2F_E_0.eot');
 src: url('../fonts/ProximaNova/webfonts/36DD2F_E_0.eot?#iefix') format('embedded-opentype'),url('../fonts/ProximaNova/webfonts/36DD2F_E_0.woff2') format('woff2'),url('../fonts/ProximaNova/webfonts/36DD2F_E_0.woff') format('woff'),url('../fonts/ProximaNova/webfonts/36DD2F_E_0.ttf') format('truetype');
 }
// Regular IT
@font-face {font-family: 'ProximaNova';
  font-display: swap;
  font-weight: 500;
  font-style: italic;
  src: url('../fonts/ProximaNova/webfonts/36DD2F_17_0.eot');
 src: url('../fonts/ProximaNova/webfonts/36DD2F_17_0.eot?#iefix') format('embedded-opentype'),url('../fonts/ProximaNova/webfonts/36DD2F_17_0.woff2') format('woff2'),url('../fonts/ProximaNova/webfonts/36DD2F_17_0.woff') format('woff'),url('../fonts/ProximaNova/webfonts/36DD2F_17_0.ttf') format('truetype');
 }

//Semibold
@font-face {font-family: 'ProximaNova';
  font-display: swap;
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/ProximaNova/webfonts/36DD2F_11_0.eot');
 src: url('../fonts/ProximaNova/webfonts/36DD2F_11_0.eot?#iefix') format('embedded-opentype'),url('../fonts/ProximaNova/webfonts/36DD2F_11_0.woff2') format('woff2'),url('../fonts/ProximaNova/webfonts/36DD2F_11_0.woff') format('woff'),url('../fonts/ProximaNova/webfonts/36DD2F_11_0.ttf') format('truetype');
 }
// Semibold IT
@font-face {font-family: 'ProximaNova';
  font-display: swap;
  font-weight: 600;
  font-style: italic;
  src: url('../fonts/ProximaNova/webfonts/36DD2F_15_0.eot');
 src: url('../fonts/ProximaNova/webfonts/36DD2F_15_0.eot?#iefix') format('embedded-opentype'),url('../fonts/ProximaNova/webfonts/36DD2F_15_0.woff2') format('woff2'),url('../fonts/ProximaNova/webfonts/36DD2F_15_0.woff') format('woff'),url('../fonts/ProximaNova/webfonts/36DD2F_15_0.ttf') format('truetype');
 }

// Bold
@font-face {font-family: 'ProximaNova';
  font-display: swap;
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/ProximaNova/webfonts/36DD2F_5_0.eot');
 src: url('../fonts/ProximaNova/webfonts/36DD2F_5_0.eot?#iefix') format('embedded-opentype'),url('../fonts/ProximaNova/webfonts/36DD2F_5_0.woff2') format('woff2'),url('../fonts/ProximaNova/webfonts/36DD2F_5_0.woff') format('woff'),url('../fonts/ProximaNova/webfonts/36DD2F_5_0.ttf') format('truetype');
 }

// Bold it
@font-face {font-family: 'ProximaNova';
  font-display: swap;
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/ProximaNova/webfonts/36DD2F_4_0.eot');
 src: url('../fonts/ProximaNova/webfonts/36DD2F_4_0.eot?#iefix') format('embedded-opentype'),url('../fonts/ProximaNova/webfonts/36DD2F_4_0.woff2') format('woff2'),url('../fonts/ProximaNova/webfonts/36DD2F_4_0.woff') format('woff'),url('../fonts/ProximaNova/webfonts/36DD2F_4_0.ttf') format('truetype');
 }

$icon-font            : "FontAwesome";
$icon-font-updated    : "FontAwesomeFive";
$fa-font-path:        "../fonts/fontawesome5/webfonts";
$font-helvetica       :  "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
$font-proximanova      : "ProximaNova", "Helvetica", sans-serif;
$font-proxima-nova-regular      : "ProximaNova", "Helvetica", sans-serif;
$font-proxima-nova-regular-it      : "ProximaNova", "Helvetica", sans-serif;
$font-proxima-nova-semibold         : "ProximaNova", "Helvetica", sans-serif;
$font-proxima-nova-bold         : "ProximaNova", "Helvetica", sans-serif;
$font-proxima-nova-light      : "ProximaNova", "Helvetica", sans-serif;
$base-font:   $font-proximanova;


// Typography
$base-font-family         : $font-proximanova;

$base-font-family-semibold      : $font-proxima-nova-semibold;

$heading-font-family      : $font-proxima-nova-semibold;

$base-font-family-italic  : $font-proxima-nova-regular-it;


// Font Sizes
$base-font-size: 1.7rem;


// Line height
$base-line-height: 1.25;

$heading-line-height: 1.5;


// Other Sizes
$base-border-radius: 0;

$base-spacing: $base-line-height * 1em;

$small-spacing: $base-spacing / 2;

$base-z-index: 0;




//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-size-h1:            4rem;

$font-size-h2:            3rem;

$font-size-h3:            2.5rem;

$font-size-h4:            2rem;

$font-size-h5:            $base-font-size;

$font-size-h6:            1.5rem;



//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.5;
  // 13/19.5

$headings-font-family:    $heading-font-family;

//$headings-font-weight:    800;

$subheadings-font-weight: 400;

$headings-line-height:    1.3;


$font-size-xl           : $base-font-size * 1.5;

$font-size-lg           : $base-font-size * 1.25;

$font-size-md           : $base-font-size * 1;

$font-size-sm           : $base-font-size * 0.9;

$font-size-xs           : $base-font-size * 0.8;



$serif              : Times;

$sans-serif         : $base-font-family;
