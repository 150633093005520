.node-type-product-display{

  #colorbox{
    background: $white;
    -webkit-box-shadow: 0 0 3px $gray;
    -moz-box-shadow: 0 0 3px  $gray;
    box-shadow: 0 0 3px  $gray;
  }
  // #cboxContent{
  //   @include media($screen-md){
  //   }
  // }
  #cboxLoadedContent{
    overflow-x: hidden!important;
    overflow-y:auto;
    -webkit-box-shadow: inset -5px 0 5px $base-font-color;
    -moz-box-shadow: inset -5px 0 5px $base-font-color;
    box-shadow: inset -5px 0 5px $base-font-color;
  }
  #cboxClose.cbox-close-plain{
    background: $color-pop;
    font-size: 30px;
    width: 25px;
    height: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    color: $base-font-color;
    top: .5em;
    right: .5em;
  }
}

.page-specs,
.view-mode-datasheet{


  .field-name-field-datasheet-key-features,
  .field-name-field-datasheet-overview{
    .field-label{
      font-size: 3rem;
    }
  }
}

.page-specs,
.view-mode-datasheet{
  .breadcrumbs{
    display: none;
  }

  .field-name-field-voc-statement{
    p{
    color: $base-font-color;
      font-weight: bold;
    }
  }
  .card-wide-layout{
    box-shadow: none;
    overflow: visible;
    padding-top: 0;
    padding-bottom: 1em;
    .card-content{
      padding: 0;
      flex: 0 1 20%;
    }
    .card-text{
      padding-left: 2em;
      flex: 0 1 80%;

    }

  }
  .field-name-ds-share-this-buttons{
    position: absolute;
    bottom: 1em;
    right: 2em;
  }

  .field-name-field-additional-datasheets{
    position: relative;
    &:hover > .field-items{
      display: block;
    }
    > .field-items{
      position: absolute;
      width: 50%;
      display: none;
      background: $white;
      -webkit-box-shadow: 0 0 3px $gray-light;
      -moz-box-shadow: 0 0 3px $gray-light;
      box-shadow: 0 0 3px $gray-light;

      z-index: 2;
      .field-items{

        .field-item{

          a{
            padding: .25em;
            display: block;
            width: 100%;

            &:hover{
              text-decoration: none;
              background: $color-primary;
              color: $white;

            }
          }

        }
      }
    }
  }
  ul.horizontal-tabs-list{
    -webkit-box-shadow: 0 0 0 0 transparent;
    -moz-box-shadow: 0 0 0 0 transparent;
    box-shadow: 0 0 0 0 transparent;
    margin-bottom: 1em;
    //width: 100%;
    height: 50px;
    display: flex;
    //@include media($screen-md){
    //  height: auto;
    //}
    li{
      display: inline-block;
      //display: block;
      //width: 100vw;
      //border: 1px solid $gray-light;
      &.selected,
      &:hover,
      &:active{
        padding-right: 2em;
        border-bottom: 3px solid $color-primary;
      }
      @include media($screen-md){
        display: inline-block;
        padding-right: 2em;

      }
      a{
        &:hover,
        &:active{
          color: $base-font-color;
        }
      }
    }
  }

.horizontal-tabs-panes{
  display: block;
  //width: 97%;
  //overflow-y: scroll;
  //overflow-x: hidden;
  //height: 65vh;
  padding: 0 1em;
}

}

.group-general-specifications,
.group-detailed-specifications{
  margin-bottom: 3em;


  .row{                         //wrapper
    margin-top: 1em;
    .row{                       // first level
      margin-top: 0;
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      padding: 0 .5em;
      .attribute,
      .value{
        padding: .25em;
      }
      .spec-header{
        background: $color-secondary;
        color: $white;
        width: 100%;
        font-weight: 700;
        padding: .25em .5em;
      }
      .row{                         //second level
        .spec-header{
          color: $base-font-color;
          background: transparentize($gray-lightest, .5);
        }
        //.attribute{
        //  padding-left: 1em;
        //}
        .row{
          &:first-child{
            border-top: none;
          }

          .spec-header{
            background: transparentize($gray-lightest, .5);
            //padding-left: 2em;
          }
          //.attribute{
          //  //padding-left: 2em;
          //}
        }
      }
    }
  }
  .attribute {
    display: inline-block;
    flex: 0 1 50%;
    padding: .5em;
    font-weight:bold;
    @include media($screen-md){
      flex: 0 1 23%;

    }
  }
  .value {
    display: inline-block;
    padding: .25em;
    flex: 0 1 50%;
    @include media($screen-md){
      flex: 0 1 70%;

    }
  }


  .grid {
    max-width: 100%;
    width: 100%;
    display: block;
    padding-left: 1em;
    margin: 1em;
    .row {
      display: flex;
      width: 100%;
      flex-wrap: nowrap;
      div {
        padding: .25em;
        display: inline-block;
        width: 33.3%;
        text-align: center;

      }
      &:first-of-type {
        div {
          background: transparentize($gray-lightest, .5);
          font-weight: 700;
          text-align: center;
          padding: .5em;
          &:first-of-type {
            background: white;
          }
        }
      }
      div {

        &:first-child {
          text-align: left;
          background: transparentize($gray-lightest, .5);

        }
      }
    }

  }

  &.two-col {
    .row div {
      width: calc(99% / 2);
    }
  }
  &.three-col {
    .row div {
      width: calc(99% / 3);
    }
  }
  &.four-col {
    .row div {
      width: calc(100% / 4);
    }
  }
  &.five-col {
    .row div {
      width: calc(100% / 5);
    }
  }
  &.six-col {
    .row div {
      width: calc(100% / 6);
    }
  }
  &.seven-col {
    .row div {
      width: calc(100% / 7);
    }
  }
  &.eight-col {
    .row div {
      width: calc(100% / 8);
    }
  }
  &.nine-col {
    .row div {
      width: calc(100% / 9);
    }
  }
  &.ten-col {
    .row div {
      width: calc(100% / 10);
    }
  }

}

