// .paragraphs-item-teaser-image-link-set{
//   padding: 0;
// }

.field-name-field-call-to-action,
.field-name-field-teaser-image-link{
  padding: 0 1em;
  @include outer-container();
  @include media($desk-wide){
    padding: 0;
  }
  >.field-items {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-around;
    @include media($tablet-large) {
      flex-direction: row;
    }
    > .field-item {
      display: inline-block;
      flex: 0 1 100%;

      @include media($tablet-large) {
        flex: 0 1 33.3%;
        margin-right: 1em;
        &:last-child {
          margin-right: 0;
        }
      }
      img {
        display: block;
        margin: auto;
      }
    }

    .content {
      text-align: center;
      .field-name-field-headline{
        border-bottom: 1px solid $gray-light;
        padding-bottom: 1em;
        margin-bottom: 1em;
        margin-top: 2em;
        .field-item {
          font-weight: 700;
          font-size: 2rem;
        }
      }
      .field-name-field-teaser-text {
        margin-bottom: 1em;
      }
    }
  }
}
