.view-id-fbc_videos,
.view-fbc-resources{
  &.view-display-id-block_3,
  &.view-display-id-block_1,
  &.view-display-id-block_12,
  &.view-display-id-block_13,
  &.view-display-id-block_4:not(.view-fbc-resources),
  &.view-display-id-block_8,
  &.view-display-id-block_9
  &.view-display-video-cards{
    .view-content{
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: flex-start;
      h3{
        flex: 0 1 100%;
      }

      .views-row{
        flex: 0 1 100%;
        margin: auto auto 3em auto;
        max-width: 500px;

        @include media($screen-sm){
          flex: 0 1 50%;
          //max-width: 270px;
          padding-right: 2em;
          margin: 0;
          margin-bottom: 1em;
          }

        @include media($screen-md){
        flex: 0 1 33%;
        //max-width: 270px;
        padding-right: 2em;
        margin: 0;
        margin-bottom: 1em;

        }
        @include media($screen-lg){
          flex: 0 1 25%;
        }

        a.colorbox-load:first-of-type {
          position: relative;
          display: block;

          &:after {
            content: '';
            height: 50px;
            width: 70px;
            position: absolute;
            top: calc(50% - 1em);
            bottom: auto;
            left: calc(50% - 2em);
            right: auto;
            background-image: url(../images/youtube-play.png);
            background-repeat: no-repeat;
            background-size: 100%;

          }
        }
        img{
          position: relative;
          display: block;
          margin: auto auto 1em auto;
          height: 260px;
          width: 500px;
          max-width: 100%;
          object-fit: cover;
          @include media($screen-sm){
            height: 158px;
            width: 100%;
          }

          @include media($screen-md){
            height: 158px;
            width: 100%;
          }




        }

          .field-content{
          font-size: 1.5rem;

            .video-embed-description{
              margin-top: 1em;
            }
        }

        .views-row{
          margin: 0;
          a.colorbox-load:first-of-type {
            &:after {
              content: '';
              height: 0;
              width: 0;
              position: absolute;
              top: 0;
              bottom: 0;
              left: auto;
              right: 0;
              background-image: none;

            }
          }
        }
      }

    }
  }
  &.view-display-id-block_2{
    margin-top: .5em;
    .view-content{
      justify-content: flex-start;
      .views-row{
        flex: 0 1 33%;
        width: 33%;
        padding-right: 0;
        margin-bottom: .25em;
        .views-field-nothing{
          a{
            font-family: $font-proxima-nova-semibold;
          }
        }

      }
    }
  }
  &.view-display-id-attachment_1 {
    .view-content {
      padding: 1em;
      background-color: none;
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      .views-row {
        width: 100%;
        @include media($screen-sm){
          flex: 0 1 50%;
          //max-width: 270px;
          padding-right: 2em;
          margin: 0;
          margin-bottom: 1em;
          }

        @include media($screen-md){
        flex: 0 1 33%;
        //max-width: 270px;
        padding-right: 2em;
        margin: 0;
        margin-bottom: 1em;

        }
        @include media($screen-lg){
          flex: 0 1 25%;
        }
      }
    }

  }
}
