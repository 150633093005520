table {
  @include font-feature-settings("kern", "liga", "tnum");
  border-collapse: collapse;
  margin: $small-spacing 0;
  table-layout: fixed;
  width: 100%;
}

tr, td{
  background: none;
}

table{
  position: relative;
  overflow: hidden;

  //&:before{
  //  content: '';
  //  position: absolute;
  //  top: 0px;
  //  left: 0px;
  //  background: $white;
  //  height: 100px;
  //  width: 100%;
  //
  //}
  tbody{
    //box-shadow: inset 0 0 1px $gray-light;
  }
}
tbody, tr{
  background: none;
}

tbody {
  border-top: none;
}

thead tr{
  th{
    position: relative;
    //border-left: 1px solid $gray-lighter;

    &:first-child{
      text-align: left;
    }


  &.sticky-column{
    border-top: none;
  }
  }
}
th.sticky-column{
  color: $base-font-color;
  //border-top: 1px solid $gray-lighter;
  border-bottom: none;
  padding: .5em;
  font-weight: normal;
}
tr{
  background: none;
  //border: 1px solid $gray-light;
  &:nth-child(odd){
    //background: transparentize($gray-lighter, .75);
  }

}
td{
  text-align: left;
  //border-left: 1px solid $gray-lighter;


  //&:hover{
  //  background: transparentize($teal, .75);
  //  position: relative;
  //  &:after{
  //    height: 1000px;
  //    width: 100%;
  //    content: '';
  //    position: absolute;
  //    top: -500px;
  //    left: 0;
  //    background: transparentize($teal, .75);
  //    z-index: -1;
  //
  //  }
  //}
>p{
  margin: 0;
}

}
