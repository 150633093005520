.invisible {
  height: 0;
  opacity: 0;
  transform: translate(1000px);
}
.visible{
  opacity: 1!important;
  transform: translate(0)!important;
  height: auto!important;
  -webkit-transition-delay: 5s;
  -moz-transition-delay: 5s;
  -ms-transition-delay: 5s;
  -o-transition-delay: 5s;
  transition-delay: 5s;
  -webkit-transition: transform 0.5s ease-in;
  -moz-transition: transform 0.5s ease-in;
  -ms-transition: transform 0.5s ease-in;
  -o-transition: transform 0.5s ease-in;
  transition: transform 0.5s ease-in;
}