.paragraphs-item-teaser{
  a{
    font-weight: 700;
  }
  .card-text{
    padding: 0;
    font-size: $base-font-size * .9;
    .field-name-field-link-to-content{
      font-size: $base-font-size;
      padding-bottom: .5em;
    }
  }
}