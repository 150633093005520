.node-type-product-highlights {
  .title h1 {
    color: $base-font-color!important;
  }

  .header-image {
    img {
      min-height: 400px!important;
    }
  }
  .field-name-ds-upcoming-events {
    background: $gray-lightest;

    .view-fbc-events {
      @include outer-container();
      padding-top: 2em;
      padding-bottom: 2em;

      .view-content {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .views-row {
          display: inline-block;
          flex: 0 1 30%;
          width: 30%;

          a h3 {
            color: $base-link-color;
          }
        }
      }
    }
  }

  .field-name-ds-social-block {
    .field-item {
      @include outer-container();
      padding-top: 2em;
      padding-bottom: 2em;
    }
  }
  .field-name-ds-get-more-information-webform {
    background: $gray-lightest;
    border-top: 3px solid $base-font-color;
    .block-title {
      padding: 1em;
      padding-bottom: 0;
      text-align: center;
    }
  }
}
