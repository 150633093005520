.paragraphs-item-application-tile-set {
  .field-name-field-application-tile {
    > .field-items {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      
      > .field-item {
        display: flex;
        padding: 0 1em;
        text-align: center;
        flex-direction: column;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        .field-name-field-app-tile-image {
          height: 120px;
          margin-bottom: 1em;
          img {
            height: 120px;
            width: auto;
          }
        }

        a {
          color: $base-font-color;
          text-decoration: none;

          &:hover {
            text-decoration: none;
          }
        }

        p {
          margin: 0 0 1em 0;
        }

        .link-text {
          color: $blue;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.app-tile-set{
  &.cols-3,
  &.cols-4,
  &.cols-5,
  &.cols-6,
  &.cols-7,
  &.cols-8,
  &.cols-10 {
    .field-items {
      .field-item {
        .field-items {
          width: 100%;
          .field-item {
            width: 100%;
          }
        }
      }
    }
  }
}

.app-tile-set{
  &.cols-3{
    .field-items {
      .field-item {
        width: calc(100%/3);
        .field-items {
          width: 100%;
        }
      }
    }
  }
  &.cols-4{
    .field-items {
      .field-item {
        width: calc(100%/4);
      }
    }
  }
  &.cols-5{
    .field-items {
      .field-item {
        width: calc(100%/5);
      }
    }
  }
  &.cols-6{
    .field-items {
      .field-item {
        width: calc(100%/6);
      }
    }
  }
  &.cols-8{
    .field-items {
      .field-item {
        width: calc(100%/7);
      }
    }
  }
  &.cols-10{
    .field-items {
      .field-item {
        width: calc(100%/8);
      }
    }
  }
}
