.red{
  color: red;
}

.green{
  color: green;
}


.field-name-ds-attribution {
  margin-bottom: 2em;
  span.button {
    border-radius: 3px;
    background-color: $gray-lightest;
    padding: 0.5rem;
  }
  p {
    font-size: 1em;
  }

  table {
    tr {
      td{
        padding: 0;

        &:first-child {
          width: 25%;
        }
      }
    }
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;

  &.grid-1 .grid-item,
  &.grid-2 .grid-item,
  &.grid-3 .grid-item {
    display: block;
    width: 100%;
    whitespace: normal;
  }

  @include media($screen-sm) {
    &.grid-2 .grid-item,
    &.grid-3 .grid-item,
    &.grid-4 .grid-item{
      width: calc(100%/2 - 1em);
    }
  }

  @include media($screen-lg) {
    &.grid-2 .grid-item,
    &.grid-3 .grid-item {
    display: inline-block;
  }
    &.grid-2 .grid-item {
      width: calc(100%/2 - 1em);
    }
    &.grid-3 .grid-item {
      width: calc(100%/3 - 1em);
    }
    &.grid-4 .grid-item {
      width: calc(100%/4 - 1em);
    }
  }


}