.view-mode-simple_one_line{
  .group-left{
    width: 70%;
    .field-name-field-h1-title{
      .field-item{
        font-family: $font-proxima-nova-bold;
      }
    }
  }
  .group-right{
    padding-left: 2em;
    width: 30%;
  }
}