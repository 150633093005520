.fbc-2col{

  .content-section{

    width: 100%;
    @include media($screen-sm){
      display: flex;
    }
    .group-left,
    .group-right{
      width: 100%;
      @include media($screen-sm){
        display: inline-block;
        flex: 0 1 50%;
      }

    }
    .group-left{
      @include media($screen-md){
      margin-right: 2em;
      }

    }
    .field-name-field-image{
      img{
        display: block;
        margin: auto;
        min-width: 100%;
        background-size: cover;
      }
    }

  }

  &.image-Left {
    .group-right {
      order: 1;
    }
    .group-left {
      order: 2;
      margin-right: 0;
      @include media($screen-md){
        margin-left: 2em;
      }
    }
  }

}