%all-list-unstyled {
  ul,
  ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}
%ol-unstyled {
  ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}
%ul-unstyled {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}
%ul-left-aligned {
  ul {
    padding-left: 1.5rem;
    margin: 0;
  }
}

@mixin restyle-list{
  ul{
    list-style-type: disc;
    li{
      list-style: disc;
    }
  }
}