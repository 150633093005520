.field-name-field-application-related-produc{
    @include media($tablet) {
        @include outer-container();
    }
    .field-items {
        display: flex;
        justify-content: flex-start;
        
        .field-item {
            width: calc(20% - 1em);
            display: inline-block;
            margin-right: 1em;
            &:last-child {
                margin-right: 0;
            }
            .field-name-field-teaser-image {
                display: block;
                text-align: center;
            }
            .field-items {
                display: block;
                width: 100%;
            }
        }
    }
}
