.page-node-20432 {
  .paragraphs-item-webform {
    display: none;
  }
}
#sales-button {
  &:hover {
    cursor: pointer;
  }
}

#insert-webform {
  position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 100px;
}

.webform-client-form-20710,
.webform-client-form-20639 {
  background: white;
  position: relative;
  z-index: 3;
  label {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0.25em;
  }
  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    height: 30px!important;
    padding: 0.3em;
    margin-bottom: 0.5em;
    line-height: 1;
  }
  input[type="submit"]{
    width: 100%;
    display: block;
    border-radius: 4px;
    font-weight: 700;
  }
  .webform-component--i-would-like-to,
  .webform-component--confirm-subscription,
  .webform-component--embargo-message {
    display: none;
  }
}
.node-type-landinge-page {
  .webform-client-form-20639 {
    max-height: none;
    margin: -10em -1.5em;
    padding: 1em;
    input[type="submit"]{
      border: none;
      padding: 0.5em;
    }
  }
}
