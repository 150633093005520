
.fluke-biomedical {
  .paragraphs-item-banner {
    .foreground-section {
      background: $gray;
      @include media($screen-md){
        background: transparent;
      }
    }
  }
}
.raysafe {
  .paragraphs-item-banner {
    .foreground-section {
      background: $gray;

      @include media($screen-md){
        background: transparent;
      }
    }
  }
}

.paragraphs-item-banner:not(.field-name-field-header) {
  position: relative;
  padding-top: 0!important;
  padding-bottom: 0!important;
  @include media($screen-md){
    height: 600px;
  }

    // FOREGROUND
    .foreground-section {
      z-index: 3;
      // background: $gray-lightest;
      margin-left: -1em;
      margin-right: -1em;
      bottom: 0;

      @include media($screen-md){
        margin-left: 0;
        margin-right: 0;
        background: transparent;
        padding-top: 80px;
        position: relative;
      }

      .text-area {
        display: block;
        padding: 1em;
        max-width: $screen-sm;
        width: 100%;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @include media($screen-sm) {
          max-width: 75%;
        }
        @include media($screen-md) {
          height: 520px;
          padding: 4em 0;
        }

        @include media($screen-lg) {
          text-align: left;
          max-width: 33vw;
          padding: 0;
          justify-content: center;
        }
      }
    }

    h1,h2,h3, p {
      line-height: 1.25;

    }
    h1 {
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 0;
    }
    h2{
      font-size: 22px;
      margin-bottom: 0.5em;
    }
    h3{
      font-size: 1.8rem;
      font-weight: 500;
      margin-bottom: 1em;
    }
    p{
      font-size: 1.8rem;
      margin-bottom: 1em;
    }
    &.treatment-light-text {
      h1, h2, h3, p {
        color: $white;
        @include media($screen-md){
          color: transparentize($white, 0.05);
          text-shadow: 0 0 1px #000;
        }
      }
    }
    &.treatment-dark-text {
      h1, h2, h3, p {
        color: transparentize($white, 0.05);
        @include media($screen-md){
            color: $base-font-color;
        }
      }


    }
    &.treatment-pop-text {
      @include media($screen-md){

        h1, h2, h3, p {
          color: $yellow;
          text-shadow: 0 0 1px #000;
        }
      }
    }

    &.top .foreground-section .text-area,
    &.middle .foreground-section .text-area{
      margin-top: 0;
      margin-bottom: auto;
      margin-left: auto;
      margin-right: auto;
      //text-align: center;

    }
    &.bottom .foreground-section .text-area{
      margin-top: auto;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
      //text-align: center;
    }
    &.left,
    &.right{
      .text-area{
        //text-align: center;
        margin: 5vw;
      }
    }

    @include media($screen-sm){
      &.top .foreground-section .text-area{
        margin-top: 2vw;
        margin-bottom: auto;
        text-align: left;
        margin-bottom: 60px;
      }
      &.middle .foreground-section .text-area{
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 3em;
        padding-bottom: 4em;
      }
      &.bottom .foreground-section .text-area{
        margin-bottom: 0;
        text-align: left;
        padding-top: 3em;
        padding-bottom: 4em;
      }
      &.left .foreground-section .text-area{
        text-align: left;
        margin-left: 0;
        margin-right: auto;
        padding-left: 2vw;
      }
      &.center .foreground-section .text-area{
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        img{
          margin: auto;
        }
        a {
          margin: auto;
        }

      }
      &.right .foreground-section .text-area{
        text-align: left;
        margin-left: auto;
        margin-right: 0;
        padding-right: 2vw;

      }
    }
    @include media($screen-lg){
      &.left .foreground-section .text-area{
        padding-left: 0;
      }
      &.right .foreground-section .text-area{
        padding-right: 0;
      }
    }

  .background-section {
    margin-left: -1em;
    margin-right: -1em;
    height: 300px;

    @include media($screen-md) {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 0;
      margin-left: 50%;
      transform: translateX(-50%);
      height: 300px;
      height: 600px;
      &::before {
        content:'';
        width: 50px;
        height: 600px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 4;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100;White+to+Transparent */
        background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */

      }
      &::after {
        content: '';
        width: 50px;
        height: 600px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 4;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
        background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
      }
    }

    .field-name-field-background-image {
      height: 300px;
      img {
        height: 300px;
        min-width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        min-height: 300px;
        @include media($screen-md){
          max-width: none;
          height: 600px;
          -o-object-fit: fill;
        object-fit: fill;
        }

      }
    }
  }

  .field-name-field-cta-link {
    a {
      font-size: 24px;
      padding: 0.25em 2em;
      @include media($screen-md){
        font-size: 28px;
        padding: 0.5em 3em;
      }
    }
  }
}
