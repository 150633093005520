.field-name-ds-upcoming-webinars {
    .views-row {
        border-bottom: 1px solid $gray-lighter;
        margin-bottom: 0.5em;
        padding-bottom: 0.5em;

        &:last-child {
            border-bottom: none;
        }
    }
    .title-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .event-title {
            display: inline;
            width: calc(100% - 140px);
            margin-right: 1em;
        }
        .event-link {
            display: inline;
        }
        .button {
            padding: 0 1em;
            margin: 0;
            line-height: 30px;

        }
    }
    .presenters {
        font-size: 1.5rem;
    }
}
