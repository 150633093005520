table {
  ///////////////////////////////////////////////////////////////////////////////////
  $base-border-color: transparent;//$gray-lighter !default;
  $base-border-radius: 3px !default;
  $base-background-color: orange !default;
  $base-line-height: 1.5em !default;
  $base-spacing: 1.5em !default;
  $base-accent-color: $blue !default;
  $base-link-color: $white !default;
  $dark-gray: #333 !default;
  $light-gray: #DDD !default;
  $medium-screen: em(640) !default;
  $large-screen: em(860) !default;
  $base-font-color: $dark-gray !default;
  width: 100%;

  th{
    //border-bottom: 1px solid darken($base-border-color, 15%);
    font-weight: 600;
    text-align: left;
    //background: linear-gradient(to bottom, rgba($gray-dark,.5) 0, rgba($gray-darkest, .5) 100%);
    border-top: none;
  }

  td {
    line-height: $base-line-height;
    vertical-align: top;
  }
  //////////////////////////////////////////////////////////////////////////////////

  $table-border-color: none; //$base-border-color;
  $table-border: 1px solid $table-border-color;
  $table-background: none; //$base-background-color;
  $table-header-background: $gray-lightest; //lighten($table-background, 10);
  $table-hover-background: none; //lighten($table-background, 2);
  $table-stripe-background: none;//transparentize(darken($table-background, 4),.5);
  $table-stripe-background-hover: none;// darken($table-stripe-background, 5);
  $table-padding: .75rem 1rem;

  border-collapse: separate;
  border-spacing: 0;

  tbody {
    tr:hover > td, tr:hover > th {
      //background-color: inherit; //Over-ride default. Original background-color: $table-hover-background;
    }
  }

  thead {
    th {
      background-color: $table-header-background;
      border-bottom: 0px;
      padding: $table-padding;
      color: $base-font-color;
    }
  }
  caption{
    //border-bottom: 1px solid darken($base-border-color, 15%);
    font-weight: 600;
    text-align: left;
    background: linear-gradient(to bottom, rgba($gray-dark,.5) 0, rgba($gray-darkest, .5) 100%);
    border-top: none;
    color: $white;
    padding: $table-padding;

  }

  tbody {
    //background-color: $table-background;

    td {
      border-bottom: 0px;
      padding: $table-padding;

      //The button mixin was deprecated. Need to find a replacement or make our own;

      button {
        //@include button(flat, $base-accent-color);
        display: inline-block;
        font-size: .7em;
        line-height: $base-line-height;
        margin-bottom: .3em;
        margin-right: 0.5em;
        outline: none;
        padding: .3em 1em;
        width: 100%;

        @include media($medium-screen) {
          margin-bottom: 0;
          width: auto;
        }
      }

    }
  }
}

  table.sticky-header{
    background: white;
    z-index: 1;
    margin-top: 60px;

  }
