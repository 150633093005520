.page-node-add-blog-post{
  .node-form{
    @include outer-container();
  }
  .form-textarea{
    height: 80px;
  }
  .form-item{
    margin-top: 0;
    margin-bottom: 0;

  }
  #edit-scheduler-settings{
    margin-top: 2em;
    width: 48%;
    display: inline-block;
  }
  .group-social-media-blurbs{
    .clearfix{
      text-align: right;
    }
  }

  .field-type-view-mode-selector,
  .field-type-taxonomy-term-reference{
    width: 48%;
    display: inline-block;
  }
  .tabledrag-toggle-weight-wrapper{
    display: none;
  }
  table.field-multiple-table{
    table-layout: auto;

    th.field-label{
      padding: 1em;
    }
    tbody{
      a.tabledrag-handle .handle{
        height: 26px;
        width: 20px;
      }
      td.field-multiple-drag{
        width: 30px;
      }
    }
  }
}