.flukestrap-article-toc {
  .row {
    display: flex;
  }
  @include test-background('false');
  .flukestrap-layouts-sidebar {
    @include span-columns(3);
    position: relative;
    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -1000px;
      width: 1000px;
      background: $gray-lightest;
      height: 100%;
      content: '';
    }

  }
  .flukestrap-layouts-main-content {
    @include span-columns(12);

    @include media($desk) {
      @include span-columns(9);

    }
    .flukestrap-layouts-featured-content {
      //@include span-columns(12);
      //
      //@include media($desk-wide) {
      //  @include span-columns(9);
      //
      //}
    }
    .flukestrap-layouts-content {
      @include span-columns(12);

      @include media($desk-wide) {
        @include span-columns(9);

      }

    }
    .flukestrap-layouts-right-sidebar-content {
      @include span-columns(12);

      @include media($desk-wide) {
        @include span-columns(3);
        margin-right: 0;

      }

    }

  }
}