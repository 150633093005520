.value-yes,
.value-no{
  text-indent: 100px;
  height: 30px;
  width: 40px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: auto;
  line-height: 30px;
  vertical-align: top;


  &:after{
    content: "✔";
    color: $color-pop;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 3rem;
    height: 30px;
    width: 30px;
    text-indent: 0;
    text-align: center;
  }
}

.value-no{
  &:after{
    content: "✘";
    color: $gray-light;
  }
}