// Some visual grids for your development
//
// Toggle true/false on visual grid to turn on/off

$visual-grid: true;
$visual-grid-color: purple;
$visual-grid-index: front;
$visual-grid-opacity: 0.2;


$test-sections: true;



@import "../../../neato/neat/app/assets/stylesheets/neat-helpers";

 //Neat Overrides
 $column: 75px;
 $gutter: 30px;
 $grid-columns: 12;
 $max-width: 1260px;

// This is used with the omega-reset mixin
$fg-column: $column;
$fg-gutter: $gutter;

// Breakpoints
// Think of this as "up to..."

$screen-xs:                  526px;       // Phone / very small screen
$screen-sm:                  768px;       // Small screen / tablet
$screen-md:                  960px;      // Medium screen / desktop
$screen-lg:                  1260px;      // Large screen / wide desktop
$screen-xl:                  1480px;      // Wide desktop / very wide desktop

// Some semantic names
// The minimum width of any particular breakpoint
$phone                   : max-width $screen-xs;
$tablet                  : min-width $screen-xs;
$tablet-large            : min-width $screen-sm;
$desk                    : min-width $screen-md;

$desk-lg                 : min-width $screen-md;
$desk-wide               : min-width $screen-lg;

$desk-xl                 : min-width $screen-lg;
$desk-xwide              : min-width $screen-xl;

$ie : 'all and (-ms-high-contrast: none), (-ms-high-contrast: active),(-ms-ime-align: auto)';


// Grids for your breakpoints
$small-screen-down: new-breakpoint(max-width $screen-sm 4);
$tablet-screen-up: new-breakpoint(min-width $screen-sm 12);
//$medium-screen-up: new-breakpoint(min-width $screen-md 12);






