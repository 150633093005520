//
///*
//* The how to buy overrrides
//*/
//
//
//#block-menu-block-2{
//  .fbc-toggle{
//
//    &:hover{
//    cursor: pointer;
//    }
//  }
//
//.fbc-toggle.open{
//  height:60px;
//  line-height: 60px;
//  -webkit-border-radius:0px;
//  -moz-border-radius:0px;
//  border-radius: 0px;
//  transition: border-radius .25s, height .25s, line-height .25s;
//
//  & + ul.menu{
//    height: 180px;
//  }
//}
//  .fbc-toggle.closed{
//  height:40px;
//    line-height: 40px;
//    -webkit-border-radius:20px;
//    -moz-border-radius:20px;
//    border-radius: 20px;
//    transition: border-radius .25s, height .25s, line-height .25s;
//
//
//    & + ul.menu{
//    height: 500px;
//
//  }
//}
//}

  //.menu-name-menu-fbc-how-to-buy{
  //  @include clear-ul;
  //
  //  > ul.menu{
  //      > li {
  //        width: auto;
  //        text-align: center;
  //        //font-size: 1.3rem;
  //        //line-height: 28px;
  //        //height: 40px;
  //        transition: height .5s ease-in, opacity .5s ease-in;
  //
  //        @include media($tablet){
  //          //line-height: 30px;
  //          font-size: 1.4rem;
  //        }
  //        @include media($desk){
  //          //line-height: 40px;
  //          font-size: 1.6rem;
  //        }
  //        >ul.menu {
  //          height: 0;                            //TOGGLE THIS FOR TESTING!
  //          //height: 380px;
  //          overflow: hidden;
  //          transition: height .5s ease-in, opacity .5s ease-in;
  //          background-color: $gray-lightest;
  //          z-index: 6;
  //          opacity: 1;
  //          @include media($screen-md){
  //          }
  //          >li{
  //            text-align: left;
  //          }
  //        }
  //      }
  //
  //    /// where to buy demo section
  //    .separator{
  //      display: none;
  //    }
  //    >li.buy-demo-link{
  //
  //      .nolink{
  //        //height: 40px;
  //        display: block;
  //        background: $color-pop;
  //        color: $black;
  //
  //        &:hover{
  //          cursor: pointer;
  //        }
  //      }
  //      >ul.menu{
  //        //background: $gray-lightest;
  //        position: absolute;
  //        top: 92px;
  //        left: 0;
  //        //width: 100%;
  //        right: 0;
  //        @include extended-background($gray-lightest);
  //      }
  //
  //      .buy-demo-wrapper{
  //        margin: auto;
  //        @include test-background('false');
  //        @include outer-container();
  //        max-width: $screen-xl;
  //        padding: 2em;
  //        @include media($tablet){
  //          max-width: $screen-sm;
  //        }
  //        @include media($tablet-large){
  //          max-width: $screen-md;
  //        }
  //        @include media($desk){
  //          max-width: $screen-lg;
  //        }
  //        @include media($desk-wide){
  //          max-width: $screen-xl;
  //        }
  //        ul{
  //          display: flex;
  //          max-width: 100%;
  //
  //
  //        }
  //
  //        li.demo-wrapper{
  //          display: inline-block;
  //          width: 50%;
  //          @include media($screen-xs){
  //            width: 60%;
  //          }
  //          @include media($screen-md){
  //            width: 75%;
  //            padding: 0 3em;
  //
  //          }
  //
  //            ul{
  //              width: 100%;
  //              display: flex;
  //              justify-content: flex-end;
  //              flex-wrap: wrap;
  //              @include media($screen-sm){
  //                flex-wrap: nowrap;
  //              }
  //              li{
  //                position: relative;
  //                padding-top: 64px;
  //                height: 64px;
  //                flex: 0 1 50%;
  //                text-align: center;
  //                margin-bottom: 5rem;
  //
  //                @include media($screen-sm){
  //                  padding-top: 80px;
  //                  height: 82px;
  //                  flex: 0 1 25%;
  //                }
  //                a{
  //                  position: absolute;
  //                  height: 100px;
  //                  width: 100%;
  //                  top: 0;
  //                  left: 0;
  //                  right: 0;
  //                  bottom: 0;
  //                  padding-top: 72px;
  //                  text-align: center;
  //                  line-height: 14px;
  //                  z-index: 2;
  //                  font-weight: bold;
  //                  font-size: 1.3rem;
  //                  padding-right: 1em;
  //                  padding-left: 1em;
  //
  //                  @include media($screen-xs){
  //                    font-size: 1.5rem;
  //                    padding-top: 90px;
  //                    line-height: 20px;
  //                    padding-right: auto;
  //                    padding-left: auto;
  //
  //                  }
  //
  //
  //                }
  //                &:before{
  //                  pointer-events: none;
  //                  content: '';
  //                  position: absolute;
  //                  top: 0;
  //                  left: 0%;
  //                  right: 0;
  //                  margin: auto;
  //                  height: 66px;
  //                  width: 80px;
  //                  background: url("../images/hexagon-solid-white.png") no-repeat;
  //                  background-size: 100%;
  //
  //                  @include media($screen-xs){
  //                    height: 100px;
  //                    width: 96px;
  //                  }
  //
  //
  //                }
  //                &:after{
  //                  position: absolute;
  //                  top: 0;
  //                  left: 0;
  //                  right: 0;
  //                  bottom: 0;
  //                  margin: auto;
  //                  content: '';
  //                  height: 56px;
  //                  width: 56px;
  //
  //                  //@include colored-svg(url('../images/icons/quote.svg'), $base-font-color, 100%);
  //                  text-align: center;
  //                  background-repeat: no-repeat;
  //                  background-size: 100%;
  //                  background-position: 50%;
  //                }
  //                &.quote{
  //                  &:after{
  //                    background-image: url('../images/icons/quote.svg');
  //
  //                  }
  //
  //                }
  //                &.demo{
  //                  &:after{
  //                    background-image: url('../images/icons/demo.svg');
  //                  }
  //                }
  //                &.sales{
  //                  &:after{
  //                    background-image: url('../images/icons/contact-us.svg');
  //                  }
  //                }
  //                &.catalog{
  //                  &:after{
  //                    background-image: url('../images/icons/download.svg');
  //                  }
  //                }
  //              }
  //            }
  //            a{
  //              color: $base-font-color;
  //            }
  //        }
  //        .contact-wrapper{
  //          display: inline-block;
  //          border-left: 2px solid $white;
  //          width: 50%;
  //
  //          @include media($screen-xs){
  //            width: 40%;
  //          }
  //          @include media($screen-md){
  //            width: 30%;
  //          }
  //            ul{
  //              display: block;
  //              padding-left: 1em;
  //              li{
  //                text-align: left;
  //                display: block;
  //                line-height: 20px;
  //                font-size: 1.5rem;
  //                font-weight: bold;
  //                .nolink{
  //                  height: 20px;
  //                  line-height: 20px;
  //                  color: $base-font-color;
  //                  background-color: transparent;
  //                }
  //
  //                  &.menu-mlid-15880{
  //                    color: $base-font-color;
  //                    font-size: 2rem;
  //                    margin-bottom: 2rem;
  //                  }
  //                  a{
  //                    color: $blue;
  //                  }
  //                }
  //            }
  //        }
  //
  //
  //      }
  //    }
  //    /// where to buy language section
  //    li.language-link{
  //    display: inline-block;
  //      width: 50%;
  //
  //
  //      ul{
  //        li{
  //          display: block;
  //          line-height: 3rem;
  //          padding-left: 1em;
  //          a{
  //            font-weight: bold;
  //            font-size: 1.5rem;
  //          }
  //        }
  //      }
  //
  //    }
  //  }
  //}
