%views-row-hover {
  border: 1px solid transparent;
  &:hover {
    border: 1px solid rgba($gray-lightest, 0.5);
    -webkit-box-shadow: 1px 1px 3px rgba($gray-lightest, 0.5);
  }
}

%margin-padding-underline {
  padding-bottom: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid $gray-lightest;
}
.gray-background {
  @include extended-background($gray-lightest);
  background-color: $gray-lightest;
  padding-bottom: 2em;
}
.white-to-gray-background {
  @include extended-background($gray-lightest);
  @include gradient(to bottom, white, $gray-lightest);
  padding-bottom: 2em;
}

.view-fh-product-category-views,
.view-fbc-product-categories {
  margin-bottom: 2em;
  &.view-display-id-block,
  &.view-display-id-block_1,
  &.view-display-id-block_8 {
    min-width: 0;
    @include outer-container();
    .view-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      @extend %three-by;
      padding: 1em;
      @include media($screen-lg) {
        padding: 0;
      }
    }
    .views-row {
      vertical-align: top;
      padding: 2em;

      .card-tall-layout {
        padding: 0 0 1em 0;
        .card-tall {
          .card-content {
            flex-direction: column;
          }
        }
      }

      .card-content {
        position: relative;
        @include clearfix;
        //height: 400px;

        .card-image {
          width: auto;
          min-height: 200px;
        }
        a {
          display: block;
          bottom: 0;
          width: 100%;
          h3 {
            color: $base-link-color;
          }
        }
        .card-text {
          min-height: 150px;
        }
      }
    }
  }
}

.view-fbc-product-categories {
  &.view-display-id-block_8 {
    .view-content {
      > h3 {
        width: 100%;
        flex: 0 1 100%;
      }
    }
  }
}
