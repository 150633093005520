.view-fbc-software-downloads{
  &.view-display-id-block_1{
  margin: 2em 0;
  h2{
    padding: 0;
    font-family: $font-proxima-nova-semibold;
  }
  .view-content{
    @include media($screen-md){
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      columns: 2;
      column-gap: 6em;
    }
    
  }
  .views-row{
    margin-bottom: 2em;
    break-inside: avoid;
    border: 1px solid transparent;
      //padding: 1em;
    //margin-top: 1em;
    //border-top: 1px solid $gray-lightest;

  }
  .card-content{
    display: flex;
    justify-content: space-between;
    .card-text{
      @include media($screen-xs) {
        flex: 0 1 75%;
        display: inline-block;
      }

    }
    .card-image{
      vertical-align: top;
      @include media($screen-xs){

      display: inline-block;
      vertical-align: top;
      flex: 0 1 20%;}

    }
  }
  }
  &.view-display-id-page_1{

    .view-header{
      position: relative;
      height: auto;

      &:after {
        content: '';
        position: absolute;
        top: -80px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        min-width: 100%;
        background-repeat: no-repeat;
        background-size: auto;
        background-image: url(../images/backgrounds/hexagons-category-768x604.png);
        //@include media($screen-md){
        //  top: -40px;
        //
        //}

      }
      @include media($screen-xs) {

        &:after {
          background-image: url(../images/backgrounds/hexagons-category-960x604.png);
          background-position: 0 0;
        }

      }
      @include media($screen-sm) {
        &:after {
          background-image: url(../images/backgrounds/hexagons-category-1260x604.png);
        }
      }
      @include media($screen-md) {
      }

      @include media($screen-lg) {
        &:after {
          background-image: url(../images/backgrounds/hexagons-category-2000x604.png);
          background-position: 50% 50%;

        }
      }
      @include media($screen-xl) {
        &:after {
          background-image: url(../images/backgrounds/hexagons-category-3000x604.png);
        }
      }
      h1{
        @include outer-container();
        padding: 1em;

        @include media($screen-lg){
          padding: 1em 0;

        }

      }
    }
    .view-content{
      @include outer-container();
      display: block;
      h2{
        @extend %table-caption;
        font-weight: normal;
      }
      .views-row{
        margin-bottom: 2em;
        padding: 1em;
        @include media($screen-lg){
          padding: 0;
        }


        .views-field-nothing{
          .field-content{
            .left{

              @include media($screen-lg){
                display: inline-block;
                width: 85%;
                padding-left: 2em;
              }

            }
            .right{
              margin-top: 1em;
              @include media($screen-lg) {
                margin-top: 0;
                display: inline-block;
                width: 15%;
              }
              a{
                @extend %button-secondary;
              }

            }
          }
        }


        &:last-child{
          border-bottom: none;
        }
      }
    }
  }
}