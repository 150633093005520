.view-fbc-page-not-found{
  .view-content{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    @include media($screen-lg) {
      flex-wrap: nowrap;
    }
    .views-row{
      display: inline;
      flex: 0 1 100%;
      padding-right: 2em;
      max-width: 400px;
      margin: auto;
    
      @include media($screen-sm) {
        margin: 0;
        flex: 0 1 50%;
      }
      @include media($screen-lg) {
        flex: 0 1 20%;
      }

      .field-content{
        a{
          display: flex;
          flex-direction: column;
          height: 100%;
        }
      }
      &:last-child{
        padding-right: 0;
      }
      .card-image{
        order: 1!important;
        height: 150px;
      }
      .field-name-field-teaser-image{
        height: 150px;
        display: inline-block;
        img{
          max-height: 150px;
          width: auto;
          max-width: 100%;
          height: auto;
          display: block;
          margin: auto;
        }
      }
      .card-text{
        order: 2!important;
      }
    }
  }


  .view-footer{
    margin-top: 1em;
    padding-top: 2em;
    border-top: 1px solid $gray-lighter;
    
    p{
      display: flex;
      width: 400px;
      margin: auto;
      flex-wrap: wrap;
      @include media($screen-md) {
        width: 80%;
      }
      @include media($screen-lg) {
        width: 100%;
        flex-wrap: nowrap;
      }
      a{
        display: inline-block;
        flex: 0 1 50%;
        margin-bottom: 1em;
       
      @include media($screen-lg) {
        flex: 0 1 20%;
        justify-content: space-between;

      }

      }
    }
  }
  &.view-display-id-block_1{
    .views-row{
      display: flex;
      flex-direction:column;
      div{
        display: inline-block;
        margin-bottom: 1em;
      }
    }
  }
}