// //
// Bootstrap Modals in Neato
// --------------------------------------------------

/* This modal was adapted from Bootstrap 3. Documentation for implementing the 
 * html can be found here: http://getbootstrap.com/javascript/#modals
 * I used the burbon/neat variables/mixins when possible. 
 * The "close" class styling from bootstrap 3 is also included here.
 * You can replace the close <button> with a close <a>
 * The default modal sturcture in Refills causes 2 problems in Drupal:
 * 1) The supporting javascript requires you to add an ID selector, which makes
 *   it unsuitable for end user applications
 * 2) The modal trigger requires the use of an input field, which can be filtered
 *    out by certain editor configurations.
 * If you have a better solution, I'm all ears! -RRN
 */ 

 //TODO: Remove small medium and large modal sizes.

// //
// HTML Example
// --------------------------------------------------
// <div data-toggle="modal" data-target="#specification" class="btn btn-blue btn-lg btn-block" >See the full specifications</div>
// <div id="specification" class="modal fade" role="dialog">
//   <div class="modal-dialog">
//     <!-- Modal content-->
//     <div class="modal-content">
//       <div class="modal-header">
//       <h4 class="modal-title">Modal title</h4>
//       <a class="close" data-dismiss="modal" aria-label="Close"><span class="fluke-icon fluke-icon-close" aria-hidden="true">&nbsp;</span></a>    
//       </div>
//       <div class="modal-body" id="specification_content">
//         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos deleniti error dolor, neque veritatis harum asperiores ab molestias quod explicabo voluptatum dolores assumenda provident, possimus illum similique voluptate at voluptatibus!
//       </div>
//     </div>
//   </div>
// </div>


// //
// Close button
// --------------------------------------------------
.modal .close {
  color: $white;
  font-size: 2rem;
  position: absolute;
  right: 0;
  text-shadow: 0 1px 0 $black;
  top: -3rem;
  &:hover {
    text-decoration: none;
    color: white;
  }
}

// //
// Bootstrap Modal
// --------------------------------------------------

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and shit

// Variables
$zindex-modal-background:  1040;
$zindex-modal:             1050;
//** Padding applied to the modal body
$modal-inner-padding:         15px;

//** Padding applied to the modal title
$modal-title-padding:         15px;
//** Modal title line-height
$modal-title-line-height:     $base-line-height;
//** Background color of modal content area
$modal-content-bg:                             $white;
//** Modal content border color
$modal-content-border-color:                   rgba(0,0,0,.2);
//** Modal content border color **for IE8**
$modal-content-fallback-border-color:          #999;

//** Modal backdrop background color
$modal-backdrop-bg:           $black;
//** Modal backdrop opacity
$modal-backdrop-opacity:      .8;
//** Modal header border color
$modal-header-border-color:   $gray-e;
//** Modal footer border color
$modal-footer-border-color:   $gray-e;

$modal-lg:                    $max-width - 200px; // 1060px
$modal-md:                    90%;
$modal-sm:                    90%;

// Kill the scroll on the body
.modal-open {
  overflow: hidden;
}

// Container that the modal scrolls within
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal;
  -webkit-overflow-scrolling: touch;

  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;

  // When fading in the modal, animate it to slide down
  &.fade .modal-dialog {
    transform: translate(0, -25%);
    @include transition-property(transform 0.3s ease-out);
  }
  &.in .modal-dialog { 
    transform: translate(0, 0);
  }
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  width: auto;
  margin: 35px;
}

// Actual modal
.modal-content {
  position: relative;
  background-color: $modal-content-bg;
  border: 1px solid $modal-content-fallback-border-color; //old browsers fallback (ie8 etc)
  border: 1px solid $modal-content-border-color;
  border-radius: $border-radius-large;
  box-shadow: 0 3px 9px rgba(0,0,0,.5);
  background-clip: padding-box;
  // Remove focus outline from opened modal
  outline: 0;
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal-background;
  background-color: $modal-backdrop-bg;
  // Fade for backdrop
  &.fade { 
    opacity: 0;
  }
  &.in {
    opacity: $modal-backdrop-opacity;
  }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  padding: $modal-title-padding;
  border-bottom: 1px solid $modal-header-border-color;
  // &:extend(.clearfix all);
  @include clearfix;
}

// Title text within header
.modal-title {
  margin: 0;
  line-height: $modal-title-line-height;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  padding: $modal-inner-padding;
}

// Footer (for actions)
.modal-footer {
  padding: $modal-inner-padding;
  text-align: right; // right align buttons
  border-top: 1px solid $modal-footer-border-color;
  //&:extend(.clearfix all); // clear it in case folks use .pull-* classes on buttons
  @include clearfix;
  // Properly space out buttons
  .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
  }
  // but override that for button groups
  .btn-group .btn + .btn {
    margin-left: -1px;
  }
  // and override it for block buttons as well
  .btn-block + .btn-block {
    margin-left: 0;
  }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

// Scale up the modal
@include media($screen-sm) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    width: $modal-md;
    margin: 4rem auto;
  }
  .modal-content {
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
  }

  // Modal sizes
  .modal-sm {
    width: $modal-sm; 
  }
}
@include media($screen-lg) {
  .modal-dialog {
    width: $modal-lg;
    margin: 6rem auto;
  }
  .modal-lg { 
    width: $modal-lg;
  }
}

