@mixin gradient($direction:to right, $from:$teal-light, $to:$blue) {
  /* fallback/image non-cover color */
  background-color: $from;

  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient($direction, $from, $to);

  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($from), to($to));

  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient($direction, $from, $to);

  /* Opera 11.10+ */
  background-image: -o-linear-gradient($direction, $from, $to);
}

@mixin remove-gradient($direction:to bottom, $from:$yellow, $to:$blue) {
  /* fallback/image non-cover color */
  background-color: transparent;

  /* Firefox 3.6+ */
  background-image: initial;

  /* Safari 4+, Chrome 1+ */
  background-image: initial;

  /* Safari 5.1+, Chrome 10+ */
  background-image:initial;

  /* Opera 11.10+ */
  background-image: initial;
}