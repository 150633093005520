.paragraphs-item-related-resource {
  .field-name-ds-resource-download-with-title {
    margin-bottom: 1em;
    > .field-items {
      >.field-item {
        display: flex;
        justify-content: space-between;
        width: 100%;
        h3 {
          display: inline-block;
          width: calc(100% - 140px);
        }

        a {
          display: inline-block;
          height: 30px;
        }
      }
    }
  }
}