#block-menu-block-1 {
  display: inline-block;
  z-index: 2;

  ul.menu { // Top level
    position: relative;
    display: flex;
    justify-content: space-around;
    @include clear-ul;
    padding-left: 0;
    margin: 0;
    background: $gray-dark;

    //@include outer-container();

    > li {
      display: inline-block;
      position: relative;
      color: $white;
      line-height: 60px;
      font-weight: bold;
      white-space: nowrap;
      padding: 0;
      font-size: 1.5rem;
      @include media($screen-lg){
        font-size: $base-font-size;
      }

      &:hover{
        cursor: pointer;
        color: $fluke-yellow;
      }

      a {
        color: $white;
      }

      > ul { // Second level
        background: transparentize($gray-dark, .05);
        min-width: 100%;
        height: 360px;
        overflow: hidden;
        position: absolute;
        top: -400px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        columns: 2;
        z-index: 1;
        align-content: flex-start;
        -webkit-transition: height .5s, border-top .5s;
        -moz-transition: height .5s, border-top .5s;
        -ms-transition: height .5s, border-top .5s;
        -o-transition: height .5s, border-top .5s;
        transition: height .5s, border-top .5s;
        min-width: 100%;
        @include media($screen-md){
          min-width: 6260px;
          margin: 0 -2500px;
          padding: 0 2500px;

        }

        > li {
          line-height: 1;
          display: inline-block;
          width: 25%;
          min-width: unset;
          margin-bottom: .5em;
          text-transform: none;

          &.break {
            margin-bottom: 100%;
          }

          a,
          .nolink {
            color: $color-pop;
            font-weight: bold;
            font-size: 1.4rem;

          }
          a{
            color: $white;
            font-weight: normal;
          }

          > ul { // third level
            top: 0;
            min-width: 0;
            margin: 0;
            padding: 0;
            border-top: none;
            width: auto;
            display: block;
            background: none;
            position: relative;
            height: auto;
            columns: 1;

            li {
              padding: 0;
              display: block;
              text-transform: none;
              width: auto;
              margin-bottom: 0;
              a {
                font-weight: normal;
                color: $white;
              }
            }
          }
        }
        }
      &.products{
        ul{
          li{
            margin-bottom: .5em;
            a{
              color: $color-pop;
              font-weight: bold;
              margin-bottom: .5em;

            }
            ul{
              margin-top: .5em;
              li{
                 margin-bottom: .5em;
                a{
                  color: $white;
                  font-weight: normal;
                  text-transform: none;

                }
              }
            }
          }
        }


      }

      //.service-support {
      //  & > ul {
      //    left: -300px;
      //    columns: 3;
      //  }
      //}
      & > li.expanded {
        position: relative;
        background: transparentize($gray-dark, .05);
        z-index: 10;

        &:after {
          content: '';
          width: 100%;
          height: 4px;
          background: $yellow;
          position: absolute;
          bottom: 0;
          left: 0;
        }
        & > ul {
          padding-top: 1em;
          -webkit-transition: height .5s, border-top .5s;
          -moz-transition: height .5s, border-top .5s;
          -ms-transition: height .5s, border-top .5s;
          -o-transition: height .5s, border-top .5s;
          transition: height .5s, border-top .5s;
          //border-top: 3px solid $action-color;
          -webkit-box-shadow: 0 0 1px $gray-light;
          -moz-box-shadow: 0 0 1px $gray-light;
          box-shadow: 0 0 1px $gray-light;
          li {
            &:after {
              background: none;
            }

          }

          ul {
            padding-top: 0;
            border-top: none;
            transition: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;

          }
        }
      }

      &.open > ul{
        position: absolute;
        top: 60px;
        padding-top: 1em;
      }
    }
  }
}
/*#block-menu-menu-oneqa-navigation {
  max-width: 1260px;
  margin-left: auto;
  margin-right: auto;
    .menu {
      position: relative;
      display: flex;
      justify-content: space-around;
      padding-left: 0;
      margin: 0;
      background: none;
        li {
        display: inline-block;
        position: relative;
        color: #fff;
        line-height: 60px;
        font-weight: bold;
        white-space: nowrap;
        padding: 0;
        font-size: 1.7rem;
        margin-right: 25px;
        a {
          color: #fff;
        }
          ul>li a {
            color: #fff;
            font-weight: normal;
        }
    }
  }
}*/
#block-menu-menu-oneqa-navigation,
.field-name-oneqa-navigation {
  display: inline-block;
  z-index: 2;
  width: 50%;

  ul.menu { // Top level
    position: relative;
    display: flex;
    justify-content: space-around;
    @include clear-ul;
    padding-left: 0;
    margin: 0;
    background: none;

    //@include outer-container();

    > li {
      display: inline-block;
      position: relative;
      color: $white;
      line-height: 50px;
      font-weight: bold;
      white-space: nowrap;
      padding: 0;
      font-size: 1.5rem;
      @include media($screen-lg){
        font-size: $base-font-size;
      }

      &:hover{
        cursor: pointer;
        color: $fluke-yellow;
      }

      a {
        color: $white;
      }

      > ul { // Second level
        background: transparentize($gray-dark, .05);
        min-width: 100%;
        padding: 10px 15px;
        position: absolute;
        top: 50px;
        left: -15px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        columns: 2;
        z-index: 1;
        align-content: flex-start;
        -webkit-transition: height .5s, border-top .5s;
        -moz-transition: height .5s, border-top .5s;
        -ms-transition: height .5s, border-top .5s;
        -o-transition: height .5s, border-top .5s;
        transition: height .5s, border-top .5s;
        min-width: 100%;
        @include media($screen-md){
          min-width: 6260px;
          margin: 0 -2500px;
          padding: 0 2500px;

        }

        > li {
          line-height: 1;
          display: inline-block;
          width: 25%;
          min-width: unset;
          margin-bottom: .5em;
          text-transform: none;

          &.break {
            margin-bottom: 100%;
          }

          a,
          .nolink {
            color: $color-pop;
            font-weight: bold;
            font-size: 1.4rem;

          }
          a{
            color: $white;
            font-weight: normal;
          }

          > ul { // third level
            top: 0;
            min-width: 0;
            margin: 0;
            padding: 0;
            border-top: none;
            width: auto;
            display: block;
            background: none;
            position: relative;
            height: auto;
            columns: 1;

            li {
              padding: 0;
              display: block;
              text-transform: none;
              width: auto;
              margin-bottom: 0;
              a {
                font-weight: normal;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}
  
      