/* 
 * The products are displayed through Panelizer. There is a custom template called
 * flukestrap-libby, and this styles each section in the template
 */
 %product-layout-common {
  @include span-columns(12);
  padding: 2rem 0;
  border-bottom: 1px solid $gray-c;
  @include media($screen-sm) {
    padding: 5rem 0;
    border-bottom: none;
  }
}

.libby {
  .flukestrap-layouts-header{
    @include outer-container();
  }

  .gray-wrapper {
    background-color: $gray-e;

  }
  .additional-info-wrapper{
    @include outer-container();
  }
  .flukestrap-layouts-header {
    .panel-pane {
      @include outer-container(100%);
    }
  }
  .libby-title { //@include media($screen-md) {
    //  @include span-columns(6);
    //}
    @include span-columns(12);
    h1 {
      margin-top: 2rem;
    }
  }
  .libby-slideshow {
    @include span-columns(12);
    padding-bottom: 2rem;
    @include media($screen-sm) {
      @include span-columns(7);
    padding-bottom: 7rem;
    }

  }
  .libby-alert-panel {
    @include span-columns(12);
    @include media($screen-sm) {
      @include span-columns(5);
      margin-right: 0;
    }
    @include media($screen-md) {
      @include span-columns(5);
      @include shift(1);
      margin-right: 0;
    }
  }

  .libby-overview {
  @extend %product-layout-common;
    @include media($screen-sm) {
      @include span-columns(12);
    }
    @include media($screen-md) {
      @include span-columns(6);
    }
  }
  .libby-prod-models {
    @extend %product-layout-common;
    @include media($screen-sm) {
      @include span-columns(12);
      margin-right: 0;
    }
    @include media($screen-md) {
      @include span-columns(6);
      margin-right: 0;
    }
  }
  .libby-accessories {
    @extend %product-layout-common;
    @include media($screen-sm) {
      @include span-columns(8);
    }
  }
  .libby-resources {
    @extend %product-layout-common;
    @include media($screen-sm) {
      @include span-columns(4);
      margin-right: 0;
    }
  }
  .libby-related {
    @extend %product-layout-common;
    border-bottom: none;
  }
}
