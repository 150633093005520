//@mixin texture-background(){
//  $circle: transparentize($white, 1) 20px, transparentize($white, .7) 21px, transparentize($white, .70) 47px, transparentize($white, 1) 48px;
//  background-image:
//  //radial-gradient(1px at 0% 0%, $circle),
//  radial-gradient(1px at 0% 25%, $circle),
//  radial-gradient(1px at 0% 50%, $circle),
//  //radial-gradient(1px at 0% 75%, $circle),
//  radial-gradient(1px at 0% 100%, $circle),
//  radial-gradient(1px at 25% 0%, $circle),
//  radial-gradient(1px at 25% 25%, $circle),
//  radial-gradient(1px at 25% 50%, $circle),
//  //radial-gradient(1px at 25% 75%, $circle),
//  radial-gradient(1px at 25% 100%, $circle),
//  radial-gradient(1px at 50% 0%, $circle),
//  radial-gradient(1px at 50% 25%, $circle),
//  radial-gradient(1px at 50% 50%, $circle),
//  //radial-gradient(1px at 50% 50%, $circle),
//  //radial-gradient(1px at 50% 75%, $circle),
//  radial-gradient(1px at 50% 100%, $circle),
//  radial-gradient(1px at 75% 0%, $circle),
//  //radial-gradient(1px at 75% 25%, $circle),
//  radial-gradient(1px at 75% 50%, $circle),
//  radial-gradient(1px at 75% 75%, $circle),
//  //radial-gradient(1px at 75% 100%, $circle),
//  radial-gradient(1px at 100% 0%, $circle),
//  radial-gradient(1px at 100% 25%, $circle),
//  //radial-gradient(1px at 100% 50%, $circle),
//  radial-gradient(1px at 100% 75%, $circle),
//  radial-gradient(1px at 100% 100%, $circle),
//
//;
//  background-size: 275px 275px;
//  border:1px solid pink;
//  transform: rotate(45deg);
//
//radial-gradient(closest-side, transparent 98%, pink 99%),
//radial-gradient(closest-side, transparent 98%, pink 99%);
//  background-size:80px 80px;
//  background-position:0 0, 40px 40px;
//
//
//
////radial-gradient(circle at 0% 50%, rgba(96, 16, 48, 0) 9px, #613 10px, rgba(96, 16, 48, 0) 11px) 0px 10px,
////radial-gradient(at 100% 100%,      rgba(96, 16, 48, 0) 9px, #613 10px, rgba(96, 16, 48, 0) 11px),
////#8a3;
//
//}