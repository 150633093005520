//Also Check out the components/toggle.scss to get the toggle part of this


.flukestrap-layouts-sidebar{
  // Checkboxes that are not a custom collapse. EX: Software downloads
  .pane-title{
    margin: 0 0 0.25em;
  }
  .item-list ul li{
    list-style: none;
    //margin: ($spacing-1 / 2) 0;
    input[type="checkbox"]{
      margin-right: 13px;
    }
    a.active-trail.active{
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
  ul.menu li.active-trail.active{
    padding-bottom: 0;
  }
  .active-trail.active {
    font-weight: bold;
    a{
      display: block;
      margin-bottom: 10px;
    }

    ul li {
      a{
        font-weight: normal;
        color: $action-color;
        margin-bottom: 0;

      }
    }
  }
  button.collapse-toggle{
    display: block;
    color: $black;
    width: 100%;
    text-align: left;
    background-color: $white;
    padding: 1.6rem;
    @include media($screen-md){
      display: none;
    }
  }
  .panel-group{
    display: none;
    @include media($screen-md){
      display: block;
    }
  }
  .pane-content,
  .custom-collapse{
    .panel-group{
      .panel{
        display: block;
        border-bottom: none;
        .panel-heading{
          position: relative;
          h4 {
            margin-bottom: 0;
            font-size: 13px;
            font-weight: 400;
            line-height: 1.3;
            //padding: .7rem 2rem .7rem 0;
            a {
              font-family: $base-font-family;
              color: $gray-21;
            }
          }
          @extend %adds-toggle-plus;
          a[data-parent='#accordion'] {
            padding: 0;
          }

        }
        .child-menu-item {
          padding-bottom: 10px;
        }
        .panel-collapse{
          display: none;

        }
      }
    }
  }
}
