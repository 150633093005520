.node-product-display.view-mode-full{
  .field > .field-label{
    @extend %h3;
  }
  .libby-features{
    display: inline-block;
    width: 40%;
  }
  .field-name-field-product-overview{
    .ds-2col{
      display: block;
      .group-left,
      .group-right{
        width: 100%;
        display: block;
        float: none;
        clear: both;
      }
    }
  }

  .group-resources{
    & > .field{
      padding: .5em 0;
      border-bottom: 1px solid $gray-lightest;
    }
    .panel-heading{
      @extend h4;
      font-weight: bold;
      .custom-collapse .panel-heading a[data-parent='#accordion']{
        line-height: 0;
      }
    }
  }
}