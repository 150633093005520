// admin/content
body.page-admin-content {
  table td {
    padding: 1rem;
  }
}
// Getting the white tabs out from behind sticky nav. Remove if this breaks tabs elsewhere
.tabs.primary {
  //position: absolute;
  display: block;
  top: 140px; //Place under floating nav in both mobile and desktop.
  width: 100%;
  background: white;
}