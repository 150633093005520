.paragraphs-item-application-video {
  .field-type-video-embed-field {
    position: relative;

    &:before{
      content: '\25BA';
      height: 80px;
      width: 80px;
      background: white;
      color: $blue;
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
      line-height: 50px;
      text-align: center;
      margin: auto;
      font-size: 50px;
      padding: 20px 0 0 10px;
      box-shadow: 5px 5px 10px $gray-dark;

      &:hover {
        background: $gray-lightest;
        color: darken($blue, 0.25);
      }
    }
  }
  footer {
    display: none;
  }
}
